<div class="container-popup">

   <div class="general-popup-header header">
      <b>Your password has been successfully changed</b>
   </div>

   <div class="general-popup-body body">
      <span>You must now relogin to SphereWMS</span>
   </div>

   <div class="general-popup-footer footer">
      <button (click)="logout()">Login</button>
   </div>

</div>
