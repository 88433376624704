import { Component, ContentChildren, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AppRouteService, AppUtils, AuthenticationService, clsv1Response, ScreenRenderer, UtilityService, V1MenuService, V1UtilitiesService } from '@app/core';
import { FileUploadComponent } from '@app/shared/controls/file-upload/file-upload.component';
import { Notifier } from '@app/shared/controls/grid/grid.component';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { V1ModuleWiseResponseService } from '../general-ui-ux/v1-module-vise-respons.service';
import { MSGBOXComponent } from './msg-box/msg-box.component';

@Component({
  selector: 'v1-lookup-screen',
  templateUrl: './v1-lookup.component.html',
  styleUrls: ['./v1-lookup.component.scss']
})
export class v1LookupComponent extends ScreenRenderer implements OnInit, OnDestroy {
  isMasterLayout: boolean = false;
  selectedClientName;
  //currentSelectedClientName
  escKeySubscription;
  functionKeySubscription;

  isOpen = false;
  selectedRows = [];
  selectedRow;
  notifyContextMenuObj = new Notifier();
  @ViewChild('headTitle') headTitle: ElementRef;
  @ViewChild('bodyArea') private bodyAreaContainer: ElementRef;
  headerTextIsOverflowing: boolean = false;
  hasTwoSubSequentScreen: boolean = false;
  startingWorkArea: ScreenRenderer;
  loadMoreCounter: number = 0;
  _loadAllInProcess: boolean = false;
  constructor(private _utilityService: UtilityService, public appRouteService: AppRouteService, private v1Menu: V1MenuService,
    private _dialog: MatDialog,
    private _renderer: Renderer2,
    private auth: AuthenticationService, public v1Utility: V1UtilitiesService, public resPonseSer: V1ModuleWiseResponseService,
    public dialogRef: MatDialogRef<v1LookupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(v1Utility);
    if (data?.res) {
      this._isActive = true;
      this.hasTwoSubSequentScreen = this.hasTwoSubsequentWA(data.res);
      if (this.hasTwoSubSequentScreen) {
        this.startingWorkArea = new ScreenRenderer(this.v1Utility);
        this.startingWorkArea._addNewLayer = (splitedByLineEnd: clsv1Response[]) => {
          this._ParseScreen(splitedByLineEnd, true);
        };
        this.startingWorkArea._removeLayer = (splitedByLineEnd: clsv1Response[]) => {
          this.startingWorkArea._clearControls();
          this.startingWorkArea._ParseScreen(splitedByLineEnd, true);
        }
        this.startingWorkArea._showMsgBox = (boxelement) => {
        }
        this.startingWorkArea._ParseScreen(data?.res, true);
      } else {
        this._ParseScreen(data?.res, true);
      }

      if (this.auth.QFSelected && this.auth.QFSelected.length > 0) {
        if (!this.auth.quickFunctionDialougeId) {
          this.auth.quickFunctionDialougeId = this.dialogRef.id;
        }
      }
    }
  }


  ngOnInit(): void {
    this.selectedClientName = this.auth.SelectedClientName;
    this.escKeySubscription = this._utilityService.onEscapeKeyPress.subscribe(x => {
      if (this._isActive) {
        console.log("ESC Key Presed from Lookup", this._title, this._isActive);
        if (this._isGemPad) {
          this._closeGemPad()
        } else {
          this._onEnterPress('[ESC]');
        }
      }
    });
    this.functionKeySubscription = this._utilityService.onFunctionKeyPress.subscribe(x => {
      if (this._isActive) {
        console.log("Function Key Presed from Lookup", this._title, this._isActive);
        this.auth.gemxPost({
          gconn: this.auth.gConn,
          gcmd: x
        }).subscribe(x => {
          console.log("After Quick Function Key Called From Popup", x);
          this._ParseScreen(this.v1Utility.parseResponse(x));
        });
      }
    });
  }
  _addNewLayer(splitedByLineEnd: clsv1Response[]) {
    console.log("New Layer Found Need to Open New Screen:", splitedByLineEnd);
    let chkWA = this.isNotAppearableWorkArea(splitedByLineEnd);
    if (chkWA.index == -1) {
      this.OpenLookUp(this.parseIdfromResponseArray(splitedByLineEnd[0].value), splitedByLineEnd);
      //this.newScreenLayerObj._ParseScreen(splitedByLineEnd,true);
    } else {
      let tempLine = splitedByLineEnd.slice(chkWA.index + 1);
      if (chkWA.urlLine && chkWA.urlLine.length > 0) {
        chkWA.urlLine.map(el => {
          tempLine.splice(1, 0, el);
        })
      }
      this._isActive = true;
      this._ParseScreen(tempLine, true);
    }
  }
  _removeLayer(splitedByLineEnd: clsv1Response[], removeWorkAreaId) {
    if (this._workAreaId == removeWorkAreaId) {
      this._clearControls();
      if (this.isMasterLayout) {
        this._isActive = true;
        this._ParseScreen(splitedByLineEnd, true);
      } else {
        //pass response to parent screen and remove this dialouge from screen
        if (this.auth.QFSelected) {
          let url = this._utilityService._router.url.split("/");
          if (this.auth.quickFunctionDialougeId && this.auth.quickFunctionDialougeId == this.dialogRef.id && url[1] != 'QF') {
            this.auth.quickFunctionDialougeId = undefined;
            this.auth.QFSelected = undefined;
          }
        }
        if (this.hasTwoSubSequentScreen) {
          this.startingWorkArea._removeLayer = (splitedByLineEnd: clsv1Response[]) => {
            this.startingWorkArea._clearControls();
            this.dialogRef.close(splitedByLineEnd);
          }
          this.startingWorkArea._ParseScreen(splitedByLineEnd)
        } else {
          this.dialogRef.close(splitedByLineEnd);
        }
      }
    } else if (this._workAreaId && removeWorkAreaId > this._workAreaId) {
      this._isActive = true;
    } else if (!this._workAreaId) {
      this._isActive = true;
      this._ParseScreen(splitedByLineEnd, true);
    }
  }
  private hasTwoSubsequentWA(splitedByLineEnd: clsv1Response[]) {
    let virtualWACount = 0;
    let startingWorkArea: ScreenRenderer = new ScreenRenderer(this.v1Utility);
    startingWorkArea._addNewLayer = (splitedByLineEnd: clsv1Response[]) => {
      virtualWACount = virtualWACount + 1;
      startingWorkArea._clearControls();
      startingWorkArea._ParseScreen(splitedByLineEnd, true);
    };
    startingWorkArea._removeLayer = (splitedByLineEnd: clsv1Response[]) => {
      virtualWACount = virtualWACount - 1;
      startingWorkArea._clearControls();
      startingWorkArea._ParseScreen(splitedByLineEnd, true);
    }
    startingWorkArea._showMsgBox = (boxelement) => {
    }
    startingWorkArea._ParseScreen(splitedByLineEnd);
    if (virtualWACount == 2) {
      console.log("***Two WorkArea Found On Layer", this._title);
      return true;
    } else {
      return false;
    }
  }
  _onEnterPress(value: String, isLookUp?: boolean) {
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: '[RAW]' + value
    }).subscribe(x => {
      console.log("After Key Press", x);
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  _autoRequestOnEmptyGrid() {
    this._onEnterPress('');
  }
  _extraSyncRequestOnPrint(cmd) {
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: cmd
    }).subscribe(x => {
      console.log("Onemore Sync Request on Print", x);
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  _showMsgBox(boxElements) {
    this.OpenMsgBox(boxElements);
  }
  _loadGemPadContent() {
    this.v1Utility.GemPadRequest({
      'filename': this._gemPadConfig.filename
    }).subscribe(x => {
      if (this._gemPadConfig.diary_mode == 1) {
        this._gemPadConfig.fileContent = this._gemPadConfig.optional_diary_string + "\r\n\r\n" + x;
      } else {
        this._gemPadConfig.fileContent = x;
      }
    })
  }
  _closeGemPad() {
    if (this._gemPadConfig.readonly == 1) {
      this.auth.gemxPost({
        gconn: this.auth.gConn,
        gcmd: '[GEMPAD][ESC]'
      }).subscribe(x => {
        console.log("After [GEMPAD][ESC] close", x);
        this._ParseScreen(this.v1Utility.parseResponse(x));
      });
    } else {
      this.v1Utility.GemPadRequest({
        'filename': this._gemPadConfig.filename,
        'content': this._gemPadConfig.fileContent
      }).subscribe(z => {
        if (this._gemPadConfig.send_response) {
          this.auth.gemxPost({
            gconn: this.auth.gConn,
            gcmd: '[GEMPAD]0'
          }).subscribe(x => {
            console.log("After [GEMPAD]0 close", x);
            this._ParseScreen(this.v1Utility.parseResponse(x));
          });
        } else {
          this.auth.gemxPost({
            gconn: this.auth.gConn,
            gcmd: '[GEMPAD][ESC]'
          }).subscribe(x => {
            console.log("After [GEMPAD][ESC] close", x);
            this._ParseScreen(this.v1Utility.parseResponse(x));
          });
        }
      });
    }

  }
  _compileQuickFunctions() {
    this.auth.ConstructFunctionKeysList(this.v1Menu.loaderSerivce.lastResponse);
  }
  _print_request(url) {
    this.v1Utility.request_url_handler(url, this.auth.currentUserValue.designation, this.auth.currentUserValue.masterUser)
  }
  onInputfocus(elId) {
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: '[LMCLICK]' + elId
    }).subscribe(x => {
      console.log("After Focus Changed", x);
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  gridActionButtonClicked(icoType) {
    if (this.selectedRows.length > 0) {
      if (icoType == 'csv') {
        this.v1Utility.exportToCsv1(this._grid_columnsToDisplay, this.selectedRows);
      } else if (icoType == 'pdf') {

      } else if (icoType == 'printer') {

      }
    }
  }
  rowDbClicked(event) {
    console.log("Row Double clciked", event);
    this.v1Menu.doNavigation(['[FS][LMCLICK]' + event.index, '[FS]' + event.index]).subscribe(x => {
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  LoadAndOpenRowContextMenu(contextMenuObj) {
    console.log("Fired From Pares Fucntion Class", contextMenuObj)
    if (!this._gridContextMenuLoaded) {
      this.auth.gemxPost({
        gconn: this.auth.gConn,
        gcmd: '[FS][RMCLICK]' + contextMenuObj.rowNumber
      }).subscribe(x => {
        this._gridContextMenuLoaded = true;
        let resLines = this.v1Utility.parseResponse(x);
        if (resLines.find(x => x.code == '127')) {
          this._ParseScreen(resLines);
        }
        if (this._gridContextMenus && this._gridContextMenus.length > 0) {
          this.auth.gemxPost({
            gconn: this.auth.gConn,
            gcmd: this._gridContextMenus[this._gridContextMenus.length - 1].cmd
          }).subscribe(x => {
            //contextMenuObj.showContextMenu(this._gridContextMenus);
            this.notifyContextMenuObj.valueChanged(this._gridContextMenus)
          });
        }
      });
    } else {
      if (this._gridContextMenus && this._gridContextMenus.length > 0) {
        this.notifyContextMenuObj.valueChanged(this._gridContextMenus)
      }
    }
  }
  rowContextMenuClicked(obj) {
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: '[FS][RMCLICK]' + obj.rowNumber
    }).subscribe(x => {
      let resLines = this.v1Utility.parseResponse(x);
      if (resLines.find(x => x.code == '127')) {
        this.auth.gemxPost({
          gconn: this.auth.gConn,
          gcmd: obj.cmd
        }).subscribe(y => {
          this._ParseScreen(this.v1Utility.parseResponse(y));
        });
      }
    });

    this.v1Menu.doNavigation(['[FS][RMCLICK]' + obj.rowNumber, obj.cmd]).subscribe(x => {
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  onRowsSelected(rows) {
    this.selectedRows = rows;
    console.log("Rows Selected", this.selectedRows);
  }
  onRowSelected(row) {
    this.selectedRow = row;
    console.log("Row Selected", this.selectedRow);
  }
  onInquirySearch(event, onClose) {
    console.log("Grid Search clicked", event);
    if (onClose) {
      event = '';
    }
    // if(onClose || (event && event!='' && (event+'').length>0)){
    //   this._onEnterPress(event);
    // }
    this._onEnterPress(event);
  }
  onbtnClick(btnId) {
    if (this.selectedRows.length == 0) {
      this.auth.gemxPost({
        gconn: this.auth.gConn,
        gcmd: '[CTRL]' + btnId
      }).subscribe(x => {
        console.log("After Button Press", x);
        this._ParseScreen(this.v1Utility.parseResponse(x));
      });
    } else {
      let tempRowSelectionCmds = [];
      if (this._gridProps.enable_multiSelect) {
        tempRowSelectionCmds = this.selectedRows.map(x => ('[FS][LMCLICK]' + x.index));
      } else {
        tempRowSelectionCmds = ['[FS][LMCLICK]' + this.selectedRows[this.selectedRows.length - 1].index];
      }
      this.v1Menu.doNavigation(tempRowSelectionCmds).subscribe(y => {
        this.auth.gemxPost({
          gconn: this.auth.gConn,
          gcmd: '[CTRL]' + btnId
        }).subscribe(x => {
          console.log("After Button Press", x);
          this._ParseScreen(this.v1Utility.parseResponse(x));
        });
      })
    }

  }
  _returnMultipleSelection() {
    let cmd = '[FS][MS]' + this.selectedRows.length + ';' + this.selectedRows.map(g => g.index).join('|');
    this.selectedRows = [];
    this.selectedRow = undefined;
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: cmd
    }).subscribe(x => {
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  onLookupInputClicked(event) {
    this._onEnterPress('/' + event);
  }
  dialouge: MatDialogRef<any>;
  OpenLookUp(waId, res, width?, height?) {
    this.dialouge = this._dialog.open(v1LookupComponent, {
      //height: '210px',
      data: { res },
      width: width ? width : '90%', height: height ? height : '85%',
      id: waId,
      disableClose: true,
      autoFocus: false
    });
    this.dialouge.afterClosed().subscribe(x => {
      this._isActive = true;
      this._ParseScreen(x, false);
    });
  }
  _uploadDialouge(res) {
    this._isActive = false;
    this._dialog.open(FileUploadComponent, {
      height: '360px',
      width: '50%',
      disableClose: true,
      data: res
    }).afterClosed().subscribe(z => {
      this._isActive = true;
      this._ParseScreen(z);
    })
  }
  _fileDownloadedCompleted() {
    this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: '[FILE_DNLD]OK'
    }).subscribe(x => {
      console.log("After File Download Completed", x);
      this._ParseScreen(this.v1Utility.parseResponse(x));
    });
  }
  OpenMsgBox(boxElements) {
    this._isActive = false;
    let msgDialouge = this._dialog.open(MSGBOXComponent, {
      //height: '210px',
      data: boxElements,
      width: '650px', height: '185px',
      id: 'open-msg-box' + Math.random().toString(36).substr(2),
      disableClose: true
    });
    msgDialouge.afterClosed().subscribe(x => {
      this._isActive = true;
      this._ParseScreen(this.v1Utility.parseResponse(x), false);
    });
  }
  private parseIdfromResponseArray(res) {
    return res.split('|')[1];
  }
  _stateIsMenuWorkArea(splitedByLine: clsv1Response[]) {
    this.dialogRef.close(splitedByLine.slice(splitedByLine.findIndex(x => x.code == '110')));
  }
  _onComplition() {
    console.log("*************On Complition of Rendring Functionality*************");
    if (this._isOptionSelectionScreen) {
      setTimeout(() => {
        this.dialogRef.updateSize('300px', '85%');
      }, 5);
    }
    if (this.hasTwoSubSequentScreen) {
      setTimeout(() => {
        this.bodyAreaContainer.nativeElement.scroll({
          top: this.bodyAreaContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }, 0);
    }
    setTimeout(() => {
      this.headerTextIsOverflowing = this._isTextOverflowing(this.headTitle);
    }, 6);
    if (this.resPonseSer) {
      this.resPonseSer.stateChanged(this.getRawControls(), this._lastPerformedAction, this._isMenuWorkArea);
      if (this.resPonseSer.isCurrentStateCommingFromProcessFinalState) {
        //this.resPonseSer.emit();
      }
    }
  }
  ngOnDestroy() {
    this.escKeySubscription.unsubscribe();
    this.functionKeySubscription.unsubscribe();
  }
  onScroll() {
    if (this._gridProps.available_more_rows) {
      this.auth.gemxPost({
        gconn: this.auth.gConn,
        gcmd: '[FS][MORE]'
      }).subscribe(x => {
        console.log("After Button Press", x);
        let responseArr = this.v1Utility.parseResponse(x);
        let filteredValue = responseArr.filter(el => el.code == '105');
        if (filteredValue && filteredValue.length == 0) {
          this._gridProps.available_more_rows = false;
        } else {
          this._ParseScreen(this.v1Utility.parseResponse(x));
        }
      });
    }
  }

  _loadAllRows() {
    this.loadMoreCounter += 1;
    // RECURSIVE FUNCTION TO LOAD UPTO 500 MORE ROWS
    let iterativeReq: (moreRowsExist) => Observable<any> = (moreRowsExist: boolean) => {
      if (moreRowsExist) {
        return this._gemRequest('[FS][MORE]').pipe(concatMap(res => {
          this._ParseScreen(this.v1Utility.parseResponse(res));
          if (this._grid_Items.length >= this.loadMoreCounter * 500) {
            return new Observable<any>(ob => { ob.next(true); ob.complete() })
          } else {
            if (this._gridProps.available_more_rows) {
              let responseArr = this.v1Utility.parseResponse(res);
              let filteredValue = responseArr.filter(el => el.code == '105');
              if (filteredValue && filteredValue.length == 0) {
                this._gridProps.available_more_rows = false;
                return iterativeReq(false);
              } else {
                return iterativeReq(true);
              }
              // if (responseArr.length > 1 && responseArr[0].code == '001' && responseArr[1].code == '999') {
              //   return iterativeReq(false);
              // } else {
              //   return iterativeReq(true);
              // }
            }
            else {
              return iterativeReq(false);
            }
          }
        }))
      }
      else {
        return new Observable<any>(ob => { ob.next(false); ob.complete() })
      }
    }

    this._loadAllInProcess = true
    iterativeReq(this._gridProps.available_more_rows).subscribe(res => {
      this._loadAllInProcess = false;
      if (res) {
        console.log("Rows Loaded!")
      }
      else {
        console.log("No more rows to load...")
      }
    });
  }

  _gemRequest(cmd) {
    return this.auth.gemxPost({
      gconn: this.auth.gConn,
      gcmd: cmd
    });
  }
  _isTextOverflowing(_el: ElementRef) {
    let newEl = this._renderer.createElement('h2');
    let text = this._renderer.createText(_el.nativeElement.childNodes[0].data);
    this._renderer.appendChild(newEl, text);
    this._renderer.setStyle(newEl, 'display', 'inline');
    this._renderer.setStyle(newEl, 'width', 'auto');
    this._renderer.setStyle(newEl, 'visibility', 'hidden');
    this._renderer.appendChild(document.body, newEl);
    let rendererWidht = _el.nativeElement.offsetWidth;
    let calculatedWidht = newEl.offsetWidth;
    newEl.remove();
    //console.log("Both Width (Renderer,Calculated) are: ",rendererWidht,calculatedWidht);
    return rendererWidht < calculatedWidht;
  }
}

