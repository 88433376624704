import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '@app/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  title:any;
  body: any;
  template: TemplateRef<any>;
  type: any;
  timeOut: any;
  buttonText: any;

  constructor(public utiliServ:UtilityService,public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title=data.title;
      this.body = data.type != 'customTemplate' ? data.body : this.template = data.body;
      this.type = data.type;
      this.timeOut = data.timeOut;
      this.buttonText = data.buttonText? data.buttonText : 'Add';
      this.utiliServ.msgBoxTitleChenge.subscribe(x=>{
        this.title=x;
      });
    }

  ngOnInit(): void {
  }

}
