import { Component, OnInit } from '@angular/core';
import { TabLayoutService } from './tab-layout.service';

@Component({
  selector: 'app-tab-layout',
  templateUrl: './tab-layout.component.html',
  styleUrls: ['./tab-layout.component.scss'],
  providers:[TabLayoutService]
})
export class TabLayoutComponent implements OnInit {

  constructor(private tabService:TabLayoutService) { }

  ngOnInit(): void {
  }
  SetToComplete(x){
      this.tabService.SetStatusToComplete(x);
  }
}
