import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeClientName'
})
export class ClientNameRemovalPipe implements PipeTransform {

  transform(value: string,clientName:string): string {
    if(value && clientName){
      let tempVal= value.replace('for Client '+clientName.trim(),'');
      if(tempVal.length<value.length)
      {
        return tempVal;
      }else{
        return value.replace(clientName,'');
      }
    }else{
      return value
    }
  }

}
