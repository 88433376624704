// import { ThrowStmt } from '@angular/compiler';
import {
  EventEmitter,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { V1MenuService } from '@app/core';
import { LoaderInterceptor } from '@app/core/interceptors/loader-interceptor.service';
import { AppConstants } from '@app/core/services/app-constants';
import { AppRouteService } from '@app/core/services/app-route.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { LoaderService } from '@app/core/services/loader.service';
import { UtilityService } from '@app/core/services/utility.service';
import { AppUtils } from '@app/core/services/utils/App-Utils';
import { clsCMD, clsSubMenu } from '@app/core/services/v1-menu.service';
import { clsv1Response } from '@app/core/services/v1-utilities.service';
import { v1LookupComponent } from '@app/modules/lookup/v1-lookup.component';
import { Observable } from 'rxjs';
import { repeat } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss'],
})
export class SideMenuBarComponent implements OnInit {
  @Input() showSubMenu: boolean = false;
  @Output() showSubMenuChange = new EventEmitter<boolean>();
  @Output() subMenuRequested = new EventEmitter<any>();
  @Input() panelData = {
    title: '',
    name: '',
    hasPanel: false,
    links: [],
  };
  @Output('menuSelected') menuSelected: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() subMenus;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  //@ViewChild('gridR') terturyMenuTriger: MatMenuTrigger;
  public showPanel = true;
  public appRoutes = AppConstants.APP_ROUTES;
  public isMobile: boolean = false;
  slideToggleOne: boolean = true;
  childTitle;
  shouldrender;
  optionTitle;
  submenutitle;
  prevButtonTrigger;
  gridChildIndex;
  gridChildIndexnew;

  constructor(
    private router: Router,
    private _utilityService: UtilityService,
    public authService: AuthenticationService,
    public v1menuservice: V1MenuService,
    private loaderService: LoaderService,
    private ren: Renderer2,
    public _dialog: MatDialog
  ) {
    this.isMobile = AppUtils.mobileCheck();
  }

  ngOnInit() {
    console.log(this.panelData);
    console.log('this is Main Menu', this.v1menuservice.menu.menus);
    // this.shouldRender = this.v1menuservice.menu.menus[].SubMenues
  }

  menuToggle() {
    this._utilityService.setSideMenuBar(false);
  }

  NavigateToV1Page(cmdArg) {
    console.log('Navigate to v1');
    this.subMenuRequested.emit(cmdArg);
    console.log(this.subMenus[0]?.eventid);
    console.log(this.subMenus);
  }

  open(menu, status) {
    menu.openMenu();
    console.log('this is menu', menu);
  }

  OpenLookUp(waId, res, width?, height?) {
    //this._isActive=false;
    return this._dialog.open(v1LookupComponent, {
      //height: '210px',
      data: { res },
      width: width ? width : '60%',
      height: height ? height : '25%',
      id: waId,
      disableClose: true,
      autoFocus: false,
    });
  }
  onMenuRequest(item, parentIndex, smIndex, cmd: clsCMD, isActive?) {
    this.v1menuservice.stopDashboardPooling.next(true);

    if (!isActive) {
      if (
        this.authService.qfddItems.length < this.authService.functionKeys.length
      ) {
        this.authService.qfddItems = this.authService.functionKeys;
      }

      let impMenu = false;
      let mobMenu = false;

      for (const val of this.v1menuservice.impMenus) {
        if (val.includes(item.title)) {
          impMenu = true;
          break;
        }
      }
      for (const val of this.v1menuservice.mobMenus) {
        if (val.includes(item.title)) {
          mobMenu = true;
          break;
        }
      }

      this.v1menuservice
        .navigateToMenuSection(
          this.loaderService.lastResponse,
          impMenu,
          mobMenu
        )
        .subscribe((_x) => {
          this.v1menuservice.menu.resetAllMenuesonSubChildLoad(
            parentIndex,
            smIndex
          );
          if (cmd.value != '0307') {
            item.loadSubMenus(smIndex, ['[MENU]' + cmd.value]).subscribe((z) => {
              this._utilityService.isAnyModuleRendered()
                ? this._utilityService.navigateToHome()
                : 'false';
              if (z && z.type && z.type == 'WorkArea') {
                if (z.inBetweenMenuScreen) {
                  let resLines: clsv1Response[] = z.responseLines;
                  let waIndex = resLines.findIndex((x) => x.code == '100');
                  let waId = resLines[waIndex].value.split('|')[1];
                  this.OpenLookUp(waId, resLines.slice(waIndex))
                    .afterClosed()
                    .subscribe((x) => {
                      item
                        .loadSubMenus(smIndex, [], undefined, undefined, x)
                        .subscribe();
                    });
                } else {
                  let requestedNavigation = this.v1menuservice
                    .getForwardNavigationPath()
                    .map((x) => x.nameToUrl)
                    .join('/')
                    .toLowerCase();
                  if (mobMenu) {
                    requestedNavigation = 'mobile-' + requestedNavigation;
                  }
                  console.log('Requested Path::::::', requestedNavigation);
                  this._utilityService.navigate(requestedNavigation).then((_z) => {
                    if (requestedNavigation == 'magazine-dashboard') {
                      this.v1menuservice.sphereDashboard = false;
                      this.v1menuservice.magDashboard = true;
                    } else if (requestedNavigation == 'dashboard') {
                      this.v1menuservice.sphereDashboard = true;
                      this.v1menuservice.magDashboard = false;
                      this.v1menuservice.stopDashboardPooling.next(true);
                    }
                    this.v1menuservice.afterMainComponentsReady.emit({
                      type: 'onMenuClick',
                      isMainMenuState: true,
                    });
                  });
                }
              }
            });
          } else if (cmd.value == '0307') {
            this._utilityService.navigate('dashboard').then((_z) => {
              this.v1menuservice.sphereDashboard = true;
              this.v1menuservice.magDashboard = false;
              this.v1menuservice.stopDashboardPooling.next(true);
              this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', isMainMenuState: true });
            });
          }

        });
    }
  }

  OnSubMenuClicked(item, parentIndex, smIndex, submenu) {

    this.gridChildIndex = null;
    this.gridChildIndexnew = null;
    if (
      submenu.childs[smIndex].cmd &&
      submenu.childs[smIndex].cmd.value != null &&
      submenu.childs[smIndex].cmd.value.length > 0
    ) {
      if (submenu.childs[smIndex].isGridBasedMenu) {
        if (submenu.childs[smIndex].isActive == false) {
          this.OnGridMenuRequest(item, parentIndex, smIndex, submenu);
        } else {
          // submenu.childs[smIndex].toggleChild = !submenu.childs[smIndex].toggleChild;
        }
      } else if (submenu.childs[smIndex].shouldRenderOptions) {
        // submenu.childs[smIndex].toggleChild =!submenu.childs[smIndex].toggleChild;
        if (
          submenu.childs[smIndex].shouldOpenCustomForm &&
          (!submenu.childs[smIndex].applyActiveClass)
        ) {
          this.SubMenuesHeaderClicked(
            submenu.childs[smIndex].customformJsonKey,
            submenu.childs[smIndex].title
          );
        }
      }
    } else {
      if (submenu.childs[smIndex].shouldRenderOptions) {
        //  submenu.childs[smIndex].toggleChild = !submenu.childs[smIndex].toggleChild;
        if (
          submenu.childs[smIndex].shouldOpenCustomForm &&
          (!submenu.childs[smIndex].applyActiveClass)
        ) {
          this.SubMenuesHeaderClicked(
            submenu.childs[smIndex].customformJsonKey,
            submenu.childs[smIndex].title
          );
        }
      } else {
        if (
          (!submenu.childs[smIndex].isActive) &&
          (!submenu.childs[smIndex].applyActiveClass)
        ) {
          this.SubMenuesHeaderClicked(
            submenu.childs[smIndex].customformJsonKey,
            submenu.childs[smIndex].title
          );
        }
      }
    }
  }

  OnSubMenuRequest(
    item,
    subItemchild,
    smIndex,
    fChild,
    cmd: clsCMD,
    isGridBasedMenu?
  ) {
    let impMenu = false;
    let mobMenu = false;

    for (const val of this.v1menuservice.impMenus) {
      if (val.includes(item.title)) {
        impMenu = true;
        break;
      }
    }
    for (const val of this.v1menuservice.mobMenus) {
      if (val.includes(item.title)) {
        mobMenu = true;
        break;
      }
    }

    this.v1menuservice
      .navigateToMenuSection(this.loaderService.lastResponse, impMenu, mobMenu)
      .subscribe((_x) => {
        subItemchild.setAsActive(fChild);
        let isDataUpload = item.SubMenues
          ? (item.SubMenues[smIndex].customformJsonKey + '')
            .trim()
            .includes('DataUploadProcessing')
          : false;
        let isFileSentToEnv = isDataUpload
          ? (subItemchild.childs[fChild].customformJsonKey + '')
            .trim()
            .includes('files_sent_to_environment')
          : false;
        if (isDataUpload && isFileSentToEnv) {
          this.SubMenuesHeaderClicked(
            subItemchild.childs[fChild].customformJsonKey,
            subItemchild.childs[fChild].title + ''
          );
        }
        if (
          subItemchild.childs[fChild]['isInqAll'] &&
          subItemchild.childs[fChild].shouldOpenCustomForm
        ) {
          this.SubMenuesHeaderClicked(
            subItemchild.childs[fChild].customformJsonKey,
            'showInquiries'
          );
        } else {
          item
            .loadSubMenus(
              smIndex,
              ['[MENU]' + subItemchild.cmd.value, '[MENU]' + cmd.value],
              true,
              fChild
            )
            .subscribe((z) => {
              this._utilityService.isAnyModuleRendered()
                ? this._utilityService.navigateToHome()
                : 'false';
              if (z && z.type && z.type == 'WorkArea') {
                if (z.inBetweenMenuScreen) {
                  let resLines: clsv1Response[] = z.responseLines;
                  let waIndex = resLines.findIndex((x) => x.code == '100');
                  let waId = resLines[waIndex].value.split('|')[1];
                  this.OpenLookUp(waId, resLines.slice(waIndex))
                    .afterClosed()
                    .subscribe((x) => {
                      item
                        .loadSubMenus(smIndex, [], undefined, undefined, x)
                        .subscribe();
                    });
                } else {
                  let requestedNavigation = this.v1menuservice
                    .getForwardNavigationPath()
                    .map((x) => x.nameToUrl)
                    .join('/')
                    .toLowerCase();
                  if (mobMenu) {
                    requestedNavigation = 'mobile-' + requestedNavigation;
                  }
                  console.log('Requested Path::::::', requestedNavigation);
                  this._utilityService
                    .navigate(requestedNavigation)
                    .then((_z) => {
                      if (
                        subItemchild.childs[fChild].title ==
                        'Magazine Dashboard'
                      ) {
                        this.v1menuservice.sphereDashboard = false;
                        this.v1menuservice.magDashboard = true;
                      }
                      this.v1menuservice.afterMainComponentsReady.emit({
                        type: 'onMenuClick',
                        isMainMenuState: true,
                      });
                    });
                }
              }
            });
        }
      });
  }

  OnGridMenuRequest(item, parentIndex, smIndex, submenu) {
    console.log('That Menu', item, parentIndex, smIndex, submenu);
    this.v1menuservice
      .navigateToMenuSection(this.loaderService.lastResponse)
      .subscribe((_x) => {
        item.resetChildMenusActivate(parentIndex, true);
        submenu.setAsActive(smIndex);
        item
          .loadSubMenus(
            parentIndex,
            ['[MENU]' + submenu.cmd.value, submenu.childs[smIndex].cmd.value],
            true,
            smIndex
          )
          .subscribe((z) => {
            this._utilityService.isAnyModuleRendered()
              ? this._utilityService.navigateToHome()
              : 'false';
            //IF on Grid Menu selection there is Work area
            if (z && z.type && z.type == 'WorkArea') {
              if (z.inBetweenMenuScreen) {
                let resLines: clsv1Response[] = z.responseLines;
                let waIndex = resLines.findIndex((x) => x.code == '100');
                let waId = resLines[waIndex].value.split('|')[1];
                this.OpenLookUp(waId, resLines.slice(waIndex))
                  .afterClosed()
                  .subscribe((x) => {
                    item
                      .loadSubMenus(smIndex, [], undefined, undefined, x)
                      .subscribe();
                  });
              } else {
                let requestedNavigation = this.v1menuservice
                  .getForwardNavigationPath()
                  .map((x) => x.nameToUrl)
                  .join('/')
                  .toLowerCase();
                console.log('Requested Path::::::', requestedNavigation);
                this._utilityService.navigate(requestedNavigation).then((_z1) => {
                  this.v1menuservice.afterMainComponentsReady.emit({
                    type: 'onMenuClick',
                    isMainMenuState: true,
                  });
                });
              }
            }
          });
      });
  }

  newOnFormMenuRequest(item, subItemchild, gridChildMenues, smIndex, firstChildIndex, gridChildItemIndex, cmd = null, fChild = null, child?) {
    if (child && child.childs && child.childs.length > 0) {
      this.gridChildIndexnew = fChild;
    }
    else {
      item.resetChildMenusActivate(smIndex);
      this.gridChildIndex = gridChildItemIndex;
      if (gridChildItemIndex && subItemchild.childs[gridChildItemIndex]) {
        subItemchild.setAsActive(gridChildItemIndex);
      }
      if (
        gridChildMenues['isInqAll'] == true &&
        gridChildMenues.shouldOpenCustomForm
      ) {
        this.SubMenuesHeaderClicked(
          gridChildMenues.customformJsonKey,
          'showInquiries'
        );
      } else {
        let impMenu = false;
        let mobMenu = false;

        for (const val of this.v1menuservice.impMenus) {
          if (val.includes(item.title)) {
            impMenu = true;
            break;
          }
        }
        for (const val of this.v1menuservice.mobMenus) {
          if (val.includes(item.title)) {
            mobMenu = true;
            break;
          }
        }
        this.v1menuservice.navigateToMenuSection(this.loaderService.lastResponse, impMenu, mobMenu).subscribe((x) => {
          let cmd2, cmd3;
          if (subItemchild.cmd.value) {
            cmd2 = subItemchild.isGridBasedMenu ? subItemchild.cmd.value : '[MENU]' + subItemchild.cmd.value;
            cmd3 = gridChildMenues.cmd.value && gridChildMenues.cmd.value.startsWith('[FS') ? gridChildMenues.cmd.value : '[MENU]' + gridChildMenues.cmd.value;
          } else {
            cmd2 = '[MENU]' + gridChildMenues.cmd.value;
          }

          if (cmd) {
            cmd3 = cmd.startsWith('[FS') ? cmd : '[MENU]' + cmd;
          }

          let cmdArray = cmd3 ? ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmd2, cmd3] : ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmd2];
          subItemchild.setAsActive(gridChildItemIndex);
          gridChildMenues.setAsActive(fChild);
          item.loadSubMenus(smIndex, cmdArray, true, firstChildIndex).subscribe((res) => {
            this._utilityService.isAnyModuleRendered() ? this._utilityService.navigateToHome() : false;
            if (res && res.type && res.type == 'WorkArea') {
              if (res.inBetweenMenuScreen) {
                let resLines: clsv1Response[] = res.responseLines;
                let waIndex = resLines.findIndex((x) => x.code == '100');
                let waId = resLines[waIndex].value.split('|')[1];
                this.OpenLookUp(waId, resLines.slice(waIndex)).afterClosed().subscribe((x) => {
                  item.loadSubMenus(smIndex, ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmd2], true, firstChildIndex).subscribe();
                });
              } else {
                subItemchild.setAsActive(gridChildItemIndex);
                gridChildMenues.setAsActive(fChild);

                let requestedNavigation = this.v1menuservice.getForwardNavigationPath().map((x) => x.nameToUrl).join('/').toLowerCase();
                if (mobMenu) {
                  requestedNavigation = 'mobile-' + requestedNavigation;
                }
                console.log('Requested Path::::::', requestedNavigation);
                this._utilityService.navigate(requestedNavigation).then((z) => {
                  this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', isMainMenuState: true });
                });
              }
            } else if ((cmdArray.length > 2 && res == undefined) || (res && res.type == 'preselected')) {
              subItemchild.setAsActive(gridChildItemIndex);
              gridChildMenues.setAsActive(fChild);
              let requestedNavigation = this.v1menuservice.getForwardNavigationPath().map((x) => x.nameToUrl).join('/').toLowerCase();
              if (mobMenu) {
                requestedNavigation = 'mobile-' + requestedNavigation;
              }
              console.log('Requested Path::::::', requestedNavigation);
              this._utilityService.navigate(requestedNavigation).then((z) => {
                this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', isMainMenuState: true, });
              });
            }
          });
        });
      }
    }

  }

  newMenuLayer(item, submenu, subItemchild, gridChildMenues, child, smIndex, firstChildIndex, gridChildItemIndex, fChild, fChild2, child2cmd) {

    item.resetChildMenusActivate(smIndex);
    let impMenu = false;
    let mobMenu = false;

    for (const val of this.v1menuservice.impMenus) {
      if (val.includes(item.title)) {
        impMenu = true;
        break;
      }
    }
    for (const val of this.v1menuservice.mobMenus) {
      if (val.includes(item.title)) {
        mobMenu = true;
        break;
      }
    }
    this.v1menuservice.navigateToMenuSection(this.loaderService.lastResponse, impMenu, mobMenu).subscribe((x) => {
      const cmdsObj = {}; // object that contains cmd sequence
      // let cmd2, cmd3, cmd4;
      if (subItemchild.cmd.value) {
        cmdsObj['cmd2'] = subItemchild.isGridBasedMenu ? subItemchild.cmd.value : '[MENU]' + subItemchild.cmd.value;
        cmdsObj['cmd3'] = gridChildMenues.cmd.value && gridChildMenues.cmd.value.startsWith('[FS') ? gridChildMenues.cmd.value : '[MENU]' + gridChildMenues.cmd.value;
      } else {
        cmdsObj['cmd2'] = '[MENU]' + gridChildMenues.cmd.value;
        if(gridChildMenues.childs[fChild].cmd.value) {
          cmdsObj['cmd3'] = gridChildMenues.childs[fChild].cmd.value;  
        } else {
          cmdsObj['cmd3'] = '[MENU]' + gridChildMenues.childs[fChild].childs[fChild2].cmd.value;
        }
      }

      if (child2cmd && subItemchild.cmd.value) {
        cmdsObj['cmd3'] = child2cmd.startsWith('[FS') ? child2cmd : '[MENU]' + child2cmd;
      } else if (gridChildMenues.childs[fChild].cmd.value) {
        // only if menu is 5 level deep.
        cmdsObj['cmd4'] = child2cmd.startsWith('[FS') ? child2cmd : '[MENU]' + child2cmd;
      }

      // let cmdArray = cmd3 ? ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmd2, cmd3, cmd4] : ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmd2];
      let cmdArray = cmdsObj['cmd3'] ?
        [
          '[MENU]' + item.SubMenues[smIndex].cmd.value,
          ...Object.values(cmdsObj).map(cmd => cmd) // spread out the cmds from the object.
        ]
        : ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmdsObj['cmd2']];
      subItemchild.setAsActive(gridChildItemIndex);
      gridChildMenues.setAsActive(fChild);
      child.setAsActive(fChild2);

      item.loadSubMenus(smIndex, cmdArray, true, firstChildIndex).subscribe((res) => {
        this._utilityService.isAnyModuleRendered() ? this._utilityService.navigateToHome() : false;
        if (res && res.type && res.type == 'WorkArea') {
          if (res.inBetweenMenuScreen) {
            let resLines: clsv1Response[] = res.responseLines;
            let waIndex = resLines.findIndex((x) => x.code == '100');
            let waId = resLines[waIndex].value.split('|')[1];
            this.OpenLookUp(waId, resLines.slice(waIndex)).afterClosed().subscribe((x) => {
              item.loadSubMenus(smIndex, ['[MENU]' + item.SubMenues[smIndex].cmd.value, cmdsObj['cmd2']], true, firstChildIndex).subscribe();
            });
          } else {
            subItemchild.setAsActive(gridChildItemIndex);
            gridChildMenues.setAsActive(fChild);
            child.setAsActive(fChild2);

            let requestedNavigation = this.v1menuservice.getForwardNavigationPath().map((x) => x.nameToUrl).join('/').toLowerCase();
            if (mobMenu) {
              requestedNavigation = 'mobile-' + requestedNavigation;
            }
            console.log('Requested Path::::::', requestedNavigation);
            this._utilityService.navigate(requestedNavigation).then((z) => {
              this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', isMainMenuState: true });
            });
          }
        } else if ((cmdArray.length > 2 && res == undefined)) {
          subItemchild.setAsActive(gridChildItemIndex);
          gridChildMenues.setAsActive(fChild);
          child.setAsActive(fChild2);
          let requestedNavigation = this.v1menuservice.getForwardNavigationPath().map((x) => x.nameToUrl).join('/').toLowerCase();
          if (mobMenu) {
            requestedNavigation = 'mobile-' + requestedNavigation;
          }
          console.log('Requested Path::::::', requestedNavigation);
          this._utilityService.navigate(requestedNavigation).then((z) => {
            this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', isMainMenuState: true, });
          });
        }
      });
    });


  }

  SubMenuesHeaderClicked(titleKey, title) {
    this.gridChildIndex = null;
    this.gridChildIndexnew = null;
    let tempTitleKey = titleKey;
    let allInqMen = null;
    let isInvManagement = false;
    if (tempTitleKey.startsWith('inventory_management')) {
      allInqMen = this.v1menuservice.menu.menus
        .find((main) =>
          main.SubMenues.find((firstChild) => firstChild.isActive == true)
        )
        .SubMenues.find((secondChild) => secondChild.isActive == true)
        .childs.find((thirdChild) => thirdChild.isActive == true)
        .childs.filter((child) => child['isInqAll'] == true);
      isInvManagement = true;
    } else if (
      tempTitleKey.startsWith('order_processing') ||
      tempTitleKey.startsWith('arn_processing') ||
      tempTitleKey.startsWith('shipment_processing')
    ) {
      allInqMen = this.v1menuservice.menu.menus
        .find((x) => x.SubMenues.find((y) => y.isActive == true) != undefined)
        .SubMenues.find((y) => y.isActive == true)
        .childs.filter((x) => x['isInqAll'] == true);
    }

    if (allInqMen && allInqMen.length > 0) {
      if (allInqMen[0]['toggleAdvFilter'])
        allInqMen[0]['toggleAdvFilter'](true);
      else {
        let inq = this.v1menuservice.menu
          .getInquiriesMenuItems(isInvManagement ? 'lower' : 'upper')
          .find((x) =>
            (x.title + '')
              .trim()
              .toLowerCase()
              .match(/inquiries/)
          );
        if (inq && inq.childs.length > 0) {
          this.v1menuservice
            .navigateToMenuSection(this.loaderService.lastResponse)
            .subscribe((_x) => {
              let requestedNavigation =
                this.v1menuservice.getForwardNavigationPath();
              let tempMenu = requestedNavigation
                .filter((y) => y.type == 'request')
                .map((z) => z.cmd);
              this.v1menuservice.doNavigation(tempMenu).subscribe((_z) => {
                if (isInvManagement) {
                  this._utilityService
                    .navigate(
                      tempTitleKey.startsWith('inventory_management')
                        ? '/inventory-inquiries'
                        : ''
                    )
                    .then((_x1) => {
                      this.v1menuservice.afterMainComponentsReady.emit({
                        type: 'onMenuClick',
                      });
                    });
                } else {
                  let showInqPanOnLoad =
                    title && title.length > 0 && title == 'showInquiries'
                      ? '/inquiries'
                      : '';
                  if (tempTitleKey.startsWith('shipment_processing')) {
                    this._utilityService
                      .navigate('shipment-inquiries')
                      .then((_x2) => {
                        this.v1menuservice.afterMainComponentsReady.emit({
                          type: 'onMenuClick',
                        });
                      });
                  } else {
                    this._utilityService
                      .navigate(
                        tempTitleKey.startsWith('order_processing')
                          ? 'orders' + showInqPanOnLoad
                          : 'arn' + showInqPanOnLoad
                      )
                      .then((_x3) => {
                        this.v1menuservice.afterMainComponentsReady.emit({
                          type: 'onMenuClick',
                        });
                      });
                  }
                }
              });
            });
        }
      }
    } else if (
      tempTitleKey.startsWith('suppliers') ||
      tempTitleKey.startsWith('bin_locations_global') ||
      tempTitleKey.startsWith('customers') ||
      tempTitleKey.startsWith('inventory_accounts') ||
      tempTitleKey.startsWith('products') ||
      tempTitleKey.startsWith('bin_schedules') ||
      tempTitleKey.startsWith('inventory_safety_stock') ||
      tempTitleKey.startsWith('commodity_codes') ||
      tempTitleKey.startsWith('inv_acct_assign_priority') ||
      tempTitleKey.includes('files_sent_to_environment')
    ) {
      let inq;
      let routingPath;
      if (tempTitleKey.startsWith('suppliers')) {
        routingPath = 'suppliers';
      } else if (tempTitleKey.startsWith('bin_locations_global')) {
        routingPath = 'bin-locations';
      } else if (tempTitleKey.startsWith('customers')) {
        routingPath = 'customers';
      } else if (tempTitleKey.startsWith('inventory_accounts')) {
        routingPath = 'inventory';
      } else if (tempTitleKey.startsWith('products')) {
        routingPath = 'products-inquiry';
      } else if (tempTitleKey.startsWith('bin_schedules')) {
        routingPath = 'bin-schedules';
      } else if (tempTitleKey.startsWith('inventory_safety_stock')) {
        routingPath = 'inventory-safety-stock';
      } else if (tempTitleKey.startsWith('commodity_codes')) {
        routingPath = 'commodity-codes';
      } else if (tempTitleKey.startsWith('inv_acct_assign_priority')) {
        routingPath = 'inv-acct-assign-priority';
      } else if (tempTitleKey.includes('files_sent_to_environment')) {
        routingPath = 'filestoenvironment';
      }

      inq = this.v1menuservice.menu.getSubMenues(title);
      if (
        inq &&
        (inq.childs.length > 0 ||
          tempTitleKey.includes('files_sent_to_environment'))
      ) {
        let cmd;
        if (tempTitleKey.startsWith('inv_acct_assign_priority')) {
          cmd = inq.childs[0].cmd.value;
        } else if (tempTitleKey.includes('files_sent_to_environment')) {
          cmd = inq.cmd.value;
        } else {
          cmd = inq.childs[1].cmd.value;
        }
        if (cmd) {
          this.v1menuservice
            .navigateToMenuSection(this.loaderService.lastResponse)
            .subscribe((_x) => {
              let requestedNavigation =
                this.v1menuservice.getForwardNavigationPath();
              let tempMenu = requestedNavigation
                .filter((y) => y.type == 'request')
                .map((z) => z.cmd);
              this.v1menuservice.doNavigation(tempMenu).subscribe((_z) => {
                this._utilityService.navigate(routingPath).then((_x2) => {
                  this.v1menuservice.afterMainComponentsReady.emit({
                    type: 'onMenuClick',
                    inqCmd: cmd,
                  });
                });
              });
            });
        }
      }
    }
  }

  enteredButton = false;
  isMatMenuOpen = false;

  menuenter() {
    this.isMatMenuOpen = true;
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (this.prevButtonTrigger) {
        this.prevButtonTrigger.closeMenu();
      }
      this.isMatMenuOpen = false;
    }, 80);
  }

  buttonEnter(trigger) {
    if(!trigger){
      return
    }
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    if(!trigger){
      return
    }
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button, 'cdk-focused');
        this.ren.removeClass(button, 'cdk-program-focused');
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(button, 'cdk-focused');
        this.ren.removeClass(button, 'cdk-program-focused');
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  hide_mat_menu(menu) {
    if (menu != undefined) {
      menu.closeMenu();
    }
  }

  onGridChildMenuesClicked(parentIndex, gridChildMenues: any) {
    this.gridChildIndex =
      this.gridChildIndex === parentIndex ? null : parentIndex;
    //   gridChildMenues.toggleChild = !gridChildMenues.toggleChild;
  }

  toggleMenuElements(item?, submenu?, subItemchild?, gridChildMenues?, child?, child2?) {
    console.log(item, submenu, subItemchild, gridChildMenues, child, child2);
    if (item) {
      item.toggleChild = !item.toggleChild
    }
    if (submenu) {
      item.toggleChild = true
      submenu.toggleChild = !submenu.toggleChild
    };

    if (subItemchild) {
      item.toggleChild = true
      submenu.toggleChild = true
      if (submenu.childs.length > 0) {
        for (let val1 of submenu.childs) {
          if (val1.toggleChild == true) {
            if (val1.childs.length > 0) {
              for (let val2 of val1.childs) {
                if (val2.toggleChild == true && val2 != gridChildMenues) {
                  val2.toggleChild = false;
                  if (val2.childs.length > 0) {
                    for (let val3 of val2.childs) {
                      if (val3.toggleChild == true && val3 != child) {
                        val3.toggleChild = false;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      subItemchild.toggleChild = !subItemchild.toggleChild
    }
    if (gridChildMenues) {
      item.toggleChild = true
      submenu.toggleChild = true
      subItemchild.toggleChild = true
      gridChildMenues.toggleChild = !gridChildMenues.toggleChild
    }
    if (child) {
      item.toggleChild = true
      submenu.toggleChild = true
      subItemchild.toggleChild = true
      gridChildMenues.toggleChild = true
      if (gridChildMenues.childs.length > 0) {
        for (let i = 0; i < (gridChildMenues.childs.length); i++) {
          if (gridChildMenues.childs[i] != child) {
            gridChildMenues.childs[i].toggleChild = false;
          }
        }
      }
      child.toggleChild = !child.toggleChild
    }
    if (child2) {
      item.toggleChild = true
      submenu.toggleChild = true
      subItemchild.toggleChild = true
      gridChildMenues.toggleChild = true
      child.toggleChild = true
      child2.toggleChild = !child2.toggleChild
    }

  }
}
