import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-history-side-panel',
  templateUrl: './history-side-panel.component.html',
  styleUrls: ['./history-side-panel.component.scss']
})
export class HistorySidePanelComponent implements OnInit {

  history: any;
  @Input('historyData') set historyData(data) {
    this.history = data
  }
  @Input('toggle') _opened=false;
  @Output('toggleChange') _toggleChange=new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this._opened=false;
    this._toggleChange.emit(this._opened);
  }

}
