import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabLayoutService {
  tabClicked=new EventEmitter<any>();
  isFirst:boolean=false;
  isLast:boolean=false;

  constructor() {}
  statusToCompleteEvent=new EventEmitter<any>();
  SetStatusToComplete(tabId){
    this.statusToCompleteEvent.emit(tabId);
  }
   NavigateTo=new EventEmitter<any>();
}
