import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UtilityService } from '@app/core';
import { DropDown } from '../select-search/select-search.model';

@Component({
  selector: 'app-select-with-action-item',
  templateUrl: './select-with-action-item.component.html',
  styleUrls: ['./select-with-action-item.component.scss']
})
export class SelectWithActionItemComponent implements OnInit {

  ddlOptions: DropDown[] = [];
  copyOptions: DropDown[] =[];
  showList: boolean = false;
  isTooltipActive: boolean = false;
  controlId: string;
  txtSearch: string;
  actionText: string;
  defaultValue: any;

  @Input('actionText') set text(value) {
    this.actionText = value;
    this.isTooltipActive = this.actionText.length > 7? false : true;
  }

  @Input('options') set options(data: DropDown[]) {
    this.ddlOptions = data;
  }

  @Input() set setValue(value) {
    this.defaultValue = value;
    if (this.defaultValue[this.displayLabel])
      this.txtSearch = this.defaultValue[this.displayLabel];
  }

  @Input() displayLabel: string = 'name';
  @Input() bindValue: string = 'id';


  @Input() set id(id: string) {
    this.controlId =  id;
  }

  @Output('selected') selectedOption = new EventEmitter<any>();
  @Output('actionClick') actionClick: EventEmitter<any> = new EventEmitter<any>();


  constructor(private _utilityService: UtilityService) { }

  ngOnInit() {
    // this.copyOptions = this._utilityService.deepCopy(this.ddlOptions);
    this.copyOption();
    if (!this.controlId) {
      this.controlId  = String.fromCharCode(10 + Math.floor(Math.random() * 2)) + Date.now();
    }
    // this.isTooltipActive = this.actionText.length > 6? false : true;
  }

  searchValue(value: string) {
    if (value) {
      if (this.ddlOptions) {
        this.copyOptions = this.ddlOptions.filter(row => row[this.displayLabel].toLocaleLowerCase().includes(value));
      } else {
        this.copyOption();
      }
    } else {
      this.copyOption();
    }
  }

  optionSelected(option: any) {
    if (option) {
      this.defaultValue = option;
      this.txtSearch = option[this.displayLabel];
    }
    this.selectedOption.emit(option);
  }

  focusOut() {
    setTimeout(() => {
      this.showList = false
    }, 200);
  }

  searchFocus () {
    this.showList = !this.showList;
    if (this.showList)
      document.getElementById(this.controlId).focus();
  }

  actionEmit() {
    this.actionClick.emit();
  }

  copyOption() {
    this.copyOptions = this._utilityService.deepCopy(this.ddlOptions);
  }

}
