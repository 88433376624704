<div class="d-flex">
  <div class="circle"></div>
  <div class="background-gradient">
    <div class="copy-rights">
      <span class="cop">Copyright &copy; 2021 SphereWMS. All Rights Reserved</span>
    </div>
  </div>
  <div class="login-container" style="visibility: visible">
    <div class="align-items-center container-haider d-flex justify-content-between pb-1 px-3">
      <h3 class="container-heading m-0">Authenticator</h3>
      <img src="../../../../assets/icon/close.svg" (click)="closeDialoge()">
    </div>
    <div class="h-100 my-5">
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <div class="info-text">
            <p>
              Your login requires Two Factor Authentication. Please install Google Authenticator IOS
              Google Authenticator or other TOTP (Time-based One-Time Password) Supporting MFA.
              Then scan the below QR to get your logins Secret key
            </p>
          </div>
        </div>
      </div>

      <div *ngIf="qrCode" class="row mt-2">
        <div class="col-md-12 col-xs-12 col-12">
          <div class="auth-code">
            <p>xdt3chgpdzy6vevx</p>
          </div>
        </div>
        <div class="col-md-12 col-12 text-center">
          <img width="125" class="my-3" [src]="qrCode" />
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12 col-12" *ngIf="qrCode">
          <div style="display: block;" class="info-text">
            <p style="line-height: 0px;">Enter the verification code generated by Authenticator to</p>
            <p>validate setup correctly.</p>
          </div>
        </div>

        <div class="col-md-12 col-12 text-center">
          <div style="display: inline-block;">
            <ngx-otp-input #totpElem (fill)="fillOTPValue($event)" [config]="otpInputConfig" [ngClass]="{'otp-error': invalidOTP}"></ngx-otp-input>
          </div>
          <p *ngIf="invalidOTP" class="text-danger m-0">We're sorry, your One-Time Password is incorrect. Please try again.</p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12 col-12 text-center">
          <button type="button" id="btn-signin" class="signin-button">Validate</button>
        </div>
      </div>
    </div>
  </div>
</div>