<div class="">
    <label *ngIf="label" [for]="controlId">{{label}}</label>
    <div class="custom-autocomplete-container" [id]="'auto-'+controlId">
        <div class="autocomplete-area" [ngClass]="{'brd-b-r-0 brd-b-l-0 border-primary': showOption}">
            <input type="text" [id]="controlId" [name]="controlId" [placeholder]="placeholder" [(ngModel)]="searchValue" (ngModelChange)="Search()" (focus)="showOption = true;" (focusout)="focusOut()" (keydown.enter)="onEnterClick()">
            <!-- Lookup Area Start -->
            <div class="lookup-area" *ngIf="isLookup">
                <img src="assets/icon/search.svg" (click)="openLookUp()">
            </div>
            <!-- Lookup Area End -->
        </div>

        <!-- Options Listing Area Start -->
        <ng-container *ngIf="showOption">
            <ul class="item-list border-primary">
                <li *ngFor="let option of filteredOptions; let i = index;" class="item" [attr.data-label]="option[valueKey]" (click)="optionSelect(option)">
                    {{option[displayKey]}}
                </li>
            </ul>
        </ng-container>
        <!-- Options Listing Area End -->

    </div>
</div>