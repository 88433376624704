import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from './popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  constructor( public dialog: MatDialog) {

  }
  OpenCustomTemplate(title,customTemplate,timeout?,width?,height?,AutoClose?, buttonText?) {
    let temp = this.dialog.open(PopupComponent, {
      //height: '210px',
      data: {title:title, body: customTemplate, type: 'customTemplate', timeOut: timeout, AutoClose: AutoClose, buttonText: buttonText },
      width: width ? width : '745px', height: height ? height:'150px',
      id: 'open-custom-template' + Math.random().toString(36).substr(2)
    });
    return temp;
  }
}
