import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {GridTabDirective} from './controls/grid/directives/tab-buttons.directive'
import  {GridColumnsArrangPopOverDirective}from './controls/grid/directives/columns-arrange.directive'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import {MatBadgeModule} from '@angular/material/badge';


//---------------- Controls
import { GridComponent } from './controls/grid/grid.component';
import { GridPivotComponent } from './controls/grid/grid-pivot/grid-pivot.component';
import { SelectSearchComponent } from './controls/select-search/select-search.component';
import { MultiSelectComponent } from './controls/multi-select/multi-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScreenTitleComponent } from './controls/screen-title/screen-title.component';
import { AdvanceFilterComponent } from './controls/grid/advance-filter/advance-filter.component';
import { SearchBoxComponent } from './controls/grid/search-box/search-box.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { GridExportComponent } from './controls/grid/grid-export/grid-export.component';
import { FileUploadComponent } from './controls/file-upload/file-upload.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { TabLayoutComponent } from './controls/tab-layout/tab-layout.component';
import { TabLayoutDirective } from './controls/tab-layout/tab-layout.directive';
import { TabLayoutBodyDirective } from './controls/tab-layout/tab-layout-body.directive';

import { CharReplacePipe } from "./pipe/char-replace/char-replace.pipe";
import { DropdownButtonComponent } from './controls/dropdown-button/dropdown-button.component';
import {  MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProgressBarComponent } from './controls/progress-bar/progress-bar.component';
import { PopupComponent } from './controls/popup/popup.component';
import {PopupService} from './controls/popup/popup.service';
import { DomClickDirective } from './directives/dom-click/dom-click.directive';
import { ScannerComponent } from './controls/scanner/scanner.component';
import { HistorySidePanelComponent } from './controls/history-side-panel/history-side-panel.component';
import { SelectWithActionItemComponent } from './controls/select-with-action-item/select-with-action-item.component';
import { EllipsesPipe } from './pipe/ellipses/ellipses.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSlideToggleDirective } from './directives/slide-toggle/mat-slide-toggle.directive';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';



import { AutocompleteComponent } from './controls/autocomplete/autocomplete.component';
import { LookUpInput } from './controls/lookup-input/lookup-input.component';

import { ProgressSpinnerComponent } from './controls/progress-spinner/progress-spinner.component';

import { BadgeComponent } from './controls/Badge/badge.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { CollapsibleDirective } from './directives/collapsible/collapsible.directive';
import { RemoveNumberPipe } from './pipe/remove-number.pipe';
import {ClientNameRemovalPipe} from './pipe/client-name-removal/client-name-removal.pipe'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RowActionButtonDirective } from './controls/grid/row-action-button.directive';
import { GridRowDirective } from './controls/grid/directives/row.directive';
import { FocusDirective } from './directives/input-focus/focus.directive';
import { GridConfirmationPopupComponent } from './controls/grid/grid-confirmation-popup/grid-confirmation-popup.component';
import { V1UIComponent } from './controls/v1-UI-layout/v1-ui.component';
import {RowClickHandlerDirective} from './controls/grid/directives/row-click-handler.directive';

@NgModule({
    declarations: [
        GridComponent,
        GridTabDirective,
        GridPivotComponent,
        SelectSearchComponent,
        MultiSelectComponent,
        ScreenTitleComponent,
        AdvanceFilterComponent,
        SearchBoxComponent,
        GridExportComponent,
        FileUploadComponent,
        GridColumnsArrangPopOverDirective,
        TabLayoutComponent,
        TabLayoutDirective,
        TabLayoutBodyDirective,
        CharReplacePipe,
        DropdownButtonComponent,
        ProgressBarComponent,
        ProgressSpinnerComponent,
        PopupComponent,
        DomClickDirective,
        ScannerComponent,
        HistorySidePanelComponent,
        SelectWithActionItemComponent,
        EllipsesPipe,
        MatSlideToggleDirective,
        AutocompleteComponent,
        LookUpInput,
        CollapsibleDirective,
        RemoveNumberPipe,
        ClientNameRemovalPipe,
        BadgeComponent,
        RowActionButtonDirective,
        GridRowDirective,
        FocusDirective,
        GridConfirmationPopupComponent,
        GridConfirmationPopupComponent,
        RowClickHandlerDirective,
        V1UIComponent
    ],
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        FormsModule,
        MatMenuModule,
        MatPaginatorModule,
        NgSelectModule,
        MatDialogModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DragDropModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSlideToggleModule,
        FormsModule,
        MatTooltipModule,
        ScrollingModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatSliderModule,
        MatBadgeModule,
        MatProgressBarModule,
        MatExpansionModule,
    ],
    exports: [
      GridComponent,
      GridTabDirective,
      SelectSearchComponent,
      MultiSelectComponent,
      ScreenTitleComponent,
      MatIconModule,
      MatTooltipModule,
      AdvanceFilterComponent,
      SearchBoxComponent,
      NgSelectModule,
      GridExportComponent,
      FileUploadComponent,
      DropdownButtonComponent,
      MatDialogModule,
      MatFormFieldModule,
      MatDatepickerModule,
      MatMenuModule,
      DragDropModule,
      GridColumnsArrangPopOverDirective,
      CharReplacePipe,
      TabLayoutComponent,
      MatCheckboxModule,
      MatRadioModule,
      MatSlideToggleModule,
      FormsModule,
      ProgressBarComponent,
      TabLayoutDirective,
      TabLayoutBodyDirective,
      DomClickDirective,
      ScannerComponent,
      HistorySidePanelComponent,
      SelectWithActionItemComponent,
      EllipsesPipe,
      ScrollingModule,
      MatSlideToggleDirective,
      MatAutocompleteModule,
      AutocompleteComponent,
      LookUpInput,
      CollapsibleDirective,
      RemoveNumberPipe,
      ClientNameRemovalPipe,
      ProgressSpinnerComponent,
      BadgeComponent,
      MatBadgeModule,
      MatIconModule,
      MatProgressBarModule,
      RowActionButtonDirective,
      FocusDirective,
      RowClickHandlerDirective,
      V1UIComponent

    ],
    providers: [PopupService],
    schemas:[]
})
export class SharedModule { }
