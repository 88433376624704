import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent implements OnInit {

  controlId: string;
  placeholder: string = 'Scan';
  label: string;

  @Input('id') set referenceId(id) {
    if (id)
      this.controlId = id;
  }

  @Input("label") set displayLabel(value) {
    if (value)
      this.label = value;
  }

  @Input('placeholder') set placeHolder(value) {
    if (value)
      this.placeholder = value;
  }

  @Output('scanned') scanned = new EventEmitter<any>();

  scannedObject: any;

  constructor() {
    // Create dynamic ID if no ID is given
    if(!this.controlId) {
      this.controlId = 'scan-' + String.fromCharCode(10 + Math.floor(Math.random() * 2)) + Date.now();
    }
  }

  ngOnInit(): void {
  }

  // Need to implement the scanner functionality in this function
  scannedItem() {
    this.scanned.emit(this.scannedObject);
  }

}
