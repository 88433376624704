import {Directive, AfterContentInit, AfterViewInit, ElementRef, HostListener, Renderer2} from '@angular/core';
// import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';

@Directive({
  selector: '[grid-tab-def]',
})
export class GridTabDirective implements AfterContentInit,AfterViewInit {
  constructor(private _el: ElementRef,private renderer:Renderer2) {
    //el.nativeElement.style.backgroundColor = 'yellow';
  }
  ngAfterContentInit() {
    // contentChildren is set
    console.log("Tabs Area",this._el);
    this.renderer.addClass(this._el.nativeElement.children[0],"active");
  }

  ngAfterViewInit() {
    // viewChildren is set
  }

  @HostListener('click', ['$event'])
    onButtonClick(event: any) {
    // Logs the id of the element
    // where the event is originally invoked.\

    if(event.target.localName=='button'){
      for(let i=0; i<this._el.nativeElement.children.length;i++){
        this.renderer.removeClass(this._el.nativeElement.children[i],"active");
      }
      console.log(event,"target Id",event.target.id);
      this.renderer.addClass(event.target,"active");
    }


    }
}
