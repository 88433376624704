import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { ApienvService } from './apienv.service';
import { map, tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class V1PrinterService {

  private settingsUrl: string;
  private documentPrintUrl: string;
  private labelPrintUrl: string;

  constructor(
    private http: HttpClient,
    private env: ApienvService
  ) {
    this.settingsUrl = this.env.API_Config.gemxUrl + '/scripts/printer_settings.php';
    this.documentPrintUrl = this.env.API_Config.gemxUrl + '/scripts/print.php';
    this.labelPrintUrl = this.env.API_Config.gemxUrl + '/scripts/sphereprint.php';
  }

  private httpPost(url: string, postData: object): Observable<any> {
    // Convert postData to HttpParams otherwise the interceptor service
    // causes errors
    let postParams = new HttpParams();
    for (const k in postData) {
      postParams = postParams.set(k, postData[k]);
    }

    // Setup http post request
    return this.http
      .post(
        url, postParams,
        {
          headers: new HttpHeaders({
            'content-type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic 1234'
          })
        }
      );
  }

  getSpherehelperAccount(environment: string) {
    // Set the post data
    let postData = {
      action: 'get_account',
      environment: environment
    };
    // Send http request
    return this.httpPost(this.settingsUrl, postData)
      .pipe(map(responseData => {
        return responseData?.success ? responseData.account : responseData;
      }));
  }

  createSpherehelperAccount(environment: string) {
    // Set the post data
    let postData = {
      action: 'create_account',
      environment: environment
    };
    // Send http request
    return this.httpPost(this.settingsUrl, postData);
  }

  getSpherehelperDevices(environment: string, sync?: boolean) {
    // Set the post data
    let postData = {
      action: 'printers',
      environment: environment,
      ...(sync && { refresh: true })
    };
    // Send http request
    return this.httpPost(this.settingsUrl, postData);
  }

  getSettings(environment: string, username: string) {
    // Get settings from local storage
    let settings = localStorage.getItem(`gemxweb_printer_settings$(environemnt)_$(username)`);

    // Return the settings
    return settings ? JSON.parse(settings) : null;
  }

  fetchAuthSettings(environment: string, username: string) {
      // Set the post data
      let postData = {
        action: 'get_settings',
        environment: environment,
        username: username
      };
      // Send http request
      return this.httpPost(this.settingsUrl, postData)
        .subscribe(responseData => {
          // Convert zeros to blank for identifiers
          responseData.document.computer_id = responseData.document.computer_id ? responseData.document.computer_id : '';
          responseData.document.printer_id = responseData.document.printer_id ? responseData.document.printer_id : '';
          responseData.label.computer_id = responseData.label.computer_id ? responseData.label.computer_id : '';
          responseData.label.printer_id = responseData.label.printer_id ? responseData.label.printer_id : '';
          localStorage.setItem(`gemxweb_printer_settings$(environemnt)_$(username)`, JSON.stringify(responseData));
        });
  }

  saveAuthSettings(environment: string, username: string, settings) {
    // Set the post data
    let postData = {
      action: 'save_settings',
      environment: environment,
      username: username,
      "settings[document][mode]": settings.document.mode,
      "settings[document][computer_id]": settings.document.computer_id,
      "settings[document][printer_id]": settings.document.printer_id,
      "settings[document][is_headless]": settings.document.is_headless,
      "settings[label][mode]": settings.label.mode,
      "settings[label][computer_id]": settings.label.computer_id,
      "settings[label][printer_id]": settings.label.printer_id,
      "settings[label][is_headless]": settings.label.is_headless,
    };
    // Send http request
    return this.httpPost(this.settingsUrl, postData)
      .subscribe(responseData => {
        localStorage.setItem(`gemxweb_printer_settings$(environemnt)_$(username)`, JSON.stringify(settings));
      },
      errorRes => {
        return throwError(errorRes);
      });
  }

  framePrint(url: string) {
    // Fix URL if necessary
    if (url.match(/^http(s)?\:\/\//i) || url.match(/(www)/i)) {
      url = url;
    } else if (url.substring(0, this.env.API_Config.gemxUrl.length) != this.env.API_Config.gemxUrl) {
      url = this.env.API_Config.gemxUrl + url
    }

    // IE users do not get any of the toys
    // Firefox users do not either due to a firefox bug:
    // https://stackoverflow.com/questions/33254679/print-pdf-in-firefox
    if (navigator.appName == 'Microsoft Internet Explorer' ||
        !!(navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)) ||
        (navigator.userAgent.indexOf("MSIE") != -1) ||
        !!(navigator.userAgent.match(/Firefox/) ||
        (navigator.userAgent.indexOf("Firefox") != -1))
    ) {
      this.openWindow(url);

      // Yay! not IE or Firefox
    } else {
      let uid = (String.fromCharCode(30 + Math.floor(Math.random() * 3)) + Date.now()).trim();

      let iframe = document.createElement('iframe');
      iframe.setAttribute("id", "print_frame_" + uid);
      iframe.setAttribute("crossorigin", "anonymous");
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      fetch(url)
        .then(function (response) {
          return response.blob();
        })
        .then(function (myBlob) {
          let objectURL = URL.createObjectURL(myBlob);
          iframe.src = objectURL;
          URL.revokeObjectURL(objectURL);
        })
        .then(function () {
          window.setTimeout(function () {
            iframe.contentWindow.print();
          }, 1000);
        });
    }
  }

  /**
   * This mentod opens new tab
   * @param url url of the  new tab
   * @param target target place to open new tab/window
   * @param options optional parameters
   */
  openWindow(url, target = '_blank') {
    // Fix URL if necessary
    if (url.match(/^http(s)?\:\/\//i) || url.match(/(www)/i)) {
      url = url;
    } else if (url.substring(0, this.env.API_Config.gemxUrl.length) != this.env.API_Config.gemxUrl) {
      url = this.env.API_Config.gemxUrl + url
    }
    window.open(url, target);
  }

  documentPrint(environment: string, username: string, url: string) {
    // Get printer settings
    let printerSettings = this.getSettings(environment, username);
    if (printerSettings && printerSettings.document.mode == 'spherehelper') {
      // Headless Mode
      if(printerSettings.document.is_headless == "1" && printerSettings.document.printer_id) {
        this.httpPost(this.documentPrintUrl, {
          method: "headless_document",
          environment_id: environment,
          printer_id: printerSettings.document.printer_id,
          type: "pdf",
          "prints[0]": url
        }).subscribe();
      // Interface Mode
      } else {
        this.openWindow(this.documentPrintUrl
              +'?method=document'
              +'&environment_id='+environment
              +'&computer_id='+printerSettings.document.computer_id
              +'&printer_id='+printerSettings.document.printer_id
              +'&type=pdf'
              +'&prints[0]='+encodeURI(url)
        );
      }
    } else {
      this.framePrint(url);
    }
  }

  labelPrint(environment: string, username: string, url: string) {
    // If the current site is not running under the gemxweb folder then drop the folder
    // from the sphereprint script url
    if(window.API_Config.gemxUrl) {
      url = window.API_Config.gemxUrl + url;
    } else {
      url = url.replace(/\/gemxweb/i, '');
    }
    
    // if (! window.location.href.match(/\/gemxweb/i)) {
    //   url = url.replace(/\/gemxweb/i, '');
    // }

    // Get printer settings
    let printerSettings = this.getSettings(environment, username);
    if (printerSettings && printerSettings.label.mode == 'spherehelper') {
      // Headless Mode
      if(printerSettings.label.is_headless == "1" && printerSettings.label.printer_id) {
        this.httpPost(url, {
          printnode: 1,
          method: "headless_label",
          environment_id: environment,
          printer_id: printerSettings.label.printer_id,
          type: "zpl"
        }).subscribe();
      // Interface Mode
      } else {
        this.openWindow(url
          +'&printnode=1'
          +'&method=label'
          +'&environment_id='+environment
          +'&computer_id='+printerSettings.document.computer_id
          +'&printer_id='+printerSettings.document.printer_id
          +'&type=zpl'
        );
      }
    } else {
      if (url.match(/printnode/i)) {
        this.openWindow(url);
      } else {
        this.framePrint(url);
      }
    }
  }
}
