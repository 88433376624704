
import { CdkColumnDef } from '@angular/cdk/table';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, UtilityService } from '@app/core';

@Component({
  selector: 'v1-msgBox',
  templateUrl: './msg-box.component.html',
  styleUrls: ['./msg-box.component.scss']
})
export class MSGBOXComponent implements OnInit, OnDestroy {

  selectedClientName;
  _controls = {
    _title: '',
    body: '',
    buttons: []
  }
  ESCkeySubscription;
  constructor(private _utilityService: UtilityService,
    private auth: AuthenticationService,
    public dialogRef: MatDialogRef<MSGBOXComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ESCkeySubscription = this._utilityService.onEscapeKeyPress.subscribe(x => {
      console.log("ESC Key Presed from Order Entry");
      if (data.isCustomConfirmationBox) {
        this.onbtnClick('close', { isCustomAction: true });
      } else {
        this.onbtnClick('0');
      }
    });
    if (data) {
      this._controls = data
      if (this.auth.QFSelected && this.auth.QFSelected.length > 0) {
        if (!this.auth.quickFunctionDialougeId) {
          this.auth.quickFunctionDialougeId = this.dialogRef.id;
        }
      }
    }
  }


  ngOnInit(): void {
    this.selectedClientName = this.auth.clientNameFromMenu;
  }


  onbtnClick(cmd, bt?) {
    if (bt?.isSessionExpired) {
      if (bt.lowercaseText == 'ok') {
        window.location.replace('/login');
      } else {
        window.location.reload();
      }
    } else {
      if (bt?.isCustomAction) {
        this.dialogRef.close(bt.lowercaseText);
      } else {
        this.auth.gemxPost({
          gconn: this.auth.gConn,
          gcmd: '[MBOX]' + cmd
        }).subscribe(x => {
          if (this.auth.QFSelected) {
            let url = this._utilityService._router.url.split("/");
            if (this.auth.quickFunctionDialougeId && this.auth.quickFunctionDialougeId == this.dialogRef.id && url[1] == 'QF') {
              this.auth.quickFunctionDialougeId = undefined;
              this.auth.QFSelected = undefined;
            }
          }
          this.dialogRef.close(x);
        });
      }
    }

  }
  ngOnDestroy() {
    this.ESCkeySubscription.unsubscribe();
  }
}

