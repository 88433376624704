import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charReplace'
})
export class CharReplacePipe implements PipeTransform {

  transform(value: string, matchChar: string , replaceChar?: string): string {
    if (value) {
      matchChar = matchChar? matchChar : '-';
      replaceChar = replaceChar? replaceChar : ' ';
      return value.replace(matchChar, replaceChar);
    } else {
      return value;
    }
  }

}
