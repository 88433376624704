import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  text: string;
  controlId: string;

  @Input() set displayText (value) {
    this.text = value;
  }

  @Input('id') set id(value) {
    this.controlId = value;
  }

  showContent: boolean = false;

  // @HostListener('window:click', ['$event']) onClick (event) {
  //   if (this.showContent)
  //     this.showContent = false;
  // }

  constructor() {
    if (!this.controlId) {
      this.controlId = '_' + (Math.random() * 6 + 2).toString(36).substr(2, 9);
    }
  }

  ngOnInit(): void {
  }

}
