import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class V1ModuleWiseResponseService {
  private formStartingState;
  private numberOfStateChangedFromStart=0;
  public FinalRequestAction:'MsgBoxConfirmation'|'ActionFromInputSubmission';
  private requestActionStack=[];
  public isCurrentResponseIsMenu:boolean=false;
  private formLastState;
  constructor() { }

  stateChanged(state,currentRequestAct,isMenuWorkArea:boolean){
    if(!this.formStartingState){
      this.formStartingState=state;
      this.requestActionStack.unshift(currentRequestAct)
    }else{
      this.formLastState=state;
      if(this.requestActionStack.length==1){
        this.requestActionStack.unshift(currentRequestAct);
      }else if(this.requestActionStack.length==2){
        this.requestActionStack.pop();
        this.requestActionStack.unshift(currentRequestAct);
      }
    }
  }
  get isCurrentStateCommingFromProcessFinalState(){
    let result=false;
    if(this.isCurrentResponseIsMenu){
      result=true;
    }else{
      if(this.formStartingState===this.formLastState){
        if(this.requestActionStack.length==2){
          if(this.requestActionStack[1]==this.FinalRequestAction && this.numberOfStateChangedFromStart>0){
            result=true;
          }
        }
     }else{
       if(this.requestActionStack.length==2){
         this.numberOfStateChangedFromStart=this.numberOfStateChangedFromStart+1;
       }
     }
    }
    return result;
  }
}
