import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { GridRowService } from '../services/grid-row.service';

@Directive({
  selector: '[grid-row]'
})
export class GridRowDirective implements OnInit {
  isRowSelected=false;
  @Input('rowData') rowData;
  @Input('isSingleSelectionEnabled') isSSEnabled:boolean=false;
  constructor(private _el: ElementRef,private renderer:Renderer2,private _rowService:GridRowService) {
    this._rowService.rowSelected.subscribe(x=>{
      if(this._el.nativeElement.id!=x){
        this.ResetSelection();
        //console.log("Grid Row directive Reset event Fired:",x,this._el);
      }else{
        if(this.isRowSelected==true){
          this.ResetSelection();
          this._rowService.selectedRowContext.emit(undefined);
        }else{
          this.SetSelection();
          this._rowService.selectedRowContext.emit(this.rowData);
          //console.log("Grid Row directive Selection Set event Fired:",x);
        }

      }
    });
   }
  ngOnInit(): void {
    //console.log("Grid Row directive event Fired:",this._el);
  }
   ResetSelection(){
     //this.renderer.removeClass(this._el.nativeElement,'selected');
     this.isRowSelected=false;
   }
   SetSelection(){
    // this.renderer.addClass(this._el.nativeElement,'selected');
     this.isRowSelected=true;
   }
  // @HostListener('click', ['$event, $event.target'])
  // onMouseClick(event, targetElement) {
  //   console.log("Grid Row directive  clickevent Fired:",event,targetElement);

  //   this._rowService.rowSelected.emit(this._el.nativeElement.id);
  // }
}
