import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UtilityService } from '@app/core';
import { PopupService } from '../popup/popup.service';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

  options: any[] = [];
  // searchValue = new FormControl('');
  searchValue: string = '';
  showOption: boolean = false;
  filteredOptions: any[];
  template: TemplateRef<any>;

  @Input('dataSet') set setDataSet(data: any[]) {
    this.options = data;
  }
  @Input('label') label: string;
  @Input('id') controlId: string;
  @Input('placeholder') placeholder: string = 'Search';
  @Input('showLookUp') isLookup: boolean = true;

  @Input('lookTemplate') set setTemplate(template: TemplateRef<any>) {
    this.template = template;
  }
  @Input('lookupTitle') title: string;
  popupConfig:{width,height};
  @Input('displayMember') displayKey: string = 'name';
  @Input('valueMember') valueKey: string = 'id';

  @Output('selected') selectedOption: EventEmitter<any> = new EventEmitter<any>();
  @Output('lookupAccess') lookupAccess: EventEmitter<any> = new EventEmitter<any>();
  @Output('Clicked') inputClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _popupService: PopupService, private _utilityService: UtilityService) {
  }

  ngOnInit() {
    // If control ID is not given than generating dynamic ID at runtime
    if (!this.controlId) {
      this.controlId = (String.fromCharCode(30 + Math.floor(Math.random() * 3)) + Date.now()).trim();
    }
    // this.filteredOptions = this._utilityService.deepCopy(this.options);
    this.copyOptions();

  }

  // Nested searcing function
  private _filter(value: string): any[] {
     const filterValue = value.toLowerCase();
    return this.options.filter(option => option[this.displayKey].toLowerCase().includes(filterValue));
  }

  // Search function to search the options
  Search() {
    if (this.searchValue.trim() && this.searchValue.trim().length > 0) {
      this.showOption = true;
      this.filteredOptions = this._utilityService.deepCopy(this._filter(this.searchValue));
    } else {
      this.copyOptions();
    }
  }

  // When option is selected function emits the option
  optionSelect(option: any) {
    if (option) {
      this.searchValue = option.name;
    }
    this.selectedOption.emit(option);
  }
  onEnterClick(){
    this.inputClicked.emit(this.searchValue);
    if(this.searchValue=='.'){
      this.popupConfig={width:500,height:230};
    }else{
      this.popupConfig=undefined;
    }
    this.openLookUp();
  }
  focusOut() {
    setTimeout(() => {
      this.showOption = false;
    }, 150);
  }

  // Emit the lookup object when the lookup is opened
  openLookUp() {
    if (this.template) {
      const _dialogRef = this._popupService.OpenCustomTemplate(this.title, this.template, undefined, this.popupConfig?.width?this.popupConfig.width:undefined, this.popupConfig?.height?this.popupConfig.height:'90vh');
      this.lookupAccess.emit({dialoge:_dialogRef,value:this.searchValue});
    }
  }

  // Create copy of the Options array
  copyOptions() {
    this.filteredOptions = this._utilityService.deepCopy(this.options);
  }

}
