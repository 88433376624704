<div class="history-timeline-container" [ngClass]="{'historyOpen':_opened,'historyClose':!_opened}">

  <div class="timeline-header">
    <span class="title">History</span>
    <span class="close-icon" (click)="close()">
      <img src="assets/icon/close.svg">
    </span>
  </div>

  <div class="timeline-area">

    <div class="timeline">
      <div class="content-container right">
        <div class="content">
          <label class="font-weight-normal  mb-0">Today</label>
        </div>
      </div>
      <div class="content-container right">
        <div class="content">
          <label class="detail mb-0">01:00 PM - Putaway [Receive # <span class="wms-text-primary">288980</span>]</label>
          <br>
          <label class="font-weight-normal wms-text-dark-grey mb-0">Mathew Fox</label>
        </div>
      </div>
      <div class="content-container right">
        <div class="content">
          <label class="detail mb-0">12:30 PM - Receive [Receive # <span class="wms-text-primary">288980</span>]</label>
          <br>
          <label class="font-weight-normal wms-text-dark-grey mb-0">Mathew Fox</label>
        </div>
      </div>
      <div class="content-container right mb-0">
        <div class="content">
          <label class="detail mb-0">11:30 AM - Unload [Receive # <span class="wms-text-primary">288980</span>]</label>
          <br>
          <label class="font-weight-normal wms-text-dark-grey mb-0">Jhon Doe</label>
        </div>
      </div>
      <div class="content-container right">
        <div class="content">
          <label class="detail mb-0">10:00 AM - Edited</label>
          <br>
          <label class="font-weight-normal wms-text-dark-grey mb-0">Eric Clapton</label>
        </div>
      </div>
      <!-- <div class="content-container right mb-0">
        <div class="content">
          <label class="detail">01:00 PM - Putaway [Receive # <span class="wms-text-primary">288980</span>]</label>
          <br>
          <label class="font-weight-normal wms-text-dark-grey mb-0">Mathew Fox</label>
        </div>
      </div> -->
    </div>

  </div>


</div>
