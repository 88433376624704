<div class="container" style="height: calc(100%);">
    <div class="general-popup-header">
        <div class="row">
            <div class="col-lg-11 col-md-11 col-sm-10 col-10">
                <h2 class="">{{title}}</h2>
            </div>
            <!-- <div class="col-lg-1 col-md-1 col-sm-1 col-1 text-right">
                <img src="/assets/icon/close.svg" class="Cpointer closeIcon" mat-dialog-close>
            </div> -->
        </div>
    </div>
    <div class="general-popup-body">
        <v3-grid [MasterColumns]="columnsToDisplay" [GridData]="items" [hasMoreHadder]="false" (RowClicked)="RowClicked($event)">
        </v3-grid>
    </div>
</div>
