import { Component, OnInit } from '@angular/core';

declare global {
  interface Window { API_Config: any; }
}

window.API_Config = window.API_Config || {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    //this.LoadMouseFlowPack();
  }
  title = 'Wms-V3';
  LoadMouseFlowPack() {
    // Initialize the JIRA widget
    let jhdScript = document.createElement('script');
    jhdScript.type = 'text/javascript';
    jhdScript.src = '//cdn.mouseflow.com/projects/ac0210c5-edc5-457a-8e4f-91262aad3f68.js';
    jhdScript.defer = true;
    jhdScript.async = true;
    //  jhdScript.onload = function() {
    //    window.document.addEventListener('DOMContentLoaded', (event) => {
    //      var iframe:any = window.document.getElementById('jsd-widget');

    //      console.log('Mouse Flow Integration Done');
    //    });
    //    var DOMContentLoaded_event = document.createEvent('Event');
    //      DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
    //      window.document.dispatchEvent(DOMContentLoaded_event);
    //  }
    //Hide For demo purpose,use slashes
    //document.getElementsByTagName('head')[0].appendChild(jhdScript);
    document.getElementsByTagName('head')[0].appendChild(jhdScript);
  }
}
