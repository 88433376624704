import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { elementAt } from 'rxjs/operators';

@Directive({
  selector: '[appCollapsible]'
})
export class CollapsibleDirective implements OnInit {

  // collapsePanelId: string = '';

  @HostListener('click', ['$event']) collapseClick(event: any) {
    // console.log('collapse: ', this.el);
    this.getCollapsePanel();
  }

  constructor(private el: ElementRef, private _renderer: Renderer2) {
  }

  ngOnInit() {
  }

  // Function  to check the collapsible panel ID and ADD and REMOVE the show class of bootstrap from the ID Element
  getCollapsePanel() {
    if (this.el) {
      if (this.el.nativeElement.attributes) {
        const dataTarget = this.el.nativeElement.attributes['data-target'];
        if (dataTarget && dataTarget.nodeValue) {
          const collapsePanelId = dataTarget.nodeValue;
          const element = document.getElementById(collapsePanelId);
          if (element) {
            const isShowClass = element.classList.contains('show');
            if (isShowClass) {
              element.classList.remove('show');
            } else {
              element.classList.add('show');
            }
          }
        }
      }
    }
  }



}
