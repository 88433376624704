import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { V1PrinterService } from '@app/core/services/v1-printer.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-printer-settigns',
  templateUrl: './printer-settings.html',
  styleUrls: ['./printer-settings.scss']
})

export class PrinterSettingsComponent implements OnInit, OnDestroy {
  private username: string;
  private environment: string;
  spherehelperAccountLoaded = false;
  spherehelperAccount;
  spherehelperDevicesLoading = false;
  spherehelperDevices = {
    computers: [],
    printers: []
  };
  spherehelperComputerReference = [];
  settings = {
    document: {
      mode: "normal",
      computer_id: "",
      printer_id: "",
      is_headless: "0"
    },
    label: {
      mode: "normal",
      computer_id: "",
      printer_id: "",
      is_headless: "0"
    }
  };
  printerSettingsForm: FormGroup;
  private psfVCSub: Subscription;
  @ViewChild('shUsername') shUsername;
  @ViewChild('shPassword') shPassword;

  constructor(
    private authenticationService: AuthenticationService,
    private v1PrinterService: V1PrinterService,
    private dialogRef: MatDialogRef<PrinterSettingsComponent>
  ) {
  }

  ngOnInit() {
    this.username = this.authenticationService.currentUserValue.masterUser;
    this.environment = this.authenticationService.currentUserValue.designation;
    this.v1PrinterService.getSpherehelperAccount(this.environment).subscribe(responseData => {
      this.spherehelperAccount = responseData;
      this.spherehelperAccountLoaded = true;
    });
    this.settings = this.v1PrinterService.getSettings(this.environment, this.username);
    this.refreshSpherehelperDevices();
    this.printerSettingsForm = new FormGroup({
      'documentMode': new FormControl(this.settings.document.mode),
      'documentComputerId': new FormControl(this.settings.document.computer_id),
      'documentPrinterId': new FormControl(this.settings.document.printer_id),
      'documentIsHeadless': new FormControl(this.settings.document.is_headless.toString()),
      'labelMode': new FormControl(this.settings.label.mode),
      'labelComputerId': new FormControl(this.settings.label.computer_id),
      'labelPrinterId': new FormControl(this.settings.label.printer_id),
      'labelIsHeadless': new FormControl(this.settings.label.is_headless.toString())
    });
    this.psfVCSub = this.printerSettingsForm.valueChanges.subscribe(values => {
      this.settings.document.mode = values.documentMode;
      this.settings.document.computer_id = values.documentComputerId;
      this.settings.document.printer_id = values.documentPrinterId;
      this.settings.document.is_headless = values.documentIsHeadless;
      this.settings.label.mode = values.labelMode;
      this.settings.label.computer_id = values.labelComputerId;
      this.settings.label.printer_id = values.labelPrinterId;
      this.settings.label.is_headless = values.labelIsHeadless;
    });
  }

  ngOnDestroy(): void {
    // Save settings when dialog is closed
    this.v1PrinterService.saveAuthSettings(this.environment, this.username, this.settings);

    // Unsubscribes
    this.psfVCSub.unsubscribe();
  }

  createSpherehelperAccount() {
    this.spherehelperAccountLoaded = false;
    this.v1PrinterService.createSpherehelperAccount(this.environment).subscribe(responseData => {
      if (responseData.success) {
        this.v1PrinterService.getSpherehelperAccount(this.environment).subscribe(responseData => {
          this.spherehelperAccount = responseData;
          this.spherehelperAccountLoaded = true;
        });
      }
    });
  }

  refreshSpherehelperDevices(sync: boolean = false) {
    this.spherehelperDevicesLoading = true;
    this.v1PrinterService.getSpherehelperDevices(this.environment, sync).subscribe(responseData => {
      this.spherehelperDevices = responseData;
      this.spherehelperComputerReference = [];
      for (let computer of this.spherehelperDevices.computers) {
        this.spherehelperComputerReference[computer.id] = computer.reference_id;
      }
      this.spherehelperDevicesLoading = false;
    });
  }

  showDocumentPrinter(printer) {
    return (printer.type == "document" &&
             (! this.settings.document.computer_id ||
               this.settings.document.computer_id == this.spherehelperComputerReference[printer.spherehelper_computer_id])
           );
  }

  showLabelPrinter(printer) {
    return (printer.type == "label" &&
             (! this.settings.label.computer_id ||
               this.settings.label.computer_id == this.spherehelperComputerReference[printer.spherehelper_computer_id])
           );
  }

  copyToClipboard(input) {
    navigator.clipboard.writeText(input.value);
    input.select();
    input.focus();
  }

  downloadSpherehelper() {
    let platform = navigator.platform.toUpperCase();
    if (platform.indexOf("MAC") >= 0) {
      window.open(this.spherehelperAccount.download_urls.mac, "_download");
    } else if (platform.indexOf("WIN") >= 0) {
      window.open(this.spherehelperAccount.download_urls.windows, "_download");
    } else {
      alert("SphereHelper is only available for Windows or OSX operating systems");
    }
  }
}
