import { Pipe, PipeTransform } from '@angular/core';
// import { isNullOrUndefined } from 'util';

@Pipe({
    name: 'ellipses'
})
export class EllipsesPipe implements PipeTransform {
    transform(value: string, limit: number = 20, completeWords: boolean = false, ellipsis: string = '...') {
        if (!value) {
            return null;
        }
        if (completeWords) {
            limit = value.substr(0, limit).lastIndexOf(' ');
        }
        if (value.length <= limit) {
            ellipsis = '';
        }
        return `${value.substr(0, limit)}${ellipsis}`;
    }
}
