import { NgZone, Renderer2, Directive, Input, ElementRef, AfterContentInit, AfterViewInit, Attribute, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[focusDirective]'
})
export class FocusDirective implements AfterViewInit {
    @Input() cssSelector: string
  _focus=false;
  _cType;
  @Output('onTabPress') onTabPress=new EventEmitter<any>();
  @Output('mouseClickFocus') mouseClickFocus=new EventEmitter<any>();
  @Input('focus') set focus(value){
    this._focus=value;
    if(value==true){
      if(this._cType=='autoComplete'){
        console.log("Autocomplete Focus",this.el);
        this.el.nativeElement.children[0].children[0].children[0].children[0].focus();
      }else{
      this.el.nativeElement.focus();
      }
    }
  }
    constructor(
      @Attribute('cType') cType,
        //private ngZone: NgZone,
        private el: ElementRef,
        private renderer: Renderer2
    ) {
      this._cType=cType;
     }

    ngOnInit() {

    }
    ngAfterViewInit() {
      if(this._focus){
        setTimeout(() =>
        {
          if(this._cType=='autoComplete'){
            //console.log("Autocomplete Focus",this.el);
            this.el.nativeElement.children[0].children[0].children[0].children[0].focus();
            this.el.nativeElement.children[0].children[0].children[0].children[0].select();
          }else{
          this.el.nativeElement.focus();
          this.el.nativeElement.select();
          }
        },0
        );
      }
    }
    @HostListener('keydown', ['$event']) onKeydown($event){

         let code = ($event.keyCode ? $event.keyCode : $event.which);
        if (code == 9) {
          console.log('I was tabbed!', code);
          this.onTabPress.emit($event.target.value);
          $event.preventDefault();
        }
    }
    @HostListener('click', ['$event']) onClick($event){
      console.log("Input Clicked",$event);
      if(!this._focus){
        if($event.target.selectionEnd==$event.target.selectionStart){
          console.log("Clicked only new input focused");
          this.mouseClickFocus.emit();
        }
      }
     }

}
