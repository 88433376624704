<div class="wms-select-search-container">
    <div [ngClass]="{ 'brd-b-r-0 brd-b-l-0 border-left-primary border-top-primary border-right-primary' :  showList }" class="search-area" >
         <input
            type="text"
            class="wms-search-field"
            [id]="controlId"
            [placeholder]="placeholder"
            [(ngModel)]="txtSearch"
            (input)="searchValue($event.target.value)"
            (focus)="showList= true;"
            (focusout)="focusOut()">

         <span class="arrow-icon" (click)="searchFocus()">
             <img src="./assets/icon/arrow-down.svg">
         </span>
    </div>

     <ng-container *ngIf="showList">
         <ul class="item-list" [ngClass]="{'border-left-primary border-right-primary border-bottom-primary ' : showList}">
             <li *ngFor="let op of copyOptions" class="item" (click)="optionSelected(op)">{{ op?.name }}</li>
         </ul>
     </ng-container>
 </div>
