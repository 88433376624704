import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNumber'
})
export class RemoveNumberPipe implements PipeTransform {

  transform(value: string,removeNumber:boolean=true): string {
    if(value.includes('<') && value.includes('>')){
      value= value.replace(/</g,"&lt;").replace(/>/g,"&gt;");
    }
    if(removeNumber){
      let startIndexofclosing=value.indexOf(')');
      let startIndexofopening=value.indexOf('(');
      //console.log("Index o and c:",startIndexofopening,startIndexofclosing);
      if(startIndexofopening!==-1 && startIndexofopening<startIndexofclosing){
        return  value;
      }else{
        //console.log("VAlue after sub",value.substring(startIndexofclosing));
        let num:any=value.substr(0,startIndexofclosing);
        if(isNaN(num)){
          return value
        }else{
          return value.substring(startIndexofclosing+1).trim();
        }
      }
    }else{
      return  value;
    }

  }

}
