import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { SideMenuBarComponent } from "./side-menu-bar.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    declarations:[
        SideMenuBarComponent
    ],
    imports:[
        CommonModule,
        BrowserModule,
        RouterModule,
        SharedModule
    ],
    exports:[ SideMenuBarComponent ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class SideMenuBarModule {

}
