<form [formGroup]="printerSettingsForm">
  <button mat-icon-button class="icon-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <h2 mat-dialog-title class="title">Printer Settings</h2>
  <mat-dialog-content>
    <div class="general-popup-body body-area with-footer only-grid flex-box-center _body" style="height: calc(100% - 110px);overflow-y: auto;">
      <div *ngIf="spherehelperAccountLoaded">
        <div *ngIf="!spherehelperAccount?.id">
          <button class="wms-btn wms-btn-primary" (click)="createSpherehelperAccount()">Create SphereHelper Account</button>
        </div>

        <div *ngIf="spherehelperAccount?.id">
          <div class="row">
            <fieldset class="col-12">
              <legend>Document Printing</legend>
              <div class="form-group row">
                <label class="col-3 col-form-label">Print Mode:</label>
                <div class="col-8">
                  <mat-radio-group formControlName="documentMode">
                    <mat-radio-button value="normal">Normal</mat-radio-button>
                    <mat-radio-button value="spherehelper">SphereHelper</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div *ngIf="settings.document.mode == 'spherehelper'">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="documentComputerId">Computer:</label>
                  <div class="col-8">
                    <ng-select
                      formControlName="documentComputerId"
                      [clearable]="false"
                      [searchable]="false">
                      <ng-option value="">All computers</ng-option>
                      <ng-option *ngFor="let computer of spherehelperDevices.computers" [value]="computer.reference_id">{{computer.fullName}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-1">
                    <button mat-icon-button class="icon-button" (click)="refreshSpherehelperDevices(true)">
                      <mat-icon [ngClass]="{'spin': spherehelperDevicesLoading}">refresh</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="documentPrinterId">Printer:</label>
                  <div class="col-8">
                    <ng-select
                      formControlName="documentPrinterId"
                      [clearable]="false"
                      [searchable]="false">
                      <ng-option value="">Select a printer</ng-option>
                      <ng-container *ngFor="let printer of spherehelperDevices.printers">
                        <ng-option *ngIf="showDocumentPrinter(printer)" [value]="printer.reference_id">{{printer.name}}</ng-option>
                      </ng-container>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3 col-form-label">Headless Printing:</label>
                  <div class="col-8">
                    <mat-radio-group formControlName="documentIsHeadless">
                      <mat-radio-button value="0">Disabled</mat-radio-button>
                      <mat-radio-button value="1">Enabled</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend>Label Printing</legend>
              <div class="form-group row">
                <label class="col-3 col-form-label">Print Mode:</label>
                <div class="col-8">
                  <mat-radio-group formControlName="labelMode">
                    <mat-radio-button value="normal">Normal</mat-radio-button>
                    <mat-radio-button value="spherehelper">SphereHelper</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div *ngIf="settings.label.mode == 'spherehelper'">
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="labelComputerId">Computer:</label>
                  <div class="col-8">
                    <ng-select
                      formControlName="labelComputerId"
                      [clearable]="false"
                      [searchable]="false">
                      <ng-option value="">All computers</ng-option>
                      <ng-option *ngFor="let computer of spherehelperDevices.computers" [value]="computer.reference_id">{{computer.fullName}}</ng-option>
                    </ng-select>
                  </div>
                  <div class="col-1">
                    <button mat-icon-button class="icon-button" (click)="refreshSpherehelperDevices(true)">
                      <mat-icon [ngClass]="{'spin': spherehelperDevicesLoading}">refresh</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3 col-form-label" for="labelPrinterId">Printer:</label>
                  <div class="col-8">
                    <ng-select
                      formControlName="labelPrinterId"
                      [clearable]="false"
                      [searchable]="false">
                      <ng-option value="">Select a printer</ng-option>
                      <ng-container *ngFor="let printer of spherehelperDevices.printers">
                        <ng-option *ngIf="showLabelPrinter(printer)" [value]="printer.reference_id">{{printer.name}}</ng-option>
                      </ng-container>
                    </ng-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-3 col-form-label">Headless Printing:</label>
                  <div class="col-8">
                    <mat-radio-group formControlName="labelIsHeadless">
                      <mat-radio-button value="0">Disabled</mat-radio-button>
                      <mat-radio-button value="1">Enabled</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend>SphereHelper</legend>
              <div class="form-group row">
                <label class="col-3 col-form-label">Username:</label>
                <div class="col-8">
                  <input #shUsername type="text" class="form-control" value="{{ spherehelperAccount.email }}" readonly>
                </div>
                <div class="col-1">
                  <button class="icon-button" mat-icon-button aria-hidden="false" aria-label="Copy" (click)="copyToClipboard(shUsername)">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3 col-form-label">Password:</label>
                <div class="col-8">
                  <input #shPassword type="text" class="form-control" value="{{ spherehelperAccount.passwd }}" readonly>
                </div>
                <div class="col-1">
                  <button class="icon-button" mat-icon-button aria-hidden="false" aria-label="Copy" (click)="copyToClipboard(shPassword)">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-3 col-form-label">Application:</label>
                <div class="col-8">
                  <button type="button" style="width: auto;" class="btn wms-btn wms-btn-primary" (click)="downloadSpherehelper()">Download SphereHelper {{ spherehelperAccount.spherehelper_plus ? "+" : "" }}</button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</form>
