<div class="popup-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-11 col-md-11 col-sm-10 col-10">
                <h4 class="" style="font-size: 20px;">Import</h4>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 col-1 text-right">
                <img src="./assets/icon/close.svg" class="Cpointer closeIcon" mat-dialog-close>
            </div>
        </div>
    </div>
</div>
<div class="popup-body">
    <div class="container-fluid " style="background-color: #cccccc;">
        <div class="row d-flex justify-content-start">
            <div class="col-lg-12 " style="height: 214px;">
                <div class="justify-content-center">
                    <div>
                        <img style="
                        display: block;
                        margin:auto; width: 76px; height: 76px; margin-top: 14px;
                    " src="assets/icon/upload.svg">

                    </div> <span></span>
                    <div class="row mt-4 justify-content-center" style="font-size: 18px;">
                        Choose the file to Import
                    </div>
                    <div class="row mt-4 mb-2 justify-content-center" href="url">



                        <input type="file" class="mr-2" (change)="selectFile($event)" name="uploadtemplate" id="uniqueid" accept=".csv" />


                        <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
                              Upload
                            </button>



                    </div>
                    <div>

                    </div>
                </div>

            </div>

        </div>
    </div>



    <div class="export-popup-footer">
        <div class="container-fluid">
            <div class="row d-flex justify-content-end">
                <div class="col-6">
                    <button class="wms-btn wms-btn-light float-right" mat-dialog-close>Close</button>
                </div>
            </div>
        </div>
    </div>
</div>