<div class="main-section">
    <div class="header-section">
        <app-header [(menutoggle)]="menuToggle" [Clinets]="authService.clinets"
            (clientSelectionchanged)="clinetChanged($event)"></app-header>
    </div>

    <!-- Web Menu Start -->
    <ng-container *ngIf="!isMobileView">

        <div class="body-section">
            <div class="content-body">
                <ng-container
                    *ngIf="showMenuButton && (authService.currentUserValue.designation != 'redaspen-s' && authService.currentUserValue.designation != 'redaspen')">

                    <div class="wms-panel-buttons">
                        <ng-container
                            *ngIf="v1menuservice.dashboardcmd && v1menuservice.dashboardcmd.length>0 || authService.currentUserValue.designation == 'romeo-d' || authService.currentUserValue.designation == 'romeo-s' || authService.currentUserValue.designation == 'romeo'">
                            <div class="wms-menu-btn" [matTooltip]="'Dashboard'" [matTooltipClass]="'tooltip-pd'"
                                matTooltipPosition="right">
                                <div [class.sideactivebar]="menuSer.isDashboardActive && !isMenuActive"></div>
                                <div [class.active-view]="menuSer.isDashboardActive && !isMenuActive">
                                    <img src="assets/icon/menu/dashboard.svg"
                                        (click)="v1menuservice.menu.showMenusPanel(undefined);dashBoardIconClicked();" />
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngFor="let item of v1menuservice.menu.menus; let i = index">
                            <ng-container *ngIf="!item.isClientSelectionMenu">

                                <div class="wms-menu-btn" [matTooltip]="item?.tooltip" [matTooltipClass]="'tooltip-pd'"
                                    matTooltipPosition="right">
                                    <div [class.sideactivebar]="item.isActiveForChildVisibility"></div>
                                    <div [class.active-view]="item.isActiveForChildVisibility">
                                        <img [src]="item?.iconUrl"
                                            (click)="v1menuservice.menu.showMenusPanel(i); sideBarMenuOptionClick(i);" />
                                    </div>
                                </div>

                            </ng-container>

                        </ng-container>

                        <div class="side-meu-toggle-button fixed-bottom" (click)="sideMenuToggle()" *ngIf="!showPanel">
                            <mat-slide-toggle id="slideToggle-1" [(ngModel)]="slideToggletwo"></mat-slide-toggle>
                        </div>

                    </div>

                </ng-container>

                <div class="wms-panel-menu"
                    *ngIf="showPanel && (authService.currentUserValue.designation != 'redaspen-s' && authService.currentUserValue.designation != 'redaspen')"
                    [class.show-panel]="showPanel" style="overflow-y: auto;">
                    <app-side-menu-bar [panelData]="currentPanelData" (menuSelected)="menuToggle = false;"
                        (subMenuRequested)="subMenuRequested($event)" [subMenus]="subMenus"></app-side-menu-bar>
                </div>

                <div id="panelbody" class="wms-panel-body" [class.w-100]="!showMenuButton"
                    [class.has-panel]="showPanel && currentPanelData.hasPanel">
                    <div class="container-fluid">
                        <router-outlet (activate)="onRouterActivate($event)" (deactivate)="onRouterDeactivate($event)">
                        </router-outlet>
                        <div *ngIf="!showOutlet" class="blank-screen">
                            <app-intermediate-blank-screen></app-intermediate-blank-screen>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>© {{CurrentYear}} ASP Global Services Inc.</div>
                <div>
                    SphereWMS 3.1
                    <span *ngIf="authService.currentUserValue.name.length > 0">|
                        {{authService.currentUserValue.name}}</span>
                </div>
            </div>
        </div>

    </ng-container>
    <!-- Web Menu End -->

    <!-- Mobile Menu Start -->
    <ng-container *ngIf="isMobileView">

        <div class="mbl-sidebar-container" *ngIf="menuToggle">

            <div class="cross-icon-container">
                <img src="./assets/icon/close.svg" class="cross-icon" (click)="menuToggle = false;">
            </div>

            <div class="user-profile-container">
                <div class="profile-picture-container">
                    <img src="./assets/icon/profile_photo.png" class="profile-picture">
                </div>

                <div class="user-detail-container">
                    <label class="user-name">Jhon</label>
                    <label class="user-role">picker</label>
                </div>
            </div>

            <div class="wms-mbl-menu-panel">

                <ng-container *ngFor="let menu of menuButtons; let i = index">
                    <div class="wms-mbl-menu" *ngIf="!menu.isWeb">
                        <mat-expansion-panel [expanded]="activeTabIndex === i && menu.hasPanel"
                            [hideToggle]="!menu.hasPanel" [id]="'expension-panel-'+i">
                            <mat-expansion-panel-header (click)="onMenuBtnClick(menu.name, i)">
                                <mat-panel-title>
                                    <img [src]="menu.iconUrl" class="wms-menu-btn-icon" />
                                    <span class="wms-mbl-menu-btn-title"
                                        [class.text-uppercase]="menu?.isCap">{{menu.name}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <ng-container *ngIf="currentPanelData.hasPanel">
                                <div class="mbl-sub-menu-panel"
                                    [class.show-panel]="showPanel && currentPanelData.hasPanel">
                                    <app-side-menu-bar (menuSelected)="menuToggle = false;"
                                        [panelData]="currentPanelData"></app-side-menu-bar>
                                </div>
                            </ng-container>

                        </mat-expansion-panel>
                    </div>
                </ng-container>

            </div>

        </div>

        <div class="mbl-body-section">
            <div class="wms-mbl-content-body">
                <div id="panelbody" class="wms-panel-body" [class.has-panel]="showPanel && currentPanelData.hasPanel">
                    <router-outlet (activate)="onRouterActivate($event)" (deactivate)="onRouterDeactivate($event)">
                    </router-outlet>
                    <div *ngIf="!showOutlet" class="blank-screen">
                        <app-intermediate-blank-screen></app-intermediate-blank-screen>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>© {{CurrentYear}} ASP Global Services Inc.</div>
                <div>
                    SphereWMS 3.1
                    <span *ngIf="authService.currentUserValue.name.length > 0">|
                        {{authService.currentUserValue.name}}</span>
                </div>
            </div>
        </div>

    </ng-container>
    <!-- Mobile Menu End -->

</div>
<ng-template #MultipleAccounts>
    <div class="main-section" style="text-align: center;align-items: center;height: 100vh;overflow-y: auto;">
        <br>
        <br>
        <h2>Select an Account</h2>
        <br>
        <div *ngFor="let acc of authService.multipleUsers">
            <button class="btn wms-btn wms-btn-primary" (click)="LoginToSpecificUser(acc)">{{acc.title}}</button>
            <br>
        </div>
    </div>
</ng-template>