import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UtilityService } from '@app/core';
import { PopupService } from '../popup/popup.service';

@Component({
  selector: 'lookup-input',
  templateUrl: './lookup-input.component.html',
  styleUrls: ['./lookup-input.component.scss']
})
export class LookUpInput implements OnInit {

  searchValue: string = '';

  @Input('label') label: string;
  @Input('id') controlId: string;
  @Input('placeholder') placeholder: string = 'Search';
  @Input('defaultValue') set defaultValue(value){
    this.searchValue=value;
  }
  @Input('maxLength') maxLength=20;
  @Output('selected') selectedOption: EventEmitter<any> = new EventEmitter<any>();
  @Output('lookupAccess') lookupAccess: EventEmitter<any> = new EventEmitter<any>();
  @Output('onEnterKey') inputClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output('onFocus') onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output('onIconClicked') _onIconClicked=new EventEmitter<any>();
  constructor(private _popupService: PopupService, private _utilityService: UtilityService) {
  }

  ngOnInit() {
    // If control ID is not given than generating dynamic ID at runtime
    if (!this.controlId) {
      this.controlId = (String.fromCharCode(30 + Math.floor(Math.random() * 3)) + Date.now()).trim();
    }
  }

  onEnterClick(value){
    this.inputClicked.emit(value);
  }
  onIconClicked(){
      this._onIconClicked.emit(this.searchValue);
  }
  focus(event){
    this.onFocus.emit(event);
  }
  focusOut(event) {
    setTimeout(() => {
    }, 150);
  }


}
