<ng-container *ngFor="let group of _layoutElements; let isLastGroup=last;">
    <div class="row formGroup">
        <div class="col-lg-10 col-md-12 col-sm-12 col-xs-12" [ngClass]="{'col-lg-12':_fillCompleteArea,'col-lg-10':!_fillCompleteArea}">
            <div class="row">
                <ng-container *ngFor="let groupItem of group">
                    <ng-container *ngFor="let element of groupItem.elements">
                        <ng-container [ngSwitch]="element.elType">
                            <!--When groupElement Type is 'Single' means it has only one item inside input/label-->
                            <ng-container *ngSwitchCase="'Single'">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-sm-12 col-xs-12 unit-single-item" [ngClass]="{'col-lg-6 col-md-6':!_isClickableOptions , 'col-lg-12 col-md-12':_isClickableOptions}">
                                            <ng-container *ngFor="let item of element.el">
                                                <ng-container [ngSwitch]="item.cType">
                                                    <!--When item is Input/LookUp-->
                                                    <ng-container *ngSwitchCase="'input'">
                                                        <!--Input-->
                                                        <input tabindex="-1" *ngIf="!item.isLookUp" type="text" focusDirective [focus]="_readonly?false:item.focus" [readonly]="_readonly" [value]="item.cValue" [attr.id]="groupItem.nRow+''+item.cCol" [attr.maxLength]="item.elLength" (keydown.enter)="_readonly?false:_onEnterPress.emit($event.target.value)"
                                                            (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" />
                                                        <!--LookUp Input-->
                                                        <lookup-input *ngIf="item.isLookUp" cType="autoComplete" focusDirective [focus]="_readonly?false:item.focus" [maxLength]="item.elLength" [defaultValue]="item.cValue" [id]="'auto-complete-with-lookup'+_workAreaId" (onEnterKey)="_readonly?false:_onLookupEnterPress.emit($event)"
                                                            (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" (onIconClicked)="_readonly?false:_onLookupIconClicked.emit($event)">
                                                        </lookup-input>
                                                    </ng-container>
                                                    <!--When item is Label-->
                                                    <ng-container *ngSwitchCase="'label'">
                                                        <label [att.for]="groupItem.nRow+''+item.cCol" *ngIf="item.cType=='label'" [ngClass]="{'clickable':(_isClickableOptions && item.isClickable)}" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions" (click)="(!_readonly && _isClickableOptions)?_onInputfocus.emit(item.elId):false"></label>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!--If groupElement Type is 'LabelsOnly' means it has Labels only inside-->
                            <ng-container *ngSwitchCase="'LabelsOnly'">
                                <div class="col-12">
                                    <ng-container *ngFor="let item of element.el">
                                        <label [att.for]="groupItem.nRow+''+item.cCol" [ngClass]="{'clickable':(_isClickableOptions && item.isClickable)}" [ngStyle]="{'margin-right':item._paddRight,'margin-left':!item._paddLeft?'auto':item._paddLeft}" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions"
                                            (click)="(!_readonly && _isClickableOptions)?_onInputfocus.emit(item.elId):false"></label>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <!--If groupElement Type is 'LabelInputGroup' means it has Labels_input  inside-->
                            <ng-container *ngSwitchCase="'LabelInputGroup'">
                                <div class="col-sm-12 col-xs-12 unit-group" [ngClass]="{'col-lg-6 col-md-6':!_isClickableOptions , 'col-lg-12 col-md-12':_isClickableOptions}">
                                    <ng-container *ngFor="let elemSection of element.el;let elSectionIndex=index;">
                                        <ng-container [ngSwitch]="elSectionIndex">
                                            <ng-container *ngSwitchCase="'0'">
                                                <div class="unit-group-item" [style.min-width.px]="_maxInputLabelWidth+10">
                                                    <ng-container *ngFor="let item of elemSection">
                                                        <ng-container [ngSwitch]="item.cType">
                                                            <!--When item is Input/LookUp-->
                                                            <ng-container *ngSwitchCase="'input'">
                                                                <!--Input-->
                                                                <input tabindex="-1" *ngIf="!item.isLookUp" type="text" focusDirective [focus]="_readonly?false:item.focus" [readonly]="_readonly" [value]="item.cValue" [attr.id]="groupItem.nRow+''+item.cCol" [attr.maxLength]="item.elLength" (keydown.enter)="_readonly?false:_onEnterPress.emit($event.target.value)"
                                                                    (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" />
                                                                <!--LookUp Input-->
                                                                <lookup-input style="width: 100%" *ngIf="item.isLookUp" cType="autoComplete" focusDirective [focus]="_readonly?false:item.focus" [maxLength]="item.elLength" [defaultValue]="item.cValue" [id]="'auto-complete-with-lookup'+_workAreaId" (onEnterKey)="_readonly?false:_onLookupEnterPress.emit($event)"
                                                                    (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" (onIconClicked)="_readonly?false:_onLookupIconClicked.emit($event)">
                                                                </lookup-input>
                                                            </ng-container>
                                                            <!--When item is Label-->
                                                            <ng-container *ngSwitchCase="'label'">
                                                                <label [att.for]="groupItem.nRow+''+item.cCol" [ngClass]="{'clickable':(_isClickableOptions && item.isClickable)}" *ngIf="item.cType=='label'" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions" (click)="(!_readonly && _isClickableOptions)?_onInputfocus.emit(item.elId):false"></label>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'1'">
                                                <div class="unit-group-item unit-group-item-input" [ngClass]="{'unit-group-item-input-postfix':elemSection.length==2 && elemSection[0].cType=='input' && elemSection[1].cType=='label'}">
                                                    <ng-container *ngFor="let item of elemSection;let elItmIndex=index;">
                                                        <ng-container [ngSwitch]="item.cType">
                                                            <!--When item is Input/LookUp-->
                                                            <ng-container *ngSwitchCase="'input'">
                                                                <!--Input-->
                                                                <input tabindex="-1" *ngIf="!item.isLookUp" type="text" focusDirective [focus]="_readonly?false:item.focus" [readonly]="_readonly" [value]="item.cValue" [attr.id]="groupItem.nRow+''+item.cCol" [attr.maxLength]="item.elLength" (keydown.enter)="_readonly?false:_onEnterPress.emit($event.target.value)"
                                                                    (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" />
                                                                <!--LookUp Input-->
                                                                <lookup-input *ngIf="item.isLookUp" cType="autoComplete" focusDirective [focus]="_readonly?false:item.focus" [maxLength]="item.elLength" [defaultValue]="item.cValue" [id]="'auto-complete-with-lookup'+_workAreaId" (onEnterKey)="_readonly?false:_onLookupEnterPress.emit($event)"
                                                                    (onTabPress)="_readonly?false:_onEnterPress.emit($event)" (mouseClickFocus)="!_readonly?_onInputfocus.emit(item.elId):false" (onIconClicked)="_readonly?false:_onLookupIconClicked.emit($event)">
                                                                </lookup-input>
                                                            </ng-container>
                                                            <!--When item is Label-->
                                                            <ng-container *ngSwitchCase="'label'">

                                                                <!-- IF ELSE TEMPLATE FOR SLICING LABEL VALUE DEPENDING ON ITS SIZE -->
                                                                <div *ngIf="(item.cValue | removeNumber:!_isClickableOptions).length>28; else nonSliced">
                                                                    <div class="input-postfix-label" *ngIf="elItmIndex==1" [matTooltip]="item.cValue" [matTooltipClass]="'tooltip-xd'" [matTooltipPosition]="'below'">
                                                                        <label [att.for]="groupItem.nRow+''+item.cCol" [innerHtml]="(item.cValue | slice:0:20)+'..'"></label>
                                                                    </div>
                                                                    <label *ngIf="elItmIndex!==1" [att.for]="groupItem.nRow+''+item.cCol" [ngClass]="{'clickable':(_isClickableOptions && item.isClickable)}" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions" (click)="(!_readonly && _isClickableOptions)?_onInputfocus.emit(item.elId):false"></label>
                                                                </div>
                                                                <ng-template #nonSliced>
                                                                    <div class="input-postfix-label" *ngIf="elItmIndex==1">
                                                                        <label [att.for]="groupItem.nRow+''+item.cCol" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions"></label>
                                                                    </div>
                                                                    <label *ngIf="elItmIndex!==1" [att.for]="groupItem.nRow+''+item.cCol" [ngClass]="{'clickable':(_isClickableOptions && item.isClickable)}" [innerHtml]="item.cValue | removeNumber:!_isClickableOptions" (click)="(!_readonly && _isClickableOptions)?_onInputfocus.emit(item.elId):false"></label>
                                                                </ng-template>

                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <!--Section Break-->
    <hr *ngIf="!isLastGroup">
</ng-container>