import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropDown } from './select-search.model';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit {

  ddlOptions: DropDown[] = [];
  copyOptions: DropDown[] =[];
  showList: boolean = false;
  controlId: string;
  txtSearch: string;
  defaultValue: { id : any, name: any };

  @Input() set options(data: DropDown[]) {
    this.ddlOptions = data;
  }

  @Input() set setValue(value) {
    this.defaultValue = value;
    if (this.defaultValue.name)
      this.txtSearch = this.defaultValue.name;
  }

  @Input('placeholder') placeholder: string  = 'Select';

  @Input() set referenceId(id: string) {
    this.controlId =  id;
  }

  @Output('selected') selectedOption = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.copyOptions = [...this.ddlOptions];
    if (!this.controlId) {
      this.controlId  = String.fromCharCode(10 + Math.floor(Math.random() * 2)) + Date.now();
    }
  }

  searchValue(value: string) {
    // console.log(this.ddlOptions);

    if (value) {
      if (this.ddlOptions) {
        this.copyOptions = this.ddlOptions.filter(row => row.name.toLocaleLowerCase().includes(value));
      } else {
        this.copyOptions = [...this.ddlOptions];
      }
    } else {
      this.copyOptions = [...this.ddlOptions];
    }
    console.log(this.copyOptions);
  }

  optionSelected(option: any) {
    if (option) {
      this.defaultValue = option;
      this.txtSearch = option.name;
    }

    // console.log(option)
    this.selectedOption.emit(option);
  }

  focusOut() {
    setTimeout(() => {
      this.showList = false
    }, 200);
  }

  searchFocus () {
    this.showList = !this.showList;
    if (this.showList)
      document.getElementById(this.controlId).focus();
  }

}
