<div class="wms-btn-dropdown-container" [id]="controlId">
  <div class="wms-bg-primary wms-btn-drop"
    [class.content-showed]="showContent"
    (click)="showContent = !showContent;">
    {{ text }}
    <span class="ddl-icon-area">
      <span></span>
      <img src="./assets/icon/arrow-down-fill-light.svg">
    </span>

  </div>
  <!-- Content Area Start -->
  <div class="wms-btn-dropdown-content" (click)="showContent = false;" *ngIf="showContent">
    <ng-content></ng-content>
  </div>
  <!-- Content Area End -->
</div>
