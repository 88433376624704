//--------------------- Modules
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgxOtpInputModule } from 'ngx-otp-input';

// -------------------- Components
import { LoginComponent  } from "./components/login/login.component";
import { IntermediateBlankScreenComponent } from './../modules/intermediate-blank-screen/intermediate-blank-screen.component';

//--------------------- Services
import { DataSerivce, AuthenticationService, AppRouteService, UtilityService, EnvServiceProvider,V1MenuService,V1PrinterService,V1UtilitiesService,AppInitService } from './index';

// -------------------- Guards
import { AuthGuard } from './guards/auth-guard';
import { AppHeaderModule } from './components/app-header/app-header.module';
import { SideMenuBarModule } from './components/side-menu-bar/side-menu-bar.module'
import { HomeComponent } from './components/home/home.component';
//import { HomeModule } from './components/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '@app/shared/shared.module';
import { ClientSelectionPopupComponent } from './components/client-selection-popup/client-selection-popup.component';
import { ExternalScreenComponent} from './components/external-screen/external-screen';
import { V1LookupModule } from '../modules/lookup/v1-lookup.module';
import { PasswordChangePopupComponent } from './components/external-screen/password-change-popup/password-change-popup.component';
import { TwoFactorAuthComponent } from './components/two-factor-auth/two-factor-auth.component';
import { PrinterSettingsComponent } from './components/printer-settings/printer-settings';

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init();
  }
@NgModule({
    declarations: [
        LoginComponent,
        HomeComponent,
        ClientSelectionPopupComponent,
        ExternalScreenComponent,
        IntermediateBlankScreenComponent,
        PasswordChangePopupComponent,
        TwoFactorAuthComponent,
        PrinterSettingsComponent
        // AppHeaderComponent,
        // SideMenuBarComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AppHeaderModule,
        SideMenuBarModule,
        MatExpansionModule,
        NgSelectModule,
        SharedModule,
        NgxOtpInputModule
    ],
    // exports: [
    //      //AppHeaderComponent
    //     // SideMenuBarComponent
    // ],
    providers: [
        DataSerivce,
        AuthenticationService,
        AuthGuard,
        AppRouteService,
        UtilityService,
        V1MenuService,
        V1PrinterService,
        V1UtilitiesService,
        EnvServiceProvider,
        AppInitService,
        {
        provide: APP_INITIALIZER,
        useFactory: init_app,
        deps: [AppInitService],
        multi: true
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<any> {
        return {
          ngModule: CoreModule
        };
    }
}
