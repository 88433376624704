import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { MFAResponse } from '@app/types/TotopTyps';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {
  @ViewChild('totpElem') totpElem: NgxOtpInputComponent;
  pinOTP: string;
  invalidOTP: boolean = false;

  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
  };

  constructor(
    public dialogRef: MatDialogRef<TwoFactorAuthComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
  ) { }

  qrCode = this.data?.qrCodeUrl || null;

  ngOnInit(): void {
    console.log(this.data);
  }

  closeDialoge() {
    this.dialogRef.close({ closeTotp: true });
  }

  fillOTPValue(code: string) {
    this.pinOTP = code;
    this.authService.validateTOTP({ username: this.data.credentials.username, code })
      .subscribe((res) => {
        this.invalidOTP = false;
        if(res.isValid) {
          this.dialogRef.close({...res, closeTotp: true, ...this.data });
        } else {
          this.invalidOTP = true;
        }
      });
  }
}
