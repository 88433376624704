

export class AppConstants {

  public static APP_ROUTES = {
    dashboard: {
      default: 'dashboard',
      dashboard: 'dashboard',
      Detail:'Red-Details'
    },
    purchase: {
      default: 'purchase/orders',
      orders: 'purchase/orders',
      createOrder: 'purchase/orders/create-order'
    },
    outbound: {
      default: 'outbound/overview',
      overview: 'outbound/overview',
      overviewRule: 'outbound/overview/rule',
      overviewRuleCreateNew: 'outbound/overview/rule/create-new',
      createOrder: 'outbound/overview/create-order',
      editOrder: 'outbound/overview/edit-order/',
      createBatch: 'outbound/overview/create-batch',
      pickByOrder: 'outbound/pick-by-order',
      pickByBatch: 'outbound/pick-by-batch',
      pickByBatchRule: 'outbound/pick-by-batch/rule',
      pickByBatchRuleCreateNew: 'outbound/pick-by-batch/rule/create-new',
      packSmallParcel: 'outbound/pack-small-parcel',
      packFreight: 'outbound/pack-freight',
      packStation: 'outbound/pack-small-parcel/station-pack',
      packAndShipStation: 'outbound/pack-small-parcel/station-pack-and-ship',
      // consolidate: 'outbound/consolidate',
      // addConsolidate: 'outbound/consolidate/add-consolidate',
      shipFreight: 'outbound/ship/freight',
      shipSmallParcel: 'outbound/ship/small-parcel',
      shipStation: 'outbound/ship/small-parcel/station-pack',
      shipPackAndShipStation: 'outbound/ship/small-parcel/station-pack-and-ship',
      orderOverviewDetail: 'outbound/detail/order-overview-detail/',
      orderDetail: 'outbound/detail/order-detail/',

    },
    inbound: {
      default: 'inbound/overview',
      overview: 'inbound/overview',
      createAsn: 'inbound/overview/create-asn',
      asnDetail: 'inbound/overview/asn-detail/',
      createBlindReceipt: 'inbound/overview/create-blind-receipt',
      checkin: 'inbound/checkin',
      checkinNew: 'inbound/checkin/checkin-new',
      unload: 'inbound/unload',
      ansUnload: 'inbound/unload/ans-unload',
      receive: 'inbound/receive',
      receiver: 'inbound/receive/receiver',
      putaway: 'inbound/putaway',
      putawayNew: 'inbound/putaway/putaway-new',
      history: 'inbound/history',
    },
    inventory: {
      default: 'inventory/overview',
      overview: 'inventory/overview',
      stockTransfer: 'inventory/stock-transfer',
      createStockTransfer: 'inventory/stock-transfer/create-new',
      inventoryAdjustment: 'inventory/inventory-adjustment',
      createInventoryAdjustment: 'inventory/inventory-adjustment/create-new',
    },
    accountmanagement: {
      default: 'accountmanagement/companyaccount/companylist',
      subscriptionplan: 'accountmanagement/subscriptionplan',
      integrationsetting: 'accountmanagement/integrationsetting',
      masterdata: 'accountmanagement/masterdata',
      masterdatadetail:'accountmanagement/masterdata/masterdatadetail',
      createaccount:'accountmanagement/companyaccount/createaccount',
      companydetail:'accountmanagement/companyaccount/companyoverview/companydetail',
      companyoverview: 'accountmanagement/companyaccount/companyoverview',
      createSubscriptionPlan:'accountmanagement/subscriptionplan/createsubscriptionplan',
      newclient:'accountmanagement/companyaccount/AMclients/newclient',
      newuser: 'accountmanagement/companyaccount/AMusers/newuser',
      clientoverview: 'accountmanagement/companyaccount/AMclients/clientoverview',
      clientdetails: 'accountmanagement/companyaccount/AMclients/clientoverview/clientdetails',
      Managementclients: 'accountmanagement/companyaccount/AMclients',
      ManagementUsers:'accountmanagement/companyaccount/AMusers',
      ManagementSettings:'accountmanagement/companyaccount/AMsettinngs',
      AcccountManagementWarehouse:'accountmanagement/companyaccount/warehouse',
      AccountManagementAddWarehouse:'accountmanagement/companyaccount/warehouse/addwarehouse',
      addlocation:'accountmanagement/companyaccount/addwarehouse/addlocation',
      addWarehouse:'accountmanagement/companyaccount/companyoverview/addwarehouse',
      locationTypleList:'accountmanagement/companyaccount/addwarehouse/typelist',
      addAreaType:'accountmanagement/companyaccount/addwarehouse/addareatype',
      areaTypelist:'accountmanagement/companyaccount/addwarehouse/areatypelist',
      formatlist: 'accountmanagement/companyaccount/addwarehouse/formatlist',
      locationList:'accountmanagement/companyaccount/addwarehouse/locationlist',
      addFormat:'accountmanagement/companyaccount/addwarehouse/addformat',
      addtype:'accountmanagement/companyaccount/addwarehouse/addtype',

    },
    account: {
      default: 'account/warehouse',
      addWarehouse:'account/warehouse/addwarehouse',
      locationList:'account/warehouse/locationlist',
      addlocation:'account/warehouse/addlocation',
      addFormat:'account/warehouse/addformat',
      formatlist:'account/warehouse/formatlist',
      addLocationType:'account/warehouse/addtype',
      locationTypleList:'account/warehouse/typelist',
      addAreaType:'account/warehouse/addareatype',
      areaTypelist:'account/warehouse/areatypelist',
      clients: 'account/clients/list',
      clientoverview: 'account/clients/overview',
      clientdetails: 'account/clients/overview/clientdetails',
      clientSettings: 'account/clients/settings',
      newclient:'account/clients/newclient',
      suppliers: 'account/clients/suppliers',
      newsupplier: 'account/clients/suppliers/newsupplier',
      carrier: 'account/clients/carrier',
      newcarrier: 'account/clients/carrier/newcarrier',
      customers:'account/clients/customers',
      newcustomer:'account/clients/customers/newcustomer',
      itemmaster:'account/clients/itemmaster',
      newitem:'account/clients/itemmaster/newitem',
      users:'account/users',
      newusers:'account/users/newuser',
      roles:'account/roles',
      roleslist: 'account/roles/newrole',
      seetings:'account/settings',
      shipmethod: 'account/shipmethod'
    },
    uikit: {
      default: 'ui-kit'
    },
    login: {
      default: 'login'
    },
    home: {
      default: 'home'
    },
    responsiveHome: {
      default: 'home-responsive',
      orderPicker: 'order-picker'
    },
    // -------- Mobile Menus
    pick: {
      default: 'mobile/outbound/scan-order',
      scanOrder: 'mobile/outbound/scan-order',
      scanBatch: 'mobile/outbound/scan-batch',
    },
    asn : {
      default: 'mobile/inbound/unload',
      unload: 'mobile/inbound/unload',
      receive: 'mobile/inbound/receive',
      putaway: 'mobile/inbound/putaway',
    }

  };
  public static APP_MENU_BUTTONS = [
    {
      name: 'dashboard',
      iconUrl: 'assets/icon/menu/dashboard.svg',
      tooltip: 'dashboard',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    {
      name: 'purchase',
      iconUrl: 'assets/icon/menu/purchase.svg',
      tooltip: 'purchase',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    {
      name: 'inbound',
      iconUrl: 'assets/icon/menu/Inbound.svg',
      tooltip: 'inbound',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'outbound',
      iconUrl: 'assets/icon/menu/Outbound.svg',
      tooltip: 'outbound',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'inventory',
      iconUrl: 'assets/icon/menu/Inventory.svg',
      tooltip: 'inventory',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'accountmanagement',
      iconUrl: 'assets/icon/menu/work.svg',
      tooltip: 'Account Management',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'account',
      iconUrl: 'assets/icon/menu/work.svg',
      tooltip: 'Account',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'uikit',
      iconUrl: 'assets/icon/menu/settings.svg',
      tooltip: 'ui kit',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    /// Mobile Menus
    {
      name: 'asn',
      iconUrl: 'assets/icon/menu/Inbound.svg',
      tooltip: 'asn',
      isWeb: false,
      hasPanel: true,
      isCap: true
    },
    {
      name: 'pick',
      iconUrl: 'assets/icon/menu/Outbound.svg',
      tooltip: 'pick',
      isWeb: false,
      hasPanel: true,
      isCap: false
    },


  ];

  public static MENU_LINKS_DATA = {

    dashboard: {
      title: '',
      name: 'dashboard',
      index: 0,
      links: [],
      hasPanel: false,
      isLinkExpanded: false,
    },
    purchase: {
      title: 'purchase',
      name: 'purchase',
      index: 1,
      hasPanel: false,
      isLinkExpanded: false
    },
    inbound: {
      title: 'inbound',
      name: 'inbound',
      index: 2,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'overview | 721 ',
          route: AppConstants.APP_ROUTES.inbound.overview,
        },
        {
          name: 'check in | 724 ',
          route: AppConstants.APP_ROUTES.inbound.checkin,
        },
        {
          name: 'unload | 554 ',
          route: AppConstants.APP_ROUTES.inbound.unload,
        },
        {
          name: 'receive | 324 ',
          route: AppConstants.APP_ROUTES.inbound.receive,
        },
        {
          name: 'putaway | 314 ',
          route: AppConstants.APP_ROUTES.inbound.putaway,
        },
        {
          name: 'history',
          route: AppConstants.APP_ROUTES.inbound.history,
        }
      ]
    },
    outbound: {
      title: 'outbound',
      name: 'outbound',
      index: 3,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'overview | 721 ',
          route: AppConstants.APP_ROUTES.outbound.overview
        },
        {
          name: 'pick | 724',
          route: AppConstants.APP_ROUTES.outbound.pickByOrder,
          links: [
            {
              name: 'orders ',
              route: AppConstants.APP_ROUTES.outbound.pickByOrder
            },
            {
              name: 'batches',
              route: AppConstants.APP_ROUTES.outbound.pickByBatch
            },
          ]
        },
        {
          name: 'pack | 314',
          route: AppConstants.APP_ROUTES.outbound.packSmallParcel,
          links: [
            // {
            //   name: 'freight',
            //   route: AppConstants.APP_ROUTES.outbound.packFreight
            // },
            {
              name: 'small-parcel  ',
              route: AppConstants.APP_ROUTES.outbound.packSmallParcel
            }
          ]
        },
        // {
        //   name: 'consolidate',
        //   route: AppConstants.APP_ROUTES.outbound.consolidate
        // }
        {
          name: 'ship | 324',
          route: AppConstants.APP_ROUTES.outbound.shipSmallParcel,
          links: [
            // {
            //   name: 'freight',
            //   route: AppConstants.APP_ROUTES.outbound.shipFreight
            // },
            {
              name: 'small-parcel',
              route: AppConstants.APP_ROUTES.outbound.shipSmallParcel
            }
          ]
        },
        {
          name: 'history',
          route: AppConstants.APP_ROUTES.inbound.history,
        }
      ]
    },
    inventory: {
      title: 'inventory',
      name: 'inventory',
      index: 4,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'overview',
          route: AppConstants.APP_ROUTES.inventory.default,
        },
        {
          name: 'stock transfer',
          route: AppConstants.APP_ROUTES.inventory.stockTransfer,
        },
        {
          name: 'inventory adjustment',
          route: AppConstants.APP_ROUTES.inventory.inventoryAdjustment,
        },
        // {
        //   name: 'inetory adjustment',
        //   route: AppConstants.APP_ROUTES.inbound.unload,
        // },
        // {
        //   name: 'cycle count',
        //   route: AppConstants.APP_ROUTES.inbound.receive,
        // },
        // {
        //   name: 'bill of material',
        //   route: AppConstants.APP_ROUTES.inbound.putaway,
        // }
      ]
    },
    accountmanagement: {
      title: 'Account ',
      title2: 'Account',
      name: 'accountmanagement',
      index: 5,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Company Accounts',
          route: AppConstants.APP_ROUTES.accountmanagement.default
        },
        {
          name: 'Subscription Plan',
          route: AppConstants.APP_ROUTES.accountmanagement.subscriptionplan
        },
         {
          name: 'Integration Setting',
          route: AppConstants.APP_ROUTES.accountmanagement.integrationsetting
        },
        {
          name: 'Master Data',
          route: AppConstants.APP_ROUTES.accountmanagement.masterdata
        }
      ],

      AccountLinks: [

        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.accountmanagement.companyoverview
        },
        {
          name: 'Warehouses',
          route: AppConstants.APP_ROUTES.accountmanagement.AcccountManagementWarehouse
        },
        {
          name: 'Clients',
          route: AppConstants.APP_ROUTES.accountmanagement.Managementclients
        },
        {
          name: 'Users',
        route: AppConstants.APP_ROUTES.accountmanagement.ManagementUsers
         },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.accountmanagement.ManagementSettings
        }

      ],
    },
    account:{
      title: 'account',
      title1: 'Client',
       name: 'account',
      index: 6,
      hasPanel: true,
      isLinkExpanded: false,
      links: [

        {
          name: 'Warehouses',
          route: AppConstants.APP_ROUTES.account.default
        },
        {
          name: 'Clients',
          route: AppConstants.APP_ROUTES.account.clients
        },
        {
          name: 'Users',
          route: AppConstants.APP_ROUTES.account.users
        },
        {
          name: 'Roles',
          route: AppConstants.APP_ROUTES.account.roles
        },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.account.seetings
        },

      ],

       Clientlinks: [

        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.account.clientoverview
        },
        {
          name: 'Items',
          route: AppConstants.APP_ROUTES.account.itemmaster
        },
        {
          name: 'Suppliers',
          route: AppConstants.APP_ROUTES.account.suppliers
        },
        {
          name: 'Ship Method',
        route: AppConstants.APP_ROUTES.account.carrier
         },

        {
          name: 'Customers',
          route: AppConstants.APP_ROUTES.account.customers
        },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.account.clientSettings
        }

      ],







    },
    uikit: {
      title: 'uikit',
      name: 'uikit',
      index: 7,
      hasPanel: false,
      isLinkExpanded: false,
    },

    // Mobile Menu Options
    asn : {
      title: 'asn',
      name: 'asn',
      index: 8,
      hasPanel: true,
      isLinkExpanded: false,
      isCaps: true,
      links: [
        {
          name: 'unload',
          route: AppConstants.APP_ROUTES.asn.default,
        },
        {
          name: 'receive',
          route: AppConstants.APP_ROUTES.asn.receive,
        },
        {
          name: 'putaway',
          route: AppConstants.APP_ROUTES.asn.putaway,
        },
      ]
    },
    pick: {
      title: 'pick',
      name: 'pick',
      index: 9,
      hasPanel: true,
      isLinkExpanded: false,
      isCaps: false,
      links: [
        {
          name: 'Orders',
          route: AppConstants.APP_ROUTES.pick.scanOrder,
        },
        {
          name: 'Batches',
          route: AppConstants.APP_ROUTES.pick.scanBatch,
        },

      ]
    },


  };
  public static GRID_FILTER_LABLES = {
    all: 'All',
    pending: 'Pending',
    allocated: 'Allocated',
    partiallyAllocated: 'Partially Allocated',
    readyToPick: 'Ready to Pick',
    delayed: 'Delayed',
    dueNow: 'Due now',
    future: 'Future',
    pick: 'Pick',
    pack: 'Pack',
    completedToday: 'Completed'
  };
  public static ROLES={
    CompanyAdmin:'admin',
    SuperAdmin:'superadmin'
  }
}
