import { Component, OnInit, Input, Attribute, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'v1-ui',
  templateUrl: './v1-ui.component.html',
  styleUrls: ['./v1-ui.component.scss']
})
export class V1UIComponent implements OnInit {
  @Input() _layoutElements=[];
  @Input() _workAreaId='0';
  @Output() _onEnterPress=new EventEmitter<any>();
  @Output() _onInputfocus=new EventEmitter<any>();
  @Output() _onLookupIconClicked=new EventEmitter<any>();
  @Output() _onLookupEnterPress=new EventEmitter<any>();
  @Output() _EscBackClicked=new EventEmitter<any>();
  @Input('readonly') _readonly:boolean;
  @Input('formLabelMinWidth') _maxInputLabelWidth=20;
  @Input('isClickableOptionScreen') _isClickableOptions=false;
  _fillCompleteArea:boolean=false;
  constructor(@Attribute('readonly') readonly,@Attribute('fillCompleteArea') fillCompleteArea) {
    if(readonly=='true' || readonly==true){
      this._readonly=true;
    }else{
      this._readonly=false;
    }
    if(fillCompleteArea=='true' || fillCompleteArea==true){
      this._fillCompleteArea=true;
    }else{
      this._fillCompleteArea=false;
    }
  }

  ngOnInit(): void {
  }
  testTabPress(){
    console.log("Tab press inside Componet");
  }

}
