<ng-container *ngIf="!isMobile">

  <!-- ====================LEVEL-1============================-->
  <ng-container *ngFor="let item of v1menuservice.menu.menus;let parentIndex=index;">
    <ng-container *ngIf="item.isActiveForChildVisibility">

      <div class="title-area">
        <panel-title class="panel-title">{{ item?.title | uppercase}}</panel-title>
      </div>

      <ul class="outer-list main">
        <li class="overflow" *ngFor="let submenu of item.SubMenues;let smIndex=index;" id='selected-first'
          style="margin: 7px 0px !important; border-radius: 5px;" [style.cursor]="submenu.isActive? '':'pointer'"
          [ngClass]="{'selected-first':submenu.isActive }" [style.background-color]="(submenu.isActive && submenu.childs.length == 0)? '#333333' : '
          #FFFFFF'">

          <ng-container *ngIf="submenu?.shouldRenderOptions">
            <img [ngClass]="{'link-bullet': (submenu.childs.length == 0 )}"
              [style.color]="(submenu.isActive && submenu.childs.length == 0) ? '#FFFFFF':''"
              [style.background-color]="(submenu.isActive && submenu.childs.length == 0)? '#FFFFFF': ''"
              (click)="onMenuRequest(item,parentIndex,smIndex,submenu.cmd,submenu.isActive);;$event.stopPropagation();"
              src="/assets/icon/arrow-down.svg">
          </ng-container>

          <ng-container *ngIf="!submenu?.shouldRenderOptions">
            <span class="link-bullet" [style.color]="(submenu.isActive && submenu.childs.length == 0) ? '#FFFFFF' : '
            #333333'"></span>
          </ng-container>

          <a (click)="onMenuRequest(item,parentIndex,smIndex,submenu.cmd,submenu.isActive);
          toggleMenuElements(item,submenu); $event.stopPropagation();" class="link-name disable-text-selection"
            id="selectedchild" style="padding-left: 2px !important;" [matTooltipClass]="'tooltip-pd'"
            [matTooltip]="submenu.title.slice(5)" [matTooltipPosition]="'right'" [style.color]="(submenu.isActive && submenu.childs.length == 0) ? '#FFFFFF' : '
            #333333'">{{
            (submenu.title.slice(5))}}</a>

          <!-- ====================LEVEL-2============================-->
          <ul class="inner-list pick-list">
            <ng-container *ngFor="let subItemchild of submenu.childs;let firstChildIndex=index;">

              <li class="disable-text-selection" *ngIf="subItemchild.shouldRender">
                <a class="link-name disable-text-selection">
                  <!--If menu have more childs -->
                  <label
                    *ngIf="(subItemchild.cmd && subItemchild.cmd.value!=null && subItemchild.cmd.value.length>0 && subItemchild.isGridBasedMenu) || subItemchild.childs.length>0"
                    [matTooltipClass]="'tooltip-pd'" [matTooltip]="subItemchild?.tooltip" class="overflow"
                    [matTooltipPosition]="'right'"
                    style="width: 92%; font-size: 14px; font-weight: 400; margin: 0px -4px !important;"
                    [style.color]="subItemchild.isActive || subItemchild.applyActiveClass? '#333333':'#333333'"
                    [style.cursor]="subItemchild.isActive || subItemchild.applyActiveClass? '':'pointer'"
                    [ngClass]="{'selected-thirdlayer': !subItemchild.shouldRenderOptions &&  subItemchild.isActive || subItemchild.applyActiveClass   }"
                    (click)="OnSubMenuClicked(item,smIndex,firstChildIndex,submenu);
                    toggleMenuElements(item,submenu,subItemchild); $event.stopPropagation();">
                    <span *ngIf="subItemchild.shouldRenderOptions" style="margin-left:3px !important">
                      <ng-container *ngIf="subItemchild.childs.length>0">
                        <img src="/assets/icon/arrow-down.svg"
                          [ngClass]="{'link-arrow-up':!subItemchild.toggleChild,'link-arrow-down':subItemchild.toggleChild}"
                          style="margin-top: 5px !important;
                      float: right;" />
                      </ng-container>
                      {{ (subItemchild.title)}}


                    </span>
                    <span *ngIf="!subItemchild.shouldRenderOptions" style="margin-left: -7px !important;"
                      id="selected-thirdlayer"
                      [style.color]="subItemchild.isActive || subItemchild.applyActiveClass? '#FFFFFF':'#333333'">
                      <span class="first-selection" style="margin-left: 5px;
                                  margin-right: 5px; background-color: black; width: 5px;
                                  margin-top: 2px;
                                  height: 5px;"
                        [style.color]="subItemchild.isActive || subItemchild.applyActiveClass? '#FFFFFF':'#333333'"
                        [style.background-color]="subItemchild.isActive || subItemchild.applyActiveClass? '#FFFFFF':'#333333'">
                      </span>
                      {{ (subItemchild.title)}}
                    </span>
                  </label>
                  <!--If menu doesnot have more childs -->
                  <!-- test -->
                  <!--If menu doesnot have more childs -->
                  <label class="overflow"
                    *ngIf="subItemchild.cmd && subItemchild.cmd.value!=null && subItemchild.cmd.value.length>0 && !subItemchild.isGridBasedMenu && subItemchild.childs.length == 0"
                    [matTooltipClass]="'tooltip-pd'" [matTooltip]="subItemchild?.tooltip" [matTooltipPosition]="'right'"
                    style="width: 92%;font-size: 14px; font-weight: 400; margin-left: 7px 0px;"
                    [style.color]="subItemchild.isActive? '#FFFFFF':'#333333'"
                    [style.cursor]="subItemchild.isActive? '':'pointer'" (click)="OnSubMenuRequest(item,submenu,smIndex,firstChildIndex,subItemchild.cmd);
                    toggleMenuElements(item,submenu,subItemchild); $event.stopPropagation();"
                    [ngClass]="{'selected-secondlayer':subItemchild.isActive }">
                    <span>
                      {{(subItemchild.title)}}
                    </span>
                  </label>
                </a>
                <!-- ====================LEVEL-3============================-->
                <ng-container *ngIf="subItemchild.childs.length>0 && subItemchild.shouldRenderOptions">
                  <!--Sub menu Child List-->
                  <ul class="outer-list expended"
                    [ngClass]="{'expended-show':subItemchild.toggleChild,'expended-hide':!subItemchild.toggleChild}">
                    <!--Loop throug each submenu-->
                    <ng-container *ngFor="let gridChildMenues of subItemchild.childs;let gridChildItemIndex=index;">
                      <!-- ====================LEVEL-3(A)============================-->
                      <ng-container *ngIf="gridChildMenues.childs.length>0 && gridChildMenues.shouldRender">
                        <li style="padding-left: 0px !important;" class="selected" id="GridChildMenu"
                          (click)="$event.stopPropagation()" (mouseenter)="buttonEnter(gridR)"
                          (mouseleave)="buttonLeave(gridR, liMatMenu)">
                          <a class="link-name">
                            <label [matTooltipClass]="'tooltip-pd'" [matTooltip]="gridChildMenues?.tooltip"
                              [matTooltipPosition]="'right'"
                              style="width: 95%;font-size: 14px; font-weight: 400; margin-left: -7px;"
                              [style.color]="gridChildMenues.toggleChild? '#333333' :'#333333'"
                              [style.cursor]="gridChildMenues.toggleChild? '':'pointer'" class="overflow"
                              (click)="onGridChildMenuesClicked(gridChildItemIndex, gridChildMenues); toggleMenuElements(item,submenu,subItemchild,gridChildMenues);">
                              <span *ngIf="subItemchild.shouldRenderOptions" style="margin-left: 10px !important ;">
                                <img src="/assets/icon/arrow-down.svg"
                                  [ngClass]="{'link-arrow-up':!gridChildMenues.toggleChild, 'link-arrow-down':gridChildMenues.toggleChild}"
                                  style="float: right !important; margin-right: -5px !important; margin-top: 5px !important; "
                                  [style.color]="subItemchild.isActive? '#74C4E4':'black'" />
                                {{ (gridChildMenues.title)}}
                              </span>
                              <span *ngIf="!subItemchild.shouldRenderOptions" class="ml-1" id="selected-secondlayer">
                                {{ (gridChildMenues.title)}}
                              </span>
                            </label>
                            <ul class="inner-list last-pick-list"
                              [ngClass]="{'expended-show':gridChildMenues.toggleChild ,'expended-hide':!gridChildMenues.toggleChild}">
                              <!-- [ngClass]="{'expended-show':gridChildItemIndex === gridChildIndex,'expended-hide':!gridChildItemIndex === gridChildIndex}" -->
                              <ng-container *ngIf="gridChildItemIndex || gridChildItemIndex === gridChildIndex">
                                <li *ngFor="let child of gridChildMenues.childs;let fChild=index;"
                                  (click)="newOnFormMenuRequest(item,subItemchild,gridChildMenues,smIndex,firstChildIndex,gridChildItemIndex,child.cmd.value,fChild, child); toggleMenuElements(item,submenu,subItemchild,gridChildMenues,child);">
                                  <a class="link-name" *ngIf="child.title!==' ' ">
                                    <span class="ml-1">
                                      <label [matTooltipClass]="'tooltip-pd'" [matTooltip]="child?.tooltip"
                                        [matTooltipPosition]="'right'" [style.color]="(child._isActive && child.childs.length<0)? '#FFFFFF' :'
                                    #333333'"
                                        [style.cursor]="(child._isActive && child.childs.length<0) ? '':'pointer'"
                                        [ngClass]="{'last-selection':(child._isActive && child.childs.length<=0)}"
                                        style="margin-left: -7px !important; font-weight: 400 !important; margin-bottom: -12px !important;
                                    font-size: 14px !important;  text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 98%;">
                                        <span style="margin-left: 4px;"></span>
                                        <span *ngIf="child.shouldRenderOptions && child.childs.length>0"
                                          style="margin-left: 10px !important ;">
                                          <img src="/assets/icon/arrow-down.svg"
                                            [ngClass]="{'link-arrow-up':!child.toggleChild, 'link-arrow-down':child.toggleChild}"
                                            style="float: right !important; margin-right: -5px !important; margin-top: 5px !important; "
                                            [style.color]="(child._isActive && child.childs.length<0)? '#74C4E4':'black'" />
                                          {{ (child.title) | titlecase }}
                                        </span>
                                        <span *ngIf="child.shouldRenderOptions && child.childs.length<=0"
                                          style="margin-left: 10px !important ;">
                                          {{ (child.title) | titlecase }}
                                        </span>
                                      </label>
                                      <!--====================================================LEVEL-4==================================-->
                                      <ul class="inner-list last-pick-list"
                                        [ngClass]="{'expended-show': child.toggleChild,'expended-hide':!child.toggleChild}">
                                        <!-- [ngClass]="{'expended-show': child.toggleChild,'expended-hide':!child.toggleChild}" -->
                                        <!-- [ngClass]="{'expended-show':(fChild === gridChildIndexnew && child.toggleChild),'expended-hide':!(fChild === gridChildIndexnew && child.toggleChild)}" -->
                                        <ng-container *ngIf="fChild === gridChildIndexnew">
                                          <li *ngFor="let child2 of child.childs;let fChild2=index;"
                                            (click)="newMenuLayer(item,submenu,subItemchild,gridChildMenues,child,smIndex,firstChildIndex,gridChildItemIndex,fChild,fChild2,child2.cmd.value); toggleMenuElements(item,submenu,subItemchild,gridChildMenues,child,child2);">
                                            <a class="link-name" *ngIf="child2.title!==' ' ">
                                              <span class="ml-1">
                                                <label [matTooltipClass]="'tooltip-pd'" [matTooltip]="child2?.tooltip"
                                                  [matTooltipPosition]="'right'" [style.color]="child2._isActive ? '#FFFFFF' :'
                                                      #333333'" [style.cursor]="child2._isActive ? '':'pointer'"
                                                  [ngClass]="{'last-selection':child2._isActive }"
                                                  style="margin-left: 5px !important; font-weight: 400 !important; margin-bottom: -12px !important;
                                                      font-size: 14px !important;  text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: 98%;">
                                                  <span style="margin-left: 4px;"></span>

                                                  {{ (child2.title) | titlecase }}

                                                </label>
                                              </span>
                                            </a>
                                          </li>
                                        </ng-container>
                                      </ul>
                                      <!--=========================================================LEVEL-4 END==================================-->

                                    </span>
                                  </a>
                                </li>
                              </ng-container>
                            </ul>

                          </a>
                        </li>
                      </ng-container>
                      <!--When there is not tertury menu exist , the template will renderd without mat-menu selector -->
                      <!-- ====================LEVEL-3(B)============================-->
                      <ng-container *ngIf="gridChildMenues.childs.length==0 && gridChildMenues.shouldRender">
                        <li style="padding-left: 0px !important;" class="selected" id="GridChildMenu"
                          id="menuBtnGRidChild" (click)="$event.stopPropagation()">
                          <a class="link-name">
                            <label class="overflow" [matTooltipClass]="'tooltip-pd'"
                              [matTooltip]="gridChildMenues?.tooltip" [matTooltipPosition]="'right'" style="width: 95%;font-size: 14px; font-weight: 400;
                              margin-left: 0px !important;"
                              [style.color]="gridChildMenues.isActive? '#FFFFFF':'#333333'"
                              [style.cursor]="gridChildMenues.isActive? '':'pointer'"
                              [ngClass]="{'last-selection':gridChildMenues.isActive }"
                              (click)="newOnFormMenuRequest(item,subItemchild,gridChildMenues,smIndex,firstChildIndex,gridChildItemIndex); toggleMenuElements(item,submenu,subItemchild,gridChildMenues, child);">
                              <span>
                                {{ (gridChildMenues.title)}}
                              </span>
                            </label>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>

                  </ul>
                </ng-container>
              </li>
            </ng-container>

          </ul>

        </li>
      </ul>
    </ng-container>
  </ng-container>
</ng-container>

<div *ngIf="!isMobile">
  <div class="container">
    <div class="row menu-toggle-btn-container d-flex" (click)="menuToggle()">
      <div class="col-4 slidetoglebar">
        <mat-slide-toggle id="slideToggle-2" [(ngModel)]="slideToggleOne"></mat-slide-toggle>
      </div>
      <div class="wms-text-primary mt-2 col-8">Toggle Sidebar</div>
    </div>
  </div>

</div>