<div class="">
    <div class="circle">
    </div>
    <div class="background-gradient">
        <div class="copy-rights">
            <span class="cop">Copyright © {{CurrentYear}} SphereWMS. All Rights Reserved</span>
        </div>
    </div>
    <div class="login-container flex-box-row" [ngStyle]="{'visibility':displayLoginForm?'visible':'hidden'}" [ngClass]="{'invisible': showTotp}">
        <div class="login-form-container" [ngClass]="{'width-60': !isAnnouncementAvailable}">
            <div class="layout-adjust">
                <div class="login-form-section" *ngIf="!(multipleAccount || accountSelected)">
                    <form class="login-form" [formGroup]="loginForm">

                        <div class="login-header">
                            <div class="wms-sign-in">
                                <span class="sign-in-label float-left">{{ 'Sign In' }}&nbsp;</span>
                            </div>
                        </div>

                        <div class="username-section">
                            <div class="username-label font-normal">{{ 'Username' }}</div>
                            <div class="username-input custom-input">
                                <div class="icon-user"></div>
                                <input class="user-input input-txt" type="text" id="txtUsername"
                                    formControlName="username" [class.invalid-input]="showValidationError"
                                    (input)="clearFlags()" placeholder="Enter your username here" />
                            </div>

                        </div>
                        <div class="password-section">
                            <div class="password-label-section font-normal">
                                <div id="lbl-password" class="password-label">{{ 'Password' }}</div>
                                <!-- <div id="lbl-forget-password" class="forgot-password-label">{{ 'Forgot password?' }}</div> -->
                            </div>
                            <div class="password-input  custom-input">
                                <div class="icon-pass"></div>
                                <input [type]="hide ? 'password' : 'text'" id="txtPassword" class="input-txt"
                                    formControlName="password" [class.invalid-input]="showValidationError"
                                    (input)="clearFlags()" (keyup.enter)="onLoginSubmit()" placeholder="**********" />
                                <img class="eye-pwd" *ngIf="hide" src="/assets/icon/eye-hide-pwd.svg" alt=""
                                    (click)="showPwd()">
                                <img class="eye-pwd" *ngIf="!hide" src="/assets/icon/eye-show-pwd.svg" alt=""
                                    (click)="showPwd()">
                            </div>
                            <div class="validation-error" id="password-validation" *ngIf="showValidationError">
                                {{ validationMsg }}
                            </div>
                        </div>
                        <div class="submit-section flex-box-center">
                            <button type="button" id="btn-signin" class="signin-button" (click)="onLoginSubmit()">
                                {{ 'SIGN IN' }}
                            </button>
                        </div>
                        <!-- <a id="lbl-forget-password" class="forgot-password-label mt-2">Forgot your Password?</a> -->
                    </form>
                </div>

                <!--Account selection area-->
                <div class="accountSelection" *ngIf="(multipleAccount || accountSelected)">
                    <div class="row justify-content-center mt-5">
                        <div class="col-8" *ngIf="multipleAccount">
                            <label>Select Account</label>
                            <ng-select [allowClear]="true" [items]="authenticationService.multipleUsers"
                                [(ngModel)]="selectedAccount" placeholder="Select Account" bindLabel="title"
                                bindValue="userName" (change)="LoginToSpecificUser()"
                                (clear)="onAccountSelectionClear($event)">
                            </ng-select>


                        </div>

                        <!-- (scrollToEnd)="ddlClinetScrollToEnd()" [keyDownFn]="keydownInDropdown" #ddlClient
                    (keydown.enter)="ddlOnEnterPress($event,ddlClient)" [clearable]="'false'" -->
                        <div class="col-8 mt-3" *ngIf="accountSelected">
                            <label>Select Client</label>
                            <ng-select [items]="authenticationService.clinets" placeholder="Select Client"
                                bindLabel="name" bindValue="id" [(ngModel)]="selectedClient"
                                (change)="clientSelectionChange()" (scrollToEnd)="ddlClinetScrollToEnd()"
                                [keyDownFn]="keydownInDropdown" #ddlClient
                                (keydown.enter)="ddlOnEnterPress($event,ddlClient)" [clearable]="'false'">
                            </ng-select>
                        </div>
                    </div>
                </div>
                <!--Account selection area End-->
            </div>

        </div>
        <!-- <div class="login-image-container flex-box-column">
            <div class="login-info-text flex-box-column font-normal">
                <div class="d-flex align-items-center">
                    <img class="announcement-icon" src="/assets/icon/announcement-icon.png" alt="Announcement Icon">
                    <div class="app-title">
                        {{ title }}
                    </div>
                </div>
                <div class="app-description" [innerHTML]="desc" id="desc" [ngClass]="{'show': showFull}"></div>
                <div class="show-hide-text" *ngIf="isTextOverflow('desc') && !showFull" (click)="viewMore(true)">
                    <span>View More</span>
                    <img class="view-more-btn" src="/assets/icon/Vector2.svg" />
                </div>

                <div class="show-hide-text" *ngIf="showFull" (click)="viewMore()">
                    <span>View Less</span>
                    <img class="view-more-btn" src="/assets/icon/Vector1.svg" />
                </div>
            </div>
            <div class="flex-box-column fork-lift-container" *ngIf="!showFull">
                <img class="image-laptop" src="/assets/icon/devices23.png" />
            </div>
            <div>
                <img class="bg-logo" src="/assets/icon/spherewms.png" alt="SphereWms Logo">
            </div>

        </div> -->

        <div class="login-image-container" [ngClass]="{'width-40': !isAnnouncementAvailable}">
            <div class="textNimg">
                <div class="login-info-text" [ngClass]="{'login-info-text-show': showFull}">
                    <div class="d-flex align-items-center mb-3" *ngIf="title" [ngClass]="{'invisible': !(title && title.length > 0)}">
                        <img class="announcement-icon" src="/assets/icon/announcement-icon.png" alt="Announcement Icon">
                        <div class="app-title">
                            {{ title }}
                        </div>
                    </div>
                    <div #appDescRef class="app-description" [innerHTML]="desc" id="desc" [ngClass]="{'fixed-no-of-lines': !showFull, 'announcement-height': showFull}"></div>
                    <div class="show-hide-text" *ngIf="isTextOverflow('desc') && !showFull" (click)="viewMore(true)">
                        <span>View More</span>
                        <img class="view-more-btn" src="/assets/icon/Vector2.svg" />
                    </div>

                    <div class="show-hide-text" *ngIf="showFull" (click)="viewMore()">
                        <span>View Less</span>
                        <img class="view-more-btn" src="/assets/icon/Vector1.svg" />
                    </div>
                </div>
                <div class="d-flex justify-content-end pointer-events-none" *ngIf="!showFull" style="height: 50%;">
                    <img class="image-laptop" src="/assets/icon/devices23.png" />
                </div>
            </div>
            <img class="bg-logo" src="/assets/icon/spherewms.png" alt="SphereWms Logo"
                [ngClass]="{'bg-logo-big': !isAnnouncementAvailable}">
        </div>
    </div>
</div>