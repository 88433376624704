import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, UtilityService, V1MenuService, V1UtilitiesService } from '@app/core';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-grid-confirmation-popup',
  templateUrl: './grid-confirmation-popup.component.html',
  styleUrls: ['./grid-confirmation-popup.component.scss']
})
export class GridConfirmationPopupComponent implements OnInit {
  // selectedFiles: FileList;
  // currentFile: File;
  // progress = 0;
  // message = '';
  // uniqueid;
  // fileInfos: Observable<any>;

  constructor(public dialogRef: MatDialogRef<GridConfirmationPopupComponent>,private v1Utility:V1UtilitiesService,
    private _activatedRoute: ActivatedRoute,
    private auth:AuthenticationService,
    private _utilityService:UtilityService,public v1menuservice:V1MenuService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     }

    ngOnInit(): void {

    }

    // selectFile(event): void {
    //   this.selectedFiles = event.target.files;
    // }

    // upload(): void {





    //   // this.progress = 0;

    //   // this.currentFile = this.selectedFiles.item(0);
    //   // this.v1Utility.upload(this.currentFile,this.uniqueid).subscribe(
    //   //   event => {
    //   //     console.log("File Uploaded",event);
    //   //   //    if (event.type === HttpEventType.UploadProgress) {
    //   //   //      this.progress = Math.round(100 * event.loaded / event.total);
    //   //   //  } else if (event instanceof HttpResponse) {
    //   //   //     this.message = event.body.message;
    //   //   //     this.fileInfos = this.v1Utility.getFiles();
    //   //   //    }
    //   //   },
    //   //   err => {
    //   //   //    this.progress = 0;
    //   //   //    this.message = 'Could not upload the file!';
    //   //   //  this.currentFile = undefined;
    //   //     console.log("Error From File Uploading",err);
    //   //   });

    //   // this.selectedFiles = undefined;


    //   // this._utilityService.navigate('filesToenvironment',{cmd:cmd},true);

    //   // this.v1menuservice.NavigateToForm('0002');
    //   // window.location.reload();

    //   // this._activatedRoute.queryParams.subscribe(param => {
    //   //   console.log(param)
    //   //   if (param && param.cmd) {
    //   //     this.auth.gemxPost({
    //   //       gconn: this.auth.gConn,
    //   //       gcmd: '[RAW]'
    //   //     }).subscribe(x=>{
    //   //       console.log("Got Data",x);
    //   //       this._activatedRoute.queryParams.subscribe(param => {
    //   //         console.log(param)
    //   //         if (param && param.cmd) {
    //   //           this.auth.gemxPost({
    //   //             gconn: this.auth.gConn,
    //   //             gcmd: '[MENU]0002'
    //   //           })
    //   //         }
    //   //       });
    //   //     });
    //   //   }
    //   // });

    // }

  }



