<ul class="nav nav-pills wms-tab-head" id="myTab" role="tablist" TabLayoutHead>
    <li class="nav-item">
        <a class="nav-link" id="bi-tab" data-toggle="tab" role="tab" aria-controls="bi" aria-selected="true">Basic Information</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="bilinfo-tab" data-toggle="tab" role="tab" aria-controls="bilinfo" aria-selected="false">Billing Information</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="sp-tab" data-toggle="tab" role="tab" aria-controls="sp" aria-selected="false">Subscription Plan</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="gs-tab" data-toggle="tab" role="tab" aria-controls="gs" aria-selected="false">General Settings</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" id="cf-tab" data-toggle="tab" role="tab" aria-controls="cf" aria-selected="false">Carriers & Fulfillment</a>
    </li>
</ul>
<div class="tab-content wms-tab-body" id="myTabContent" TabLayoutBody>
    <div class="tab-pane fade" id="bi" role="tabpanel" aria-labelledby="bi-tab">
        <ng-content select="[basicInfo]"></ng-content>
    </div>
    <div class="tab-pane fade" id="bilinfo" role="tabpanel" aria-labelledby="bilinfo-tab">
        <ng-content select="[billingInfo]"></ng-content>
        <!-- <button (click)="SetToComplete('bilinfo')">Set Status</button> -->
    </div>
    <div class="tab-pane fade" id="sp" role="tabpanel" aria-labelledby="sp-tab">
        <ng-content select="[subscriptionPlan]"></ng-content>
    </div>
    <div class="tab-pane fade" id="gs" role="tabpanel" aria-labelledby="gs-tab">
        <ng-content select="[generalSettings]"></ng-content>
    </div>
    <div class="tab-pane fade" id="cf" role="tabpanel" aria-labelledby="cf-tab">
        <ng-content select="[carrearFullfilment]"></ng-content>
    </div>
</div>
<!--nav-justified-->
<div class="row wms-tab-footer">
    <div class="col-12">
        <div class="row justify-content-end">
            <div class="col-4 btn-col">
                <button class="btn wms-btn wms-btn-light">Save</button>
                <button class="btn wms-btn wms-btn-primary">Next</button>
            </div>
        </div>
    </div>
</div>
