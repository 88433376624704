import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private isMenuButtons: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private sideMenuToogle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedInventoryNew: BehaviorSubject<Number> = new BehaviorSubject<Number>(-1);

  SideMenuExtendedEmitter = new EventEmitter<any>();

  public dashBoard = {
    data: [],
    title: ''
  };
  public flipfirstCard: string = 'inactive';
  public flipSecondCard: string = 'inactive';
  public detailPage: boolean=false;
  public gridshow: boolean=false;
  public shiftPressed = false;
  constructor(public _router: Router) {
  }

  public mbl_menuToogle: boolean = false;

  public navigate(url: string, state?: any, isQueryParam: boolean = false) {
    if (isQueryParam) {
      return this._router.navigate([url], { queryParams: state });
    } else {
      if (state) {
        return this._router.navigate([url], { state: { data: state } });
      } else {
        return this._router.navigate([url]);
      }
    }

  }
  public navigateToHome() {
    this._router.navigate(['/']);
  }

  public navigatetoAddhoc(url) {
    this._router.navigate([]).then(result => { window.open(url, '_blank', "width=" + screen.availWidth + ",height=" + screen.availHeight) });
  }

  public navigateByUrl(url: string, [...arg]) {
    this.navigateByUrl(url, arg);
  }

  public deepCopy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  // Side Menu Button Bar Hide and Show Function
  public showMenuButtons(showMenu: boolean) {
    this.isMenuButtons.next(showMenu);
  }

  public isMenu() {
    return this.isMenuButtons;
  }

  public setSideMenuBar(toogle: boolean) {
    this.sideMenuToogle.next(toogle);
  }

  public isSideMenuBarToggle() {
    return this.sideMenuToogle;
  }

  public setselectedInventoryNew(toogle: Number) {
    this.selectedInventoryNew.next(toogle);
  }

  public isselectedInventoryNew() {
    return this.selectedInventoryNew;
  }
  public isAnyModuleRendered(): boolean {
    console.log("IS any module rendered:", this._router.url)
    if (this._router.url.endsWith('/') && !(this._router.url.length > 1)) {
      return false;
    } else {
      return true;
    }
  }
  public currentRouteIs(matched) {
    let _url = this._router.url;
    return ((_url + '').toLowerCase().endsWith(matched.toLowerCase()))
  }
  onEscapeKeyPress = new Subject();
  onFunctionKeyPress = new Subject<any>();
  onQuickFunctionSelected: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  //need to remove only for demo correction i added this function because
  // title change provision is not present in  autocomplete popup
  msgBoxTitleChenge = new EventEmitter<any>();



}
