import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ApienvService {
  // The values that are defined here are the default values that can
  // be overridden by apiConfig.js
  //Feature domain name
  featureDomainName='v1-redesign';
  // API url
  public API_Config = {
    apiUrl: '',
    backendApiUrl: environment.backendApiUrl,
    gemxUrl: environment.gemxUrl,
    // redAspenApi:environment.redAspenApi
  };

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {}
}
