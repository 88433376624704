<!-- <div [ngStyle]="{'widht':isOpen?'20px':'360px'}">
    <input id="searchOne" [ngModel]="isOpen" type="checkbox">
    <label for="searchOne">
              <i class="icon icon-search"></i>
              <i class="last icon-close"></i>
              <p>|</p>
          </label>
    <input placeholder="Search here" type="text">
</div> [ngStyle]="{'widht':isOpen?'20px':'360px'}"-->
<div style="height: 38px;">
    <input [(ngModel)]="param" placeholder="Search here" #search class="searchInput" (keydown.enter)="searchClicked()" type="text" [ngClass]="{'searchInput-open':isOpen}" id="searchBoxInput">
    <span class="icon-search" (click)="searchClicked()" id="searchBoxIconClose" [ngClass]="{'icon-search-animate':isOpen,'icon-search-animate-reverse':!isOpen}"></span>
    <span class="icon-seprator" id="searchBoxIcon" *ngIf="isOpen"></span>
    <!-- <span class="icon-clear" *ngIf="param && param.length>0"></span> -->
</div>
