// my-loader.component.ts
// import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounce, debounceTime } from 'rxjs/operators';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.scss']
})
export class MyLoaderComponent implements OnInit {

  loading: boolean;
  showImage: boolean =false;
  showImageTimeOut;
  requestTimeOut:boolean=false;
  requestWaiting:boolean=false;
  private debounceSubject: Subject<boolean> = new Subject();
  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      if(v==true){
        if(!this.showImageTimeOut){
          this.showImageTimeOut=setTimeout(() => {
            this.showImage=true;
          }, 2500);
        }
      }else{
        this.debounceSubject.next(false);
        this.requestWaiting=false;
        this.requestTimeOut=false;
      }
      this.loading = v;
    });
    this.loaderService.isAsyncLoading.subscribe((v) => {
      if(v==true){
        if(!this.showImageTimeOut){
          this.showImageTimeOut=setTimeout(() => {
            this.showImage=true;
          }, 2500);
        }
      }else{
        this.debounceSubject.next(false);
        this.requestWaiting=false;
        this.requestTimeOut=false;
      }
      this.loading = v;
    });
    this.loaderService.showTimeOutLabel.subscribe(x=>{
      if(x){
        this.requestTimeOut=x.type=='timeOut'?true:false;
        this.requestWaiting=!this.requestTimeOut;
      }else{
        this.requestTimeOut=false;
        this.requestWaiting=false;
      }
    })
  }
  ngOnInit() {
    this.debounceSubject.pipe(
      debounceTime(500)
    ).subscribe(value => {
      if(!this.loading){
        this.showImage=value;
         clearTimeout(this.showImageTimeOut);
         this.showImageTimeOut=undefined;
      }
    });
  }

}
