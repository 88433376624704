import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appDomClick]'
})
export class DomClickDirective {

  @Input() isClickAllowed: boolean = false;
  @Input() elementId: string;

  // DOM Click event listner
  @HostListener('document:click', ['$event']) DomClick() {
    if (this.isClickAllowed) {
      const el = document.getElementById(this.elementId);
      if (el) {
        el.click();
      }
    }
  }

  constructor() { }


}
