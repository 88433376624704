<ng-container *ngIf="!isMobile">
    <div class="header-container">
        <div [ngStyle]="{'padding-top':authenticationService.currentUserValue.designation === 'romeo-d' || authenticationService.currentUserValue.designation === 'romeo-s' || authenticationService.currentUserValue.designation === 'romeo' ? '1px' : '6px' }"
            class="logo-container">
            <a href="/"
                *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen' && authenticationService.currentUserValue.designation != 'romeo-d' && authenticationService.currentUserValue.designation != 'romeo-s' && authenticationService.currentUserValue.designation != 'romeo'">
                <img [src]="'/assets/icon/spherewms.svg'" alt="sphereWMS" />
            </a>
            <!-- For Customized Red Aspin -->
            <img *ngIf="authenticationService.currentUserValue.designation === 'redaspen-s' || authenticationService.currentUserValue.designation === 'redaspen'"
                [src]="'/assets/icon/redaspin-logo.svg'" alt="sphereWMS" />
            <!-- For Customized Red Aspin -->
            <!-- For Customized Target Romeo -->
            <img *ngIf="authenticationService.currentUserValue.designation === 'romeo-d' || authenticationService.currentUserValue.designation === 'romeo-s' || authenticationService.currentUserValue.designation === 'romeo'"
                [src]="'/assets/icon/targetRomeo-logo.svg'" alt="sphereWMS" />
            <!-- For Customized Target Romeo -->
        </div>

        <div class="header-body">
            <div class="wms-client" [ngClass]="{'noQuickfunc': router.url.includes('dashboard') }"
                *ngIf="ddlClinets && ddlClinets.length>0 && (authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen')"
                [matTooltip]="!dropdown?authenticationService?.clientNameFromMenu:''" [matTooltipClass]="'tooltip-xd'"
                [matTooltipPosition]="'below'">
                <ng-select #ddlClients class="ddlClient" [clearable]="'false'" [items]="ddlClinets"
                    placeholder="Select Client" bindLabel="name" bindValue="id" [(ngModel)]="selectedClient"
                    (change)="clientSelectionChange()" (open)="toggleDropdown()" (close)="toggleDropdown()">
                    <ng-template ng-option-tmp let-item="item">
                        <div [matTooltip]="item.name" [matTooltipPosition]="'right'" [matTooltipClass]="'tooltip-pd'"
                            style="text-overflow: ellipsis;overflow: hidden;">{{ item.name }}</div>
                    </ng-template>
                </ng-select>
            </div>
            <div class="wms-qf"
                *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen' && !router.url.includes('dashboard')">
                <ng-select class="ddlQF" [clearable]="'false'" [items]="authenticationService.qfddItems"
                    placeholder="Quick Functions" bindLabel="text" bindValue="cmd"
                    [(ngModel)]="authenticationService.QFSelected" [searchable]="searchableQF"
                    (change)="QuickFunctionSelected($event)" (clear)="clearQF()">
                </ng-select>
                <div class="space"></div>
            </div>
            <!-- <div class="wms-notification">
              <img [src]="notificationIconPath" alt="bell" />
          </div> -->
            <!-- (click)="openPopover($event)" -->
            <!-- [matMenuTriggerFor]="menu" -->
            <div class="wms-profile-panel" [matMenuTriggerFor]="menuLogin">
                <div class="profile-avtar">
                    <img [src]="userDetails.imgUrl?userDetails.imgUrl:'/assets/icon/user-logo.png'" alt="photo">
                </div>
                <div class="profile-details">
                    <div class="profile-name of-ellipsis" [matTooltipClass]="'tooltip-xd'"
                        [matTooltip]="userDetails?.masterUser" [matTooltipPosition]="'below'">{{ userDetails?.masterUser
                        }}</div>
                    <div class="profile-designation of-ellipsis" [matTooltipClass]="'tooltip-xd'"
                        [matTooltip]="userDetails?.designation" [matTooltipPosition]="'below'">{{
                        userDetails?.designation }}</div>
                </div>
                <div class="profile-arrow">
                    <img src="/assets/icon/arrow-down.svg" alt="bell" />
                </div>
                <mat-menu class="profile-drpdwn" #menuLogin="matMenu">
                    <div *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen' && authenticationService.isAdminUser == '99'"
                        class="profile-drpdwn-box" (click)="announcementHandler()">
                        <img alt="settings" src="/assets/icon/noun-announcement.svg" />
                        <span>Announcement</span>
                    </div>
                    <div *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen'"
                        class="profile-drpdwn-box" (click)="printerSettingHandler()"><img alt="settings"
                            src="/assets/icon/settings.svg" /><span>Printer Settings</span></div>
                    <div *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen'"
                        class="profile-drpdwn-box" (click)="openChangePassword()"><img alt="lockicon"
                            src="/assets/icon/lock-black.svg" /><span>Change Password</span></div>
                    <!-- Old help Documentation    <div class="profile-drpdwn-box" *ngIf="this.authenticationService.accessCtrlSetting.help && authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen'" (click)="helpBtnClicked()"><img alt="infoicon" src="/assets/icon/information.svg" /><span>Help Documentation</span></div>  -->
                    <!--  New help Documentation -->
                    <div class="profile-drpdwn-box"
                        *ngIf="authenticationService.currentUserValue.designation != 'redaspen-s' && authenticationService.currentUserValue.designation != 'redaspen'"
                        (click)="helpBtnClicked()"><img alt="infoicon" src="/assets/icon/information.svg" /><span>Help
                            Documentation</span></div>
                    <div class="profile-drpdwn-box" (click)="logout()"><img alt="Logouticon"
                            src="/assets/icon/power.svg" /><span>Logout</span></div>
                </mat-menu>
            </div>

        </div>
    </div>

</ng-container>

<ng-container *ngIf="isMobile">

    <div class="mbl-header-container">

        <div class="menu-button-container" (click)="menuToggle()">
            <img alt="Menubutton" src="./assets/icon/menu-icon.svg" class="menu-button">
        </div>

        <div class="form-title-container">
            <h3 class="form-title">{{ formTitle }}</h3>
        </div>


        <div class="notification-logo-container">
            <img alt="ntificationLogo" [src]="notificationIconPath" class="notify-logo" />
        </div>

    </div>

</ng-container>