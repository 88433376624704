<div class="grid-advnc-filter" [ngClass]="{'filterOpen':_opened,'filterClose':!_opened}">
    <div class="row" style="margin-right: 0px;">
        <div class="col-12 titleBar">
            <label class="filter-title">ADVANCE FILTER</label>
            <img src="./assets/icon/close.svg" class="Cpointer icoClose" (click)="onClose()">
        </div>

    </div>
    <div class="filter-body">
        <!-- accordian Implementation -->
        <mat-accordion *ngFor="let ddl of filterObj; let i=index;">
            <mat-expansion-panel [expanded]="ddl.isActive" (opened)="ddl.toggleValue = true" (closed)="ddl.toggleValue = false" hideToggle="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="pl-2" [class.active]="ddl.isActive">
                        {{ddl.title}}
                    </mat-panel-title>
                    <mat-panel-description>
                        <span *ngIf="!ddl.toggleValue" class="material-icons plus">add</span>
                        <span *ngIf="ddl.toggleValue" class="material-icons minus">minimize</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="itemsselect" *ngFor="let a of ddl.childs; let ind=index;" [style.font-weight]="a._isActive ? 'bolder' :'normal' " [style.color]="a.isActive ? '#466EB9' : '#5B5B5B' " (click)="inquiriesChanged(i,ind)">
                    {{a.title}}
                </p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>