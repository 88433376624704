<div class="container-popup" style="height: calc(100%);">
    <div class="general-popup-header">
        <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                <h2 class="">{{ _title }}</h2>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-2 text-right" style="padding-right: 30px;">
                <img src="/assets/icon/close.svg" (click)="_close()" class="Cpointer closeIcon">
            </div>
        </div>
    </div>
    <div class="general-popup-body body-area with-footer only-grid _body" style="height: calc(100% - 110px);overflow-y: auto;">
        <div #externalHtml>
        </div>
    </div>
    <div class="mt-2" *ngIf="_buttons.length>0" class="general-popup-footer">
        <div class="container-fluid">
            <div class="row d-flex justify-content-end">
                <div [class]="'col-'+(_buttons.length*2)+' btn-col'">
                    <ng-container *ngFor="let bt of _buttons">
                        <button class="wms-btn" [ngClass]="(bt.id=='change_password_form_action')?'wms-btn-primary':'wms-btn-light'" (click)="onbtnClick(bt.id)">{{bt.label}}</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>