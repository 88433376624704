import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';


// For NgModal Implementation
const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};
//--------

interface DropDown {
  id: any;
  name: string;
  selected: boolean;
  group: string;
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR] // For NgModal Implementation
})
// , ControlValueAccessor
export class MultiSelectComponent implements OnInit, ControlValueAccessor  {

  items: any = [];
  bindLabel: string = 'name';
  bindValue: string = 'id';
  ddlItems: DropDown[] =[];
  placeholder: string = 'Select';
  // selectedValue: any[] = [];

  //The internal data model
  private selectedValue: any = '';

  // Placeholders for the callbacks which are later providesd
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop; // For NgModal Implementation
  private onChangeCallback: (_: any) => void = noop; // For NgModal Implementation

  @Input('items') set ddlOptions(data) {
    this.items = data;
    this.formatItems(this.items);
  }
  @Input('bindLabel') set label(data) {
    this.bindLabel = data;
  }
  @Input('bindValue') set Value(data) {
    this.bindValue = data;
  }
  @Input('placeholder') set setPlaceholder(value) {
    this.placeholder = value;
  }

  @Output('add') addEmit = new EventEmitter<any>();
  @Output('blur') blurEmit = new EventEmitter<any>();
  @Output('selectedOptions') selectedOptions = new EventEmitter<any>();
  @Output('change') changeEmit = new EventEmitter<any>();
  @Output('close') closeEmit = new EventEmitter<any>();
  @Output('clear') clearEmit = new EventEmitter<any>();
  @Output('focus') focusEmit = new EventEmitter<any>();
  @Output('search') searchEmit = new EventEmitter<any>();
  @Output('open') openEmit = new EventEmitter<any>();
  @Output('remove') removeEmit = new EventEmitter<any>();
  @Output('scroll') scrollEmit = new EventEmitter<any>();
  @Output('scrollToEnd') scrollToEndEmit = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
  }

  //#region For NgModal Implementation

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.selectedValue) {
        this.selectedValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  //get accessor
  get value(): any {
    return this.selectedValue;
  };

  //set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.selectedValue) {
        this.selectedValue = v;
        this.onChangeCallback(v);
    }
  }

  //#endregion

  formatItems(items: any[]) {
    if (items && items.length > 0) {
      this.ddlItems = [...items];
      this.ddlItems.forEach(row => { row.group = 'All' });
    }
  }

  //#region Wrapped Events

  onAdd(event) {
    console.log('add');
    this.addEmit.emit(event);
  }

  onBlur(event) {
    console.log('blur');
    this.onTouchedCallback();
    this.addEmit.emit(event);
  }

  modelChange(value) {
    console.log('select');
    // console.log(this.selectedValue);

    // let emitValue = this.valueCheck();
    // this.selectedOptions.emit(emitValue);
    // if (value && (value.length > 0 && value.includes('All'))) {
    //   let emitValue = this.valueCheck();
    //   this.selectedOptions.emit(emitValue);
    // } else {

    // }
  }

  onChange(event) {
    console.log('change');
    this.changeEmit.emit(event);
  }

  onClose(event) {
    console.log('close');
    this.closeEmit.emit(event);
  }

  onClear(event) {
    console.log('clear');
    this.clearEmit.emit(event);
  }

  onFocus(event) {
    console.log('focus');
    this.focusEmit.emit(event);
  }

  onSearch(event) {
    console.log('search');
    this.searchEmit.emit(event);
  }

  onOpen(event) {
    console.log('open');
    this.openEmit.emit(event);
  }

  onRemove(event) {
    console.log('remove');
    this.removeEmit.emit(event);
  }

  onScroll(event) {
    console.log('scroll');
    this.scrollEmit.emit(event);
  }

  onScrollToEnd(event) {
    console.log('scroll end');
    this.scrollEmit.emit(event);
  }

  //#endregion

  // Not in use currently
  private valueCheck(){
    return (this.value && (this.value.length < 1 || this.value.includes('All')) )? '0' : this.value;
    // return (this.selectedValue.length < 1 || this.selectedValue.includes('All') )? '0' : this.selectedValue;
  }



}
