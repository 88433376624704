import { AfterContentInit, Directive, ElementRef, Renderer2 } from '@angular/core';
import { TabLayoutService } from './tab-layout.service';

@Directive({
  selector: '[TabLayoutBody]'
})
export class TabLayoutBodyDirective implements AfterContentInit  {

  constructor(private _el: ElementRef,private renderer:Renderer2,private service:TabLayoutService) { }
  ngAfterContentInit() {
    // contentChildren is set
    console.log("Tabs Panel Body Area",this._el);
    this.service.tabClicked.subscribe(x=>this.ActiveTab((x+'').replace('-tab','')));
    this.renderer.addClass(this._el.nativeElement.children[0],"show");
    this.renderer.addClass(this._el.nativeElement.children[0],"active");
    //this.renderer.addClass(this._el.nativeElement.children[0],"active");
  }
  ActiveTab(id){
    console.log("From Body ",id)
    for(let i=0; i<this._el.nativeElement.children.length;i++){
      //this.renderer.removeClass(this._el.nativeElement.children[i],"active");
      if(this._el.nativeElement.children[i].id==id){
        this.renderer.addClass(this._el.nativeElement.children[i],"show");
        this.renderer.addClass(this._el.nativeElement.children[i],"active");
      }else{
        this.renderer.removeClass(this._el.nativeElement.children[i],"show");
        this.renderer.removeClass(this._el.nativeElement.children[i],"active");
      }
    }
  }
}
