import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-client-selection-popup',
  templateUrl: './client-selection-popup.component.html',
  styleUrls: ['./client-selection-popup.component.scss']
})
export class ClientSelectionPopupComponent implements OnInit {
  title: any;
  body: any;
  type: any;
  timeOut: any;
  buttonText: any;
  constructor(public dialogRef: MatDialogRef<ClientSelectionPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.title;
    this.type = data.type;
    this.columnsToDisplay = data.body.cols;
    this.items = data.body.data;
  }
  items = [];
  columnsToDisplay = [];
  ngOnInit(): void {
  }
  RowClicked(event) {
    console.log("Row Selected", event);
    this.dialogRef.close({ event });
  }
}
