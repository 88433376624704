import { ApienvService } from "./apienv.service";

export const ApienvServiceFactory = () => {
  // Create env
  const env = new ApienvService();

  // Read environment variables from browser window
  const browserWindow = window || {};
  const browserWindowEnv = browserWindow["API_Config"] || {};
  // Assign environment variables from browser window to env
  // In the current implementation, properties from apiConfig.js overwrite defaults from the ApienvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      env.API_Config[key] = window["API_Config"][key];
    }
  }

  return env;
};

export const EnvServiceProvider = {
  provide: ApienvService,
  useFactory: ApienvServiceFactory,
  deps: []
};
