<ng-select
    [items]="ddlItems"
    [multiple]="true"
    bindLabel="name"
    bindValue="id"
    groupBy="group"
    [placeholder]="placeholder"
    [selectableGroup]="true"
    [closeOnSelect]="false"
    [(ngModel)]="value"
    (add)="onAdd($event)"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (close)="onClose($event)"
    (clear)="onClear($event)"
    (focus)="onFocus($event)"
    (search)="onSearch($event)"
    (open)="onOpen($event)"
    (remove)="onRemove($event)"
    (scroll)="onScroll($event)"
    (scrollToEnd)="onScrollToEnd($event)"
  >

    <!-- (change)="onChange($event)" -->
    <!-- All Option -->
    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
      <div class="ng-option-area">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
        <label>{{item.group}}</label>
      </div>
    </ng-template>
    <!-- Other Options -->
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">

        <div class="ng-option-area">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
          <label>{{item.name}}</label>
        </div>
    </ng-template>
</ng-select>
