<div class="container-popup" style="height: calc(100%);">
    <div class="general-popup-header">
        <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                <h2 #headTitle class="">{{ _isGemPad?'Gempad':_title | removeClientName:selectedClientName}}</h2>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                <img src="/assets/icon/close.svg" (click)="_isGemPad?_closeGemPad():_onEnterPress('[ESC]')" class="Cpointer closeIcon">
            </div>
        </div>
    </div>
    <div class="general-popup-body body-area" [ngClass]="
    [
    (_buttons.length>0  || _actionConfirmationRow.length>0)?(_actionConfirmationRow.length>0?'with-footer with-action-input':'with-footer'):'without-footer',
    ((_grid_columnsToDisplay.length>0 && _controls.length>0) || hasTwoSubSequentScreen)?'gird-entry-both':(_grid_columnsToDisplay.length>0?'only-grid':'only-form'),
    headerTextIsOverflowing?'withOverflowHeader':''
    ]" #bodyArea>
    <!-- For Previous/Next WorkArea Layes-->
        <ng-container *ngIf="hasTwoSubSequentScreen">
            <v1-ui fillCompleteArea="true" [readonly]="'true'" [_layoutElements]="startingWorkArea?._layoutElements" [_workAreaId]="startingWorkArea?._workAreaId" [formLabelMinWidth]="startingWorkArea?._maxInputLabelWidth" >
            </v1-ui>
        </ng-container>
        <!-- _controls -->
        <div *ngIf=" _controls.length>0 || _isGemPad ">
            <ng-container *ngIf="!_isGemPad;else GemPadTemplate">
                <v1-ui fillCompleteArea="true" [readonly]="isReadOnlyLayer" [_layoutElements]="_layoutElements" [_workAreaId]="_workAreaId" [formLabelMinWidth]="_maxInputLabelWidth" [isClickableOptionScreen]="_isOptionSelectionScreen" (_onEnterPress)="_onEnterPress($event)" (_onInputfocus)="onInputfocus($event)"
                    (_onLookupEnterPress)="_onEnterPress($event,true)" (_onLookupIconClicked)="onLookupInputClicked($event)" (_EscBackClicked)="_onEnterPress('[ESC]')">
                </v1-ui>
            </ng-container>
            <ng-template #GemPadTemplate>
                <textarea id="gempad_data" rows="15" cols="100" style="margin:0.5em 0em; padding:1em;
            resize:none; overflow-y:scroll;" [readonly]="_gemPadConfig.readonly" [(ngModel)]="_gemPadConfig.fileContent">
            </textarea>
            </ng-template>
        </div>
        <!--Grid-->
        <ng-container *ngIf="_grid_columnsToDisplay.length>0">
            <div class="row align-items-center" style="height: 50px;">
                <div class="col-12">
                    <div class="row justify-content-end">
                        <div class="col-8" *ngIf="isOpen">
                            <expended-search-box [(isOpen)]="isOpen" (onSearch)="onInquirySearch($event)" (onSearchClosed)="onInquirySearch('',true)"></expended-search-box>
                        </div>
                        <div class="col-10 grid-header-icon-row-parent" *ngIf="!isOpen">
                            <div class="grid-header-icon-row">
                                <div>
                                    <img src="assets/icon/csv.svg" [class.disabled]="selectedRows.length==0" (click)="gridActionButtonClicked('csv')">
                                    <span *ngIf="_isSearchingInputExist"></span>
                                </div>
                                <div *ngIf="_isSearchingInputExist">
                                    <img src="assets/icon/search.svg" id="gridSerchIcon" (click)="isOpen=true;" (click)="gridActionButtonClicked('search')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <v3-grid [MasterColumns]="_grid_columnsToDisplay" [GridData]="_grid_Items" [ShowContext]="notifyContextMenuObj" SelectableRow="true" SelectableRows="true" [hasMoreHadder]="false" [hasMoreRows]="_gridProps.available_more_rows" (RowDoubleClciked)="rowDbClicked($event)" (RowsSelected)="onRowsSelected($event)"
                (RowSelected)="onRowSelected($event)" [ShowEllipses]="false" (ScrollControl)="onScroll()" (LoadAllRows)="_loadAllRows()" (onContextMenuRequest)="LoadAndOpenRowContextMenu($event)" (onRowContextItemAction)="rowContextMenuClicked($event)">
            </v3-grid>
        </ng-container>

    </div>

    <div class="mt-2" *ngIf="(_buttons.length>0 || _actionConfirmationRow.length>0)" class="general-popup-footer" style="border-top: 2px solid rgba(0, 0, 0, .125);">
        <div class="container-fluid">
            <div class="row d-flex justify-content-end">
                <div *ngIf="_actionConfirmationRow.length>0" [ngClass]="'col-'+(_buttons.length>0?(12-(_buttons.length*2)):12)">
                    <div class="row form-bottom-fix-row mt-2">
                        <div *ngFor="let actionElem of _actionConfirmationRow;let elIndex=index;" class="col-12" style="min-height: 30px;">
                            <ng-container *ngFor="let item of actionElem">
                                <ng-container [ngSwitch]="item.cType">
                                    <!--When item is Input/LookUp-->
                                    <ng-container *ngSwitchCase="'input'">
                                        <!--Input-->
                                        <input class="input" tabindex="-1" *ngIf="!item.isLookUp" type="text" focusDirective [focus]="item.focus" [value]="item.cValue" [attr.id]="'lookup-footer-action-input-'+item.cCol" [attr.width]="item.elWidth" [attr.maxLength]="item.elLength" (keydown.enter)="_onEnterPress($event.target.value)"
                                            (onTabPress)="_onEnterPress($event)" (mouseClickFocus)="onInputfocus(item.elId)" />
                                        <!--LookUp Input-->
                                        <lookup-input *ngIf="item.isLookUp" cType="autoComplete" focusDirective [focus]="item.focus" [maxLength]="item.elLength" [defaultValue]="item.cValue" [id]="'auto-complete-with-lookup'+_workAreaId" (onEnterKey)="_onEnterPress($event,true)" (onTabPress)="_onEnterPress($event)"
                                            (mouseClickFocus)="onInputfocus(item.elId)" (onIconClicked)="onLookupInputClicked($event)">
                                        </lookup-input>
                                    </ng-container>
                                    <!--When item is Label-->
                                    <ng-container *ngSwitchCase="'label'">
                                        <label class="label" [att.for]="'lookup-footer-action-'+item.cCol" *ngIf="item.cType=='label'" [ngClass]="{'clickable':(item.isClickable),'lineHeight':elIndex==0}" [innerHtml]="item.cValue | removeNumber"></label>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div *ngIf="_buttons.length>0" [class]="'col-'+(_buttons.length*2)+' btn-col'">
                    <ng-container *ngFor="let bt of _buttons">
                        <button class="wms-btn" [ngClass]="(bt.lowercaseText=='ok' || bt.lowercaseText=='yes' || bt.lowercaseText=='done')?'wms-btn-primary':'wms-btn-light'" (click)="onbtnClick(bt.id)">{{bt.label}}</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
