import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridRowService {

  constructor() { }
  rowSelected=new EventEmitter<any>();
  selectedRowContext=new EventEmitter<any>();
}
