<div class="container" style="height: calc(100%);">
    <div class="general-popup-header">
        <div class="d-flex justify-content-between mb-2">
            <h2>{{title}}</h2>
            <img src="/assets/icon/close.svg" class="Cpointer closeIcon" mat-dialog-close>
        </div>
    </div>
    <div class="general-popup-body text-break">
        <ng-container *ngIf="type=='customTemplate'" [ngTemplateOutlet]="template"></ng-container>
        <p [innerHTML]="body"></p>
    </div>
</div>

<!-- <div class="general-popup-footer">
    <div class="container-fluid">

        <div class="row d-flex justify-content-end">
            <div class="col-4 btn-col">
                <button class="wms-btn wms-btn-light" mat-dialog-close>Close</button>
                <button class="wms-btn wms-btn-primary">Add</button>
            </div>
        </div>
    </div>
</div> -->