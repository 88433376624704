import { Component, EventEmitter, Host, HostListener, OnInit, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from "@angular/animations";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppUtils } from '@app/core/services/utils/App-Utils';
import { AppConstants } from '@app/core/services/app-constants';
import { AppRouteService } from '@app/core/services/app-route.service';
import { AppInitService, UtilityService, V1MenuService } from '@app/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { MSGBOXComponent } from '@app/modules/lookup/msg-box/msg-box.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '@app/core/services/loader.service';
import { v1LookupComponent } from '@app/modules/lookup/v1-lookup.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('400ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class HomeComponent implements OnInit {
  public showOutlet: boolean = true;
  public showPanel = true;
  public appRoutes: any = {};
  public menuButtons = [];
  public menuLinksData = {};
  public activeTabIndex = 0;
  public currentRouteName = '';
  public isMobileView: boolean = false;
  public menuToggle: boolean = false;
  slideToggletwo: boolean = false;
  public menuIndex: number = 0;
  public showMenuButton: boolean = true;
  public subMenus = [];
  public notInParent = false;
  public FirstTimeLoaded = true;
  public lastbtnclick;
  public currentPanelData = {
    title: '',
    name: '',
    hasPanel: false,
    links: []
  };
  public isMenuActive;
  public CurrentYear = new Date().getUTCFullYear();

  redaspin;
  constructor(
    public appRouteService: AppRouteService,
    public activatedRoute: ActivatedRoute,
    public _utilityService: UtilityService,
    public router: Router,
    public authService: AuthenticationService,
    private menuSer: V1MenuService,
    public v1menuservice: V1MenuService,
    public dialog: MatDialog,
    public loaderSer: LoaderService,
    public accountTo: AppInitService
  ) {
    this.loadPreviousPanel(window.location.pathname);
    this.isMobileView = AppUtils.mobileCheck();
    this.router.events.forEach((event) => {
      // console.log("Router All Event Changed From Home",event);
      // if(event instanceof NavigationStart) {
      //   if (event.navigationTrigger === 'popstate') {
      //     /* Do something here */
      //    //console.log("Router Specific Event Changed From Home",event);
      //     // this.utiltiyService.navigate('/',{skipLocationChange: true}).then(() => {
      //     //   this.router.navigate(['/login']);
      //     // });
      //   }
      // }else
      if (event instanceof NavigationEnd) {
        if (event.id == 1) {
          if (this.authService.getSubDomainFromSession() !== this.authService.parseSubDomainFromURL(location.href)) {
            window.location.replace('/login');
          }
        }
      }
    });
    this.v1menuservice.sessionExpired.subscribe(x => {
      this._SeesionExpiredPopUp(x);
    });
    this.authService.afterPageLoaded.subscribe(x => {
      console.log("AFter Page Loaded Fired from Home");
      if (this._utilityService.isAnyModuleRendered() == false) {
        if (this.authService.isDashboardAccount && this.v1menuservice.dashboardcmd && this.v1menuservice.dashboardcmd.length > 0 || this.authService.currentUserValue.designation.includes('romeo') || this.authService.currentUserValue.designation.includes('redaspen')) {
          this._utilityService.navigate('dashboard').then(x => {
            this.v1menuservice.afterMainComponentsReady.emit(x);
          });

        }
      }
    });
    this._utilityService.onQuickFunctionSelected.subscribe(x => {
      if (x) {
        this.send_function_key(x.keynum);
      }
    })
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardUpEvent(event: KeyboardEvent) {
    if (event.key === "Shift")
      this._utilityService.shiftPressed = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    let keyPressed = event.key;
    console.log("Key down presed", event)
    if (keyPressed === "Shift") {
      this._utilityService.shiftPressed = true;
    }
    else if (keyPressed === "Escape") {
      if (this._utilityService.isAnyModuleRendered()) {
        this._utilityService.onEscapeKeyPress.next(true);
      } else {
        //If there is not any component rendered in router-outlet
        //but check may be there is some popup screen is opened, most probably pasword confirmation popup
        if (this.dialog.openDialogs.length > 0) {
          if (this.dialog.openDialogs[0].componentInstance instanceof v1LookupComponent) {
            this._utilityService.onEscapeKeyPress.next(true);
          }
        }
      }
    } else {
      if (!this._utilityService.currentRouteIs('dashboard') || (this._utilityService.currentRouteIs('dashboard') && this.v1menuservice.stopDashboardPooling.getValue() == true)) {
        switch (keyPressed) {
          // F1-F12 Chrome & Firefox;
          case 'F1':
            this.send_function_key(1, event);
            break;
          case 'F2':
            this.send_function_key(2, event);
            break;
          case 'F3':
            this.send_function_key(3, event);
            break;
          case 'F4':
            this.send_function_key(4, event);
            break;
          case 'F5':
            this.send_function_key(5, event);
            break;
          case 'F6':
            this.send_function_key(6, event);
            break;
          case 'F7':
            this.send_function_key(7, event);
            break;
          case 'F8':
            this.send_function_key(8, event);
            break;
          case 'F9':
            this.send_function_key(9, event);
            break;
          case 'F10':
            this.send_function_key(10, event);
            break;
          case 'F11':
            this.send_function_key(11, event);
            break;
          case 'F12':
            this.send_function_key(12, event);
            break;
        }
      }
    }
  }

  send_function_key(key: number, event?) {
    let functionKeyExist = this.authService.functionKeys.find(x => x.keynum == key);
    if (functionKeyExist) {
      event ? event.preventDefault() : false;
      if (this._utilityService.isAnyModuleRendered()) {
        console.log("Event Fired for QFunc");
        this._utilityService.onFunctionKeyPress.next('[FKEY]' + key);
      } else {
        console.log("Route Fired For QFun");
        this.authService.qfddItems = [];
        this.authService.qfddItems.push(functionKeyExist);
        this._utilityService.navigate('QF/F' + key).then(x => {
          this._utilityService.setSideMenuBar(false);
          this.v1menuservice.afterMainComponentsReady.emit({ type: 'onMenuClick', inqCmd: '[FKEY]' + key });
        })
      }
    }
  }
  padding: boolean;
  @Output('clientSelectionchanged') _clientSelectionchanged = new EventEmitter<any>();

  ngOnInit() {

    console.log("Redaspin", this.redaspin);
    this.appRoutes = { ...AppConstants.APP_ROUTES };
    // this.menuButtons = [ ...AppConstants.APP_MENU_BUTTONS ];
    // this.menuLinksData = { ...AppConstants.MENU_LINKS_DATA };
    this.menuButtons = [];
    this.menuLinksData = {};
    this._utilityService.isMenu().subscribe(menu => {
      if (menu == null || menu == undefined) {
        this.showMenuButton = true;
      } else {
        this.showPanel = menu;
        this.showMenuButton = menu;
      }
    });
    this._utilityService.isSideMenuBarToggle().subscribe(s => this.showPanel = s);
    if (this.authService.multipleUsers.length == 0 && !this.authService.gConn) {
      this.authService.onPageLoadGetUserData();
    }

    this.checkMenuStatus();
  }

  checkMenuStatus() {
    this.isMenuActive = false;
    if (this.v1menuservice.menu.menus.length > 0) {
      this.v1menuservice.menu.menus.map(el => {
        if (el.isActiveForChildVisibility) {
          this.isMenuActive = true;
        }
      })
    }
  }

  loadPreviousPanel(currentPath: string) {
    if (AppUtils.mobileCheck()) {
      return;
    }
    if (currentPath.includes(this.appRoutes.dashboard?.dashboard) || currentPath.length < 2) {
      this.activeTabIndex = 0;
    } else {
      this.activeTabIndex = parseInt(sessionStorage.getItem('current-route-index'), 10) || 0;
    }
    const tempData = AppUtils.findFromObject(this.menuLinksData, 'index', this.activeTabIndex);
    if (tempData?.links && tempData.links?.length > 0) {
      const subLinkItem = tempData.links?.filter(link => link.links && link.links.length > 0);
      if (subLinkItem.length === 1) {
        subLinkItem[0].isLinkExpanded = true;
      }
    }
    this.currentPanelData = { ...tempData };
  }

  dashBoardIconClicked() {
    if (this.v1menuservice.dashboardcmd && this.v1menuservice.dashboardcmd.length > 0 || this.authService.currentUserValue.designation == 'romeo-d' || this.authService.currentUserValue.designation == 'romeo-s' || this.authService.currentUserValue.designation == 'romeo') {
      this.showPanel = false;
      this.v1menuservice.stopDashboardPooling.next(true);
      this.v1menuservice.navigateToMenuSection(this.loaderSer.lastResponse).subscribe(x => {
        this.v1menuservice.menu.resetAllMenuesonSubChildLoad(undefined, undefined);
        if (this._utilityService.currentRouteIs('dashboard')) {
          this.v1menuservice.afterMainComponentsReady.emit({ response: x, type: 'Dashboard' });
        } else {
          this._utilityService.navigate('dashboard').then(x => {
            this.v1menuservice.afterMainComponentsReady.emit({ response: x, type: 'Dashboard' });
          });
        }
      });
    }
    this.v1menuservice.sphereDashboard = true;
    this.v1menuservice.magDashboard = false;
    this.checkMenuStatus();
  }

  sideBarMenuOptionClick(index) {
    console.log('this is value for main Index', index);
    if (this.lastbtnclick == index && this.lastbtnclick != null && this.lastbtnclick != undefined) {
      this.showPanel = !this.showPanel
      console.log(this.lastbtnclick);
    }
    this.showPanel = true;
    this.lastbtnclick = index;
    this.checkMenuStatus();
  }

  onMenuBtnClick(btnName: string, index: number) {


    const buttons = this.menuButtons;
    this.togglePanel(btnName, index, buttons);
  }

  togglePanel(btnName: string, index: number, menuButtons: Array<any>) {
    this.activeTabIndex = index;
    this.menuButtons = menuButtons;
    const currentRouteName = this.appRouteService.getCurrentRouteName();
    this.currentPanelData = { ... this.menuLinksData[btnName] };
    if (!currentRouteName.includes(btnName)) {
      //this.router.navigate([this.appRoutes[btnName]?.default], {relativeTo: this.activatedRoute});
      this.showPanel = true;
    } else {
      this.showPanel = !this.showPanel;
    }
    if (btnName === this.appRoutes.dashboard?.dashboard) {
      this.showPanel = false;
    }
    // this.appRouteService.setFormTitle(this.currentPanelData, false)
  }

  sideMenuToggle() {
    this._utilityService.setSideMenuBar(true);
  }

  clinetChanged(value) {
    console.log("Client Changed:", value);
    if (this.FirstTimeLoaded == false) {
      this.authService.gemxPost({
        gconn: this.authService.gConn,
        gcmd: '[MENU]0000'
      }).subscribe(r => {
        console.log("Got Client List fron v1user", r);
        this.SelectClient(value);
      })
    } else {
      this.SelectClient(value);
    }

  }

  SelectClient(value) {
    this.authService.gemxPost({
      gconn: this.authService.gConn,
      gcmd: '[FS]' + value
    }).subscribe(x => {
      console.log("Menu Data in Hit 1", x);
      let splitedByLineEnd = x.split('\n');
      for (let i = 0; i < splitedByLineEnd.length; i++) {
        let data = (splitedByLineEnd[i] + '');
        if (data.startsWith('110')) {
          this.authService.onMenuResponse.emit(x)

        }
      }

    });
  }

  _SeesionExpiredPopUp(elements) {
    let msgDialouge = this.dialog.open(MSGBOXComponent, {
      //height: '210px',
      data: elements,
      width: '500px', height: '185px',
      id: 'open-msg-box' + Math.random().toString(36).substr(2),
      disableClose: true
    });
  }

  onRouterActivate(event: any) {
    this.showOutlet = true;
  }

  onRouterDeactivate(event: any) {
    this.showOutlet = false;
  }
}
