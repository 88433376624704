//---------------------- Modules
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//---------------------- Custom Modules
import { CoreModule } from './core/core.module';

//---------------------- Routing Modules
import { AppRoutingModule } from './app-routing.module';

//---------------------- Components
import { AppComponent } from './app.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSlideToggleDirective } from './shared/directives/slide-toggle/mat-slide-toggle.directive';
import { MatMenuModule } from '@angular/material/menu';
import { AppHeaderModule } from './core/components/app-header/app-header.module';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './core/components/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from './core/services/loader.service';
import { DEFAULT_TIMEOUT, LoaderInterceptor } from './core/interceptors/loader-interceptor.service';
import { MyLoaderComponent } from './core/components/my-loader/my-loader.component';
import { DndDirective } from './shared/directives/Drag-Drop/dnd.directive';
import { MatTableModule } from '@angular/material/table';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

// import { CustomerComponent } from './modules/customer/customer.component';


@NgModule({
  declarations: [
    AppComponent,
    MyLoaderComponent,
    DndDirective,
    // CustomerComponent,
    // HomeComponent,
    // MatSlideToggleDirective
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    OverlayModule,
    CoreModule.forRoot(),
    HttpClientModule,
    MatTableModule,
    CKEditorModule
  ],

  providers: [LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 10000 }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA], bootstrap: [AppComponent],
})
export class AppModule { }
