<div class="popup-header">
  <div class="container-fluid">
      <div class="row">
          <div class="col-lg-11 col-md-11 col-sm-10 col-10">
              <h3 class="" style="font-size: 20px;">Order Void Confirmation [OP0176]</h3>
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1 col-1 text-right">
              <img src="./assets/icon/close.svg" class="Cpointer closeIcon" mat-dialog-close>
          </div>
      </div>
  </div>
</div>
<div class="popup-body">
  <div class="container-fluid " style="font-size: 18px;">
<div class="row d-flex justify-content-center mt-4">
<div> Are you sure you want to Void / Delete? </div>
</div>
<div class="row d-flex justify-content-center mt-4">
    <div> Order# 99830405   </div> <div class="ml-4" style="margin-left: 3.5rem!important"> Project# 2370860 </div>
  </div>
  </div>



  <div class="export-popup-footer">
    <div class="container-fluid">
        <div class="row d-flex justify-content-end" style="margin-left: 132px;">
            <div class="col-7 ml-3">
                <div class="row ">
                    <div class="col-6">
                        <button class="wms-btn wms-btn-light float-right" mat-dialog-close>No</button>
                    </div>
                    <div class="col-6">
                        <button class="wms-btn wms-btn-primary">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

