
import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable()
export class DataSerivce {

    constructor(private _http: HttpClient) {
    }

    login = () => {

    }

    get = () => {

    }

    post = () => {

    }

    put = () => {

    }

    delete = () => {

    }
}