

<div class="progress-bar-container">

  <div class="row" >


          <div class="col">
             <div class="wms-progress-bar" [style.width]="percentageValue + '%'">
              </div>

          </div>

  </div>
</div>





