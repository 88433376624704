// loader-interceptor.service.ts
import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpBackend
} from '@angular/common/http';
import { Observable, of, Subscriber, throwError, TimeoutError } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { concatMap, delay, map, mergeMap, observeOn, retryWhen,timeout } from 'rxjs/operators';
import { AuthenticationService } from '..';
import { ApienvService } from '@app/core/services/apienv.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
@Injectable()
export class LoaderInterceptor implements HttpInterceptor , OnDestroy {
  private requests: HttpRequest<any>[] = [];
  private Asyncequests: HttpRequest<any>[] = [];
  iterativeRetryReq;
  environ='';
  public backendApiURL: string = '';
  // RedAspin Implementation
  redAspenToken='Bearer eyJhbGciOiJIUzI1NiJ9.eyJJc3N1ZSBCeSI6IkRheXZlaWFuIiwiUm9sZSI6IkRhc2hib2FyZCIsIklzc3VlciI6IlNwaGVyZVdNUyIsIkVudmlyb25tZW50IjoicmVkYXNwZW4iLCJpYXQiOjE2Mzg3NzMxNDR9.sNh8-v2BfDGXRuEj5VBtsj5gJvqDvhJI6iLyU75TRa4';
   // RedAspin Implementation
  constructor(
    private AuthSer:AuthenticationService, 
    private loaderService: LoaderService,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private httpBackend: HttpBackend,
    private apiEnvService: ApienvService) { 
      this.backendApiURL = this.apiEnvService.API_Config.backendApiUrl
    }

  removeRequest(req: HttpRequest<any>) {
    //console.log("After Response",req)
    if(req['isAsync']){
      const i = this.Asyncequests.indexOf(req);
      if (i >= 0) {
        this.Asyncequests.splice(i, 1);
      }
      this.loaderService.isAsyncLoading.next(this.Asyncequests.length > 0);
    }else{
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);
      }
      this.loaderService.isLoading.next(this.requests.length > 0);
    }

  }
 getErrorMessage=(maxRetry:number)=>
  `Tried to load Resource over XHR for ${maxRetry} times without success. Giving up.`;
  DEFAULT_MAX_RETRIES=5;
  DEFAULT_BACKOFF=1000;
  retryWithBackoff(delayMs:number,maxRetry=this.DEFAULT_MAX_RETRIES,backoffMs=this.DEFAULT_BACKOFF){
    let retries=maxRetry;
    return(src:Observable<any>)=>
    src.pipe(
      retryWhen((errors:Observable<any>)=>errors.pipe(
        mergeMap(error=>{
          if(retries-- >0){
            const backoffTime=delayMs + (maxRetry - retries) +backoffMs;
            return of(error).pipe(delay(backoffTime));
          }
          return throwError(this.getErrorMessage(maxRetry));
        })
      ))
    );
  }
  doRetry:(res,isAppQuitRequest)=>any=(res,isAppQuitRequest)=>{
    let splitRes=(typeof(res)=='string'?res:'').split('\n');
    let retry=splitRes.find(x=>x.startsWith('011'));
    if(retry){
      if(isAppQuitRequest){
        return {retry:false,cmd:undefined}
      }else{
        this.loaderService.showTimeOutLabel.next({type:'retry'});
        let cmd = "["+retry.substring(3)+"]";
        return {retry:true,cmd}
      }

    }else{
      return {retry:false,cmd:undefined}
    }
  }
  newHttpClient = new HttpClient(this.httpBackend);
  iterativeReq:(timeoutValueNumeric,retReq)=>any=(timeoutValueNumeric,retReq:HttpRequest<any>)=>{
    return this.newHttpClient.post(retReq.url,retReq.body,{ headers: retReq.headers, responseType: 'text' }).pipe(
      //timeout(timeoutValueNumeric),
      this.retryWithBackoff(1000,30,this.DEFAULT_BACKOFF),
      concatMap((res)=>{
          let isRetry=this.doRetry(res,false);
          if(isRetry.retry){
            return this.iterativeReq(timeoutValueNumeric,retReq.clone());
          }else{
            console.log("Finished On Retry")
            return new Observable<any>((obs)=>{obs.next(res);obs.complete()});
          }
      })
    )
  }

  iterativeReqForGet:(timeoutValueNumeric,retReq)=>any=(timeoutValueNumeric,retReq:HttpRequest<any>)=>{
    return this.newHttpClient.get(retReq.urlWithParams,{ headers: retReq.headers }).pipe(
      //timeout(timeoutValueNumeric),
      this.retryWithBackoff(1000,30,this.DEFAULT_BACKOFF),
      concatMap((res)=>{
          let isRetry=this.doRetry(res,false);
          if(isRetry.retry){
            return this.iterativeReqForGet(timeoutValueNumeric,retReq.clone());
          }else{
            console.log("Finished On Retry")
            return new Observable<any>((obs)=>{obs.next(res);obs.complete()});
          }
      })
    )
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let newReq;
       // RedAspin Implementation Authorization

       if(req.method == 'GET' && req.url == `${this.backendApiURL}V1_0_0/auth1/admin` || req.url.includes('announcement/show')){
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
       }
      else if((req.url != `${this.backendApiURL}V1_0_0/auth1/admin` && req.headers.has('Authorization') && req.headers.get('Authorization')!=this.redAspenToken) || this.environ=='romeo-d' || this.environ=='romeo-s' || this.environ=='romeo'){
        req['isAsync']=true;
        // Get the headers without content-type
        const orignalHeader = req.headers.delete('Authorization');
        // As the request is immutable, clone the original
        // with the new headers
        newReq = req.clone({
          headers: orignalHeader
        });
        this.Asyncequests.push(req);
        // this.loaderService.isAsyncLoading.next(true);
      }else{
        this.environ=this.AuthSer?.currentUserValue?.designation;
        this.requests.push(req);
        this.loaderService.isLoading.next(true);
      }

      const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
      const timeoutValueNumeric = Number(timeoutValue);
      return new Observable<any>(observer => {
        let retyReq:HttpRequest<any>;
        let stopRetry=req.body?.get('gcmd')=='[APP][QUIT]'?true:false;
        //stopRetry=(req.body.get('gcmd')=='[DASHB][UPDATE]' && this.requests.length>0)?false:stopRetry;
        let _sessionKey=req.body?.get('gconn');
        if(_sessionKey){
          retyReq=(newReq?newReq:req).clone({
            body: req.body.set('gcmd', '[RETRY]')
          });
        }else{
          retyReq=(newReq?newReq:req).clone({
            body: req.body
          });
        }
        const subscription = next.handle(newReq?newReq:req)
        // .pipe(
        //   timeout(timeoutValueNumeric)
        //   // ,this.retryWithBackoff(1000,30,this.DEFAULT_BACKOFF)
        // )
        .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                let tempObj=this.doRetry(event.body,stopRetry);
                if(tempObj.retry){
                  //If there async request also
                  this.retryHandler(retyReq,timeoutValueNumeric,req,observer);
                }else{
                  this.removeRequest(req);
                  !req['isAsync']?this.loaderService.lastResponse=event.body:false;
                  //console.log("Last Responses seted:",this.loaderService.lastResponse)
                  observer.next(event);
                  observer.complete();
                }
              }
            },
            err => {
              //alert('error' + err);
              if (err instanceof TimeoutError) {
                this.loaderService.showTimeOutLabel.next({type:'timeOut'});
                this.retryHandler(retyReq,timeoutValueNumeric,req,observer);
             }
             else {
              if (err.error instanceof ErrorEvent) {
                // client-side error
                  this.removeRequest(req);
                  observer.error(err);
                  observer.complete();
              } else {
                // server-side error
                this.loaderService.showTimeOutLabel.next({type:'timeOut'});
                this.retryHandler(req,timeoutValueNumeric,req,observer);
              }
             }
            },
            () => {
              // this.removeRequest(req);
              // observer.complete();
            });
        // remove request from queue when cancelled
        return () => {
          this.removeRequest(req);
          subscription.unsubscribe();
        };
      });
  }
  retryHandler(retyReq:HttpRequest<any>,timeoutValueNumeric,currentReq:HttpRequest<any>,currentReqObserver:Subscriber<any>){
    console.warn("Retrying Start");
    console.log(retyReq, timeoutValueNumeric, currentReq, currentReqObserver)
    if(this.AuthSer.currentUserValue.designation!="romeo-d")
    {
      console.log(retyReq.method)
    if(retyReq.method == 'GET' && retyReq.url == `${this.backendApiURL}V1_0_0/auth1/admin`){
      this.iterativeRetryReq=this.newHttpClient.get(retyReq.urlWithParams,{ headers: retyReq.headers }).pipe(
        delay(1000),
        //timeout(timeoutValueNumeric),
        this.retryWithBackoff(1000,30,this.DEFAULT_BACKOFF),
        concatMap((conRes)=>{
          let check=this.doRetry(conRes,false);
          if(check.retry){
            return this.iterativeReqForGet(timeoutValueNumeric,retyReq.clone())
          }else{
            return new Observable<any>(obser=>{obser.next(conRes);obser.complete();})
          }
        })
      ).subscribe(retRes=>{
        console.log("Finished Retry Command",retRes);
        this.removeRequest(currentReq);
        let tempRes=new HttpResponse({
          body:retRes,
          headers:currentReq.headers,
          status:200,
          url:currentReq.url
        });
        currentReqObserver.next(tempRes);
        currentReqObserver.complete();

      },error=>{
        this.removeRequest(currentReq);
        currentReqObserver.error(error);
        currentReqObserver.complete();
      }
      )
    } else {
      this.iterativeRetryReq=this.newHttpClient.post(retyReq.url,retyReq.body,{ headers: retyReq.headers, responseType: 'text' }).pipe(
        delay(1000),
        //timeout(timeoutValueNumeric),
        this.retryWithBackoff(1000,30,this.DEFAULT_BACKOFF),
        concatMap((conRes)=>{
          let check=this.doRetry(conRes,false);
          if(check.retry){
            return this.iterativeReq(timeoutValueNumeric,retyReq.clone())
          }else{
            return new Observable<any>(obser=>{obser.next(conRes);obser.complete();})
          }
        })
      ).subscribe(retRes=>{
        console.log("Finished Retry Command",retRes);
        this.removeRequest(currentReq);
        let tempRes=new HttpResponse({
          body:retRes,
          headers:currentReq.headers,
          status:200,
          url:currentReq.url
        });
        currentReqObserver.next(tempRes);
        currentReqObserver.complete();

      },error=>{
        this.removeRequest(currentReq);
        currentReqObserver.error(error);
        currentReqObserver.complete();
      }
      )
    }


  }
  }
  ngOnDestroy(): void {
    this.iterativeRetryReq?this.iterativeRetryReq.unsubscribe():false;
  }
}
