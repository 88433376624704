import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  total: number = 100;
  obtained: number = 0;
  percentageValue: number = 0;

  @Input('value') set value(value) {
    this.percentageValue = value;
  }
  // For self calculation
  @Input('totalValue') set totalValue(value) {
    this.total = value;
  }
  @Input('obtainedValue') set obtainedValue(value) {
    this.obtained = value;
  }
  // Pass this property as true for calculation and also pass the above two properties
  @Input('isCalculate') isCalculate: boolean = false;
  //-----------------------------

  constructor() { }

  ngOnInit(): void {
    if (this.isCalculate) {
      this.calculate();
    }
  }

  // Calculate percentage
  calculate () {
    this.percentageValue = Math.floor((this.obtained / this.total) * 100);
  }

}
