<p>
  <span matBadge="4" matBadgeOverlap="false">Text with a badge</span>
</p>

<p>
    <span matBadge="1" matBadgeSize="large">Text with large badge</span>
</p>

<p>
  Button with a badge on the left
  <button class="ml-2" mat-raised-button color="primary"
      matBadge="8" matBadgePosition="before" matBadgeColor="accent">
    Action
  </button>
</p>

<p>
    Button toggles badge visibility
    <button class="ml-2" mat-raised-button matBadge="7" [matBadgeHidden]="hidden" (click)="toggleBadgeVisibility()">
        Hide
    </button>
</p>

<p>
  Icon with a badge
  <mat-icon matBadge="15" matBadgeColor="warn">home</mat-icon>
    <!-- Include text description of the icon's meaning for screen-readers -->
    <span class="cdk-visually-hidden">
      Example with a home icon with overlaid badge showing the number 15
    </span>
</p>


