import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { GridRowService } from '../services/grid-row.service';
import { Observable, of,fromEvent, EMPTY,merge } from 'rxjs';
import { concatMap, expand, map,debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[row-click-handler]'
})
export class RowClickHandlerDirective implements OnInit {
  @Input('rowData') rowData;
  @Output('dbClick') dbClick=new EventEmitter<any>();
  @Output('singleClick') singleClick=new EventEmitter<any>();
  constructor(private _el: ElementRef,private renderer:Renderer2,private _rowService:GridRowService) {

   }
  ngOnInit(): void {
    this.handleClickAndDoubleClick()
  }
  handleClickAndDoubleClick(){
    const el = this._el.nativeElement;
    const clickEvent = fromEvent<MouseEvent>(el, 'click');
    const dblClickEvent = fromEvent<MouseEvent>(el, 'dblclick');
    const eventsMerged = merge(clickEvent, dblClickEvent).pipe(debounceTime(300));
    eventsMerged.subscribe(
      (event:any) => {
        if(event.type === 'click'){
          //click
          this.singleClick.emit(event);
          //console.log("Single cliked Fired");
        }else {
          //dblclick
          this.dbClick.emit(this.rowData);
          //console.log("Double cliked Fired");
        }

      }
    );
  }
}
