<div class="container">
    <div class="general-popup-header">
        <div class="row">
            <div class="col-lg-10 col-md-10 col-sm-10">
                <h2 class="">{{_controls._title | removeClientName:selectedClientName}}</h2>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 text-right">
                <img src="/assets/icon/close.svg" class="Cpointer closeIcon" (click)="onbtnClick('0')">
            </div>
        </div>
    </div>
    <div class="general-popup-body">
        <div class="row" style="margin: 0;">
            <div class="col">
                <h3 style="margin-bottom: 0;" [innerHTML]="_controls.body"></h3>
            </div>
        </div>
    </div>
    <div class="general-popup-footer">
        <div class="container-fluid">
            <div class="row d-flex justify-content-end">
                <div [class]="'col-'+(_controls.buttons.length*2)+' btn-col'">
                    <ng-container *ngFor="let bt of _controls.buttons">
                        <button class="wms-btn" [ngClass]="{'wms-btn-light':(bt.lowercaseText!='ok' && bt.lowercaseText!='yes'),'wms-btn-primary':(bt.lowercaseText=='ok' || bt.lowercaseText=='yes')}" (click)="onbtnClick(bt.cmd,bt)">{{bt.text}}</button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>