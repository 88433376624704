import { EventEmitter, HostListener, Injectable } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);
  public isAsyncLoading = new BehaviorSubject(false);
  public showTimeOutLabel=new BehaviorSubject(undefined);
  public lastResponse='';
  private formStartingState;
  private formLasteState;

  isOnline:boolean=true;

  constructor() { }

  setInitialState(state){

  }
  setLastState(state){

  }
 //Offline Event
 @HostListener('window:offline', ['$event'])
 OfflineEvent(event: Event) {
  this.isOnline=false;
  //console.log(event);
   //do something.....
 }

 //Online event
 @HostListener('window:online', ['$event'])
 OnlineEvent(event: Event) {
    this.isOnline=true;
   //console.log(event);
   //do something.....

   }

}
