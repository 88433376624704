import { AbstractType, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConstants } from './app-constants';

@Injectable()
export class AppRouteService {
  private currentRouteName = '';
  private currentRouteIndex: number;
  private previousRoute: string = '';
  private formTitle: Subject<string> = new Subject<string>();
  constructor() {}

  getCurrentRouteName(): string {
    return this.currentRouteName;
  }

  setCurrentRouteName(routeName: string) {
    this.currentRouteName = routeName;
  }

  getCurrentRouteIndex(): number {
    return this.currentRouteIndex;
  }

  setCurrentRouteIndex(index: number) {
    this.currentRouteIndex = index;
    sessionStorage.setItem('current-route-index', index.toString());
  }

  getPreviousRoute() {
    if (this.previousRoute) {
      return this.previousRoute;
    } else {
      const pRoute = sessionStorage.getItem('pRoute');
      this.previousRoute = pRoute? pRoute : this.currentRouteName;
    }
    return this.previousRoute;
  }

  setPreviousRoute(url: string) {
    console.log('currentRouteName : ', this.currentRouteName);

    this.previousRoute = url;
    sessionStorage.setItem('pRoute', this.previousRoute);
  }

  setTitle(title: string) {
    this.formTitle.next(title);
  }

  setFormTitle(currentMenu:  any, isChild: any) {

    if(isChild) {
      this.formTitle.next(currentMenu.name);
    } else {
      if (currentMenu.links && currentMenu.links.length > 0) {
        const menu = currentMenu.links[0];
        this.formTitle.next(menu.name);
      } else {
        this.formTitle.next(currentMenu.name);
      }
    }
  }

  getFormTitle() {
    return this.formTitle;
  }

}
