import { UtilityService } from './../../../services/utility.service';
import { AuthenticationService } from '@app/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-change-popup',
  templateUrl: './password-change-popup.component.html',
  styleUrls: ['./password-change-popup.component.scss']
})
export class PasswordChangePopupComponent implements OnInit {

  constructor(
    public authenticationService: AuthenticationService,
    public utilityService: UtilityService,
    public dialogRef: MatDialogRef<PasswordChangePopupComponent>
  ) { }

  ngOnInit(): void {
  }

  async logout() { 
    this.authenticationService.logout().subscribe(x=>{
      this.authenticationService.hideJiraTicket();
      window.location.replace('/login');
    });
  }

}
