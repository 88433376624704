import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppRouteService } from '../services/app-route.service';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _appRouteSerivce: AppRouteService,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    console.log('this is current User', currentUser);
    if (currentUser) {
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
       }
      // Auth Guard For RedAspin Specificaly
       else if((currentUser.designation ==='redaspen-s' || currentUser.designation ==='redaspen') && state.url!='/' && state.url!='/dashboard'){
        this.router.navigate(['/dashboard']);
        return false;
       }
      // this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      // .subscribe((event: NavigationEnd) => {
      //   console.log('prev:', event.url);
      //   this._appRouteSerivce.setPreviousRoute(event.url);
      // });
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
