import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppHeaderComponent } from './app-header.component';
import { UserPopoverComponent } from "./user-popover/user-popover.component";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { MatMenuModule, MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { SharedModule } from '@app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';

// export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
//   return () => overlay.scrollStrategies.block();
// }
// import { Overlay } from '@angular/cdk/overlay';

@NgModule({
    declarations: [
        AppHeaderComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatMenuModule,
        SharedModule
    ],
    exports: [
        AppHeaderComponent
    ]
    // ,
    // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppHeaderModule { }
