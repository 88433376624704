import {Directive, AfterContentInit, AfterViewInit, ElementRef, Renderer2} from '@angular/core';


@Directive({
  selector: '[grid-col-arrang]',
})
export class GridColumnsArrangPopOverDirective implements AfterContentInit,AfterViewInit {
  constructor(private _el: ElementRef,private renderer:Renderer2) {
  }
  ngAfterContentInit() {
    // contentChildren is set
    console.log("Tabs Area",this._el);
  }

  ngAfterViewInit() {
    // viewChildren is set
  }

}
