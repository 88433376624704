

import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConstants } from './core/services/app-constants';
import { LoginComponent } from './core/components/login/login.component';
import { HomeComponent } from './core/components/home/home.component';
import { TwoFactorAuthComponent } from './core/components/two-factor-auth/two-factor-auth.component';
import { AuthGuard } from './core/guards/auth-guard';

const routes: Routes = [

  // {
  //   path: AppConstants.APP_ROUTES.responsiveHome.default,
  //   loadChildren: () => import('./home-responsive/home-responsive.module').then(m => m.HomeResponsiveModule)
  // },
  {
    path: AppConstants.APP_ROUTES.login.default,
    pathMatch: 'full',
    component: LoginComponent
  },
  {
    path: AppConstants.APP_ROUTES.login.default + '/:accountId/:username/:pass',
    component: LoginComponent
  },
  // {
  //   path: 'announcement',
  //   loadChildren: () => import('./modules/announcement/announcement.module').then(m => m.AnnouncementModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: '2fa',
    component: TwoFactorAuthComponent
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ""
      },
      // { path: 'Detailcomponent', component: RedAspenDetailComponent },
      {
        path: 'customers',
        loadChildren: () => import('@app/modules/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'dashboard/RedAspen-Detail',
        loadChildren: () => import('@app/modules/CustomDashboards/red-aspen/red-aspen-detail/red-aspen-detail.module').then(m => m.RedAspenDetailModule)
      },
      {
        path: 'dashboard/TargetRomeo-Detail',
        loadChildren: () => import('@app/modules/CustomDashboards/target-romeo/target-romeo-detail/target-romeo-detail.module').then(m => m.TargetRomeoDetailModule)
      },
      {
        path: 'inventory',
        loadChildren: () => import('@app/modules/Inventory-Account/inventory-account.module').then(m => m.InventoryAccountModule)
      },
      {
        path: 'suppliers',
        loadChildren: () => import('@app/modules/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'bin-locations',
        loadChildren: () => import('@app/modules/bin-locations/bin-locations.module').then(m => m.BinLocationsModule)
      },
      {
        path: 'bin-schedules',
        loadChildren: () => import('@app/modules/bin-schedules/bin-schedules.module').then(m => m.BinSchedulesModule)
      },
      {
        path: 'products-inquiry',
        loadChildren: () => import('@app/modules/products/products.module').then(m => m.ProductModule)
      },
      {
        path: 'commodity-codes',
        loadChildren: () => import('@app/modules/commity-codes/commity-codes.module').then(m => m.CommityCodesModule)
      },
      {
        path: 'inventory-safety-stock',
        loadChildren: () => import('@app/modules/inventory-safety-stock/inventory-safety-stock.module').then(m => m.InventorySafetyStockModule)
      },
      {
        path: 'inv-acct-assign-priority',
        loadChildren: () => import('@app/modules/inventory-assign-priority/inventory-assign-priority.module').then(m => m.InventoryAssignPriorityModule)
      },
      { path: 'orders', redirectTo: 'orders/', pathMatch: 'full' },
      {
        path: 'orders/:inquiries',
        loadChildren: () => import('@app/modules/order-shipment-processing/order.module').then(m => m.OrderModule)
      },
      { path: 'arn', redirectTo: 'arn/', pathMatch: 'full' },
      {
        path: 'arn/:inquiries',
        loadChildren: () => import('@app/modules/order-shipment-processing/order.module').then(m => m.OrderModule)
      },
      {
        path: 'inventory-inquiries',
        loadChildren: () => import('@app/modules/stock-transfers-inquiries/stock-transfers-inquiries.module').then(m => m.StockTransfersInquiriesModule)
      },
      {
        path: 'shipment-inquiries',
        loadChildren: () => import('@app/modules/shipment-inquiries/shipment-inquiries.module').then(m => m.ShipmentInquiriesModule)
      },
      {
        path: 'filestoenvironment',
        loadChildren: () => import('@app/modules/files-to-env/files-to-env.module').then(m => m.FilesToEnvModule)
      },
      {
        path: 'RejectedArns',
        loadChildren: () => import('@app/modules/files-to-env/files-to-env.module').then(m => m.FilesToEnvModule)
      },
      {
        path: 'RejectedOrders',
        loadChildren: () => import('@app/modules/files-to-env/files-to-env.module').then(m => m.FilesToEnvModule)
      },
      {
        path: 'QF/:functionName',
        loadChildren: () => import('@app/modules/v1-flow-parser/v1-flow-parser.module').then(m => m.V1FlowParserModule)
      },
      {
        path: 'bom-import',
        loadChildren: () => import('@app/modules/file-upload-screen/file-upload-screen.module').then(m => m.FilesUploadScreenModule)

      },
      {
        path: 'billing-code-import',
        loadChildren: () => import('@app/modules/file-upload-screen/file-upload-screen.module').then(m => m.FilesUploadScreenModule)

      },
      {
        path: 'client-(cust)-import',
        loadChildren: () => import('@app/modules/file-upload-screen/file-upload-screen.module').then(m => m.FilesUploadScreenModule)

      },
      {
        path: 'arn-receipt-processing/arn-processing/arn-exp.-date-upload',
        loadChildren: () => import('@app/modules/file-upload-screen/file-upload-screen.module').then(m => m.FilesUploadScreenModule)

      },
      {
        path: 'announcement',
        loadChildren: () => import('./modules/announcement/announcement.module').then(m => m.AnnouncementModule),
        // canActivate: [AuthGuard],
      },
      {
        path: '**',
        loadChildren: () => import('@app/modules/v1-flow-parser/v1-flow-parser.module').then(m => m.V1FlowParserModule)
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
