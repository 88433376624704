<div class="">
    <label *ngIf="label" [for]="controlId">{{label}}</label>
    <div class="custom-autocomplete-container" [id]="'auto-'+controlId">
        <div class="autocomplete-area border-primary">
            <input tabindex="-1" type="text" [attr.maxLength]="maxLength" [id]="controlId" [name]="controlId" [placeholder]="placeholder" [(ngModel)]="searchValue" (focus)="focus($event)" (focusout)="focusOut($event)" (keydown.enter)="onEnterClick($event.target.value)">
            <!-- Search Icon Area Start -->
            <div class="lookup-area">
                <img src="assets/icon/search.svg" [id]="'lookupIcon'+controlId" (click)="onIconClicked()">
            </div>
            <!-- Search Icon Area End -->
        </div>
    </div>
</div>